import { Box, LinearProgress, TextareaAutosize, Typography } from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useForm } from 'react-hook-form';

const FormResourcesProject = ({ project,setFileAudio,progress,loading,setGuideline }) => {
    const fileTypesVideo = ["mp4"];
    const fileTypesAudio = ["wav"];
    const fileTypesCaptions = ["srt"];

    const { register, handleSubmit, control, watch, formState: { errors, isDirty, dirtyFields },} = useForm({
        defaultValues: {
            ...project,
        }
    })
    return (
        <form style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '1.5rem', minWidth: '45%' }}>
            <Typography
                variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal", display: 'flex', alignItems: 'center' }}>
                Video
            </Typography>
            <Controller
                name="video"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FileUploader
                        handleChange={(e) => onChange(e)}
                        name="video"
                        disabled
                        types={fileTypesVideo}
                        label="Video"
                        multiple={false}
                        maxSize={100000000}
                    />
                )}
            />
            <Typography
                variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal", display: 'flex', alignItems: 'center' }}>
                Audio
            </Typography>
            <Controller
                name="audio"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FileUploader
                        value={value}
                        handleChange={(e) => {
                            onChange(e);
                            setFileAudio(e);
                        }}
                        name="audio"
                        types={fileTypesAudio}
                        label={project?.audio ? `Audio ${project?.audio.split('/')[project?.audio.split('/').length - 1]}` : "Audio"}
                        multiple={false}
                        maxSize={100000000}
                    />

                )}
            />
            {loading && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(progress,)}%`}</Typography>
                </Box>
            </Box> }
            <Typography
                variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal", display: 'flex', alignItems: 'center' }}>
                GuideLines
            </Typography>
            <TextareaAutosize
                id="guideline"
                {...register("guideline")}
                style={{ width: '100%', backgroundColor: 'white', padding: '10px',borderRadius:'5px', fontSize: '16px',fontFamily:'inherit' }}
                placeholder="Type in here…"
                onChange={(e) => setGuideline(e.target.value)}
            />


        </form>
    )
}

export default FormResourcesProject