import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataUser } from "../context/DataUser";
import styles from "../css/Login.module.css";

export const Login = () => {
  const navigation = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { handlerSetUser, setJwt, user } = useContext(DataUser);

  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");

  // Why? creo que esto no esta haciendo nada
  useEffect(() => {
    if (user?.roles) {
      navigation("/dashboard");
    }
  }, [user]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    // //console.log(e.target[0].value);
    // navigation("/audio-descriptor");
    postLogin();
  };

  const postLogin = () => {
    const path = `${process.env.REACT_APP_URL}/api/auth/login`;
    const data = {
      email,
      password,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    fetch(path, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem("token", data.token);
          //console.log(data);
          setJwt(data.token);
          handlerSetUser(data.usuario);
          setOpenToast(true);
          setMessage("Login success");
          setStatusSave("success");

          navigation("/dashboard");
        } else {
          setOpenToast(true);
          setMessage("Login failed please check your credentials");
          setStatusSave("error");
          //console.log(data);
        }
      });
  };

  return (    
    <div className={styles.container__login} aria-label="Login Page">
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusSave === "success" ? "success" : "error"} variant="filled">
          {/* <AlertTitle>
        {statusSave === "success" ? "success" : "error"}
      </AlertTitle> */}
          {message}
        </Alert>
      </Snackbar>
      <div className={styles.container__company}>
        <span className={styles.label__company}> by</span>
        <img
          className={styles.logo__company}
          src="/assets/img/logo_dicapta.png"
          alt="Logo de la empresa"
        />
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <figure className={styles.logo}>
            <img src="/assets/img/LogoLogin.png" alt="Logo de la empresa" />
          </figure>
        </div>
        <main className={styles.main}>
          <form
            className={styles.container__formGroup}
            onSubmit={handleSubmitLogin}
          >
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.formGroup__label}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className={styles.formGroup__input}
                aria-label="Login Email"
                required
                autoFocus
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.formGroup__label}>
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className={styles.formGroup__input}
                aria-label="Login Password"
                required
              />
            </div>
            <div className={[styles.formGroup, styles.remember].join(" ")}>
              <input
                type="checkbox"
                id="remember-me"
                name="remember-me"
                className={styles.formGroup__checkbox}
              />
              <label
                htmlFor="remember-me"
                className={styles.formGroup__checkboxLabel}
              >
                Remember me
              </label>
            </div>
            <div className={[styles.formGroup, styles.save].join(" ")}>
              <div className={styles.container__btnLogin}>
                <button type="submit" className={styles.btnLogin}>
                  Log in
                </button>
                <Link to="/forgot-password" className={styles.forgotPassword}>
                  Forgot your password?
                </Link>
              </div>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
};
