
export const analysisAudio = (wavesurfer, currentRegion, setMaxPeakAudio) => {
  //console.log("//SEGMENTO DE AUDIO//DATA ANALYSIS");
  const startTime = currentRegion.start - 3;
  const endTime = currentRegion.end + 3;


  const waveformStart = wavesurfer.current.backend.getPeaks(
    512,
    startTime,
    currentRegion.start
  );
  const waveformEnd = wavesurfer.current.backend.getPeaks(
    512,
    currentRegion.end,
    endTime
  );

  const waveform = [
    Object.values(waveformStart),
    Object.values(waveformEnd),
  ].flat();
  // //console.log("Valores 3s antes 3s despues", waveform);

  // Convertir el objeto waveform a un array
  let waveformArray = []
  waveform.forEach((value) => {
    if (value !== 0 && value !== undefined) {
      waveformArray.push(value);
    }
  });
  if (waveformArray.length === 0) {
    waveformArray = [1, 1, 1]
  }

  return waveformArray;
};

export const analysisRecording = (recordingData) => {
  let countErrorHigh = 0;
  let countErrorLow = 0;
  let countPass = 0;
  let countRecordingData = 0;

  recordingData.forEach((value) => {
    if (value > -25) {
      if (value > -18 && value < -6) countPass = countPass + 1;
      else if (value < -18) countErrorLow = countErrorLow + 1;
      else if (value > -6) countErrorHigh = countErrorHigh + 1;
      countRecordingData = countRecordingData + 1;
    }
  });

  let msg = "";
  if (countRecordingData !== 0) {
    const porcentValuesError = (100 * (countErrorHigh + countErrorLow)) / countRecordingData;
    if (porcentValuesError > 70) {
      if (countErrorHigh > countErrorLow) 
        msg = "Try to speak softly";
      else 
        msg = "Try to speak louder";
      console.log('########## REPORT ##########');
      console.log('countErrorHigh', countErrorHigh);
      console.log('countErrorLow', countErrorLow);
      console.log('countPass', countPass);
      console.log('countRecordingData', countRecordingData);
      console.log('porcentValuesError', porcentValuesError);
      console.log('########## REPORT ##########');
      
      return [false, msg];
    } 
    else{
       return [true, msg];
    } 
  }
  else {
    msg = "No data is recorded";
    return [false, msg];
  }
};

export const updateBlobSegment = (currentRegion, blob, websurfer) => {
  const data = { ...currentRegion.data, blob: blob, isDisabled: false };

  currentRegion.update({ data: data });

  const regionsList = websurfer.current.regions.list;
  //console.log("regionsList",regionsList);
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];

    if (region.id === currentRegion.id) {
      region.update({ data: data, color: "#8EC36A99" });
    }
  }
};