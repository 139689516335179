import Autocomplete from "@mui/joy/Autocomplete";
import { Box, Button } from "@mui/material";
import * as React from "react";
import { FileUploader } from "react-drag-drop-files";
import styles from "../../css/Form.module.css";
import { FormDialog } from "../projectManager/FormDialog";

export const FormAssignRoles = ({
  typeOfService,
  rolesService,
  narrators,
  usersNarrators,
  handleChange,
  fileTypes,
  handleSetRole,
  usersDescriptors,
  usersEditors,
  usersClient,
  usersQualityControl,
  handleClickOpen,
  handleClose,
  open,
  usersScriptAdapters,
  usersInterpreters
}) => {
  const [narratorsUsers, setNarratorsUsers] = React.useState([]);
  const handleSetNarrators = (text, rol, index) => {
    handleSetRole(text, rol, index);
    let narrators = [...narratorsUsers]
    narrators[index] = text;
    setNarratorsUsers(narrators);
  }
  return (
    <Box
      className={styles.container__form}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <h2 className={styles.title_forms}>Assign roles</h2>
      {typeOfService &&
        rolesService.map((rol, index) =>
          rol === "Narrators" ? (
            <div key={index} className={styles.container__narrators}>
              {Array.from({ length: narrators }).map((_, index) => (
                <div className={styles.container__inputNarrator} key={index}>
                  <label className={styles.label}>
                    Audio Narrator {index + 1}
                  </label>
                  <Autocomplete
                    key={index}
                    onChange={(e, value) => handleSetNarrators(value, rol, index)}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    placeholder={`Select users to assign ${rol}`}
                    options={usersNarrators}
                    sx={{ marginBottom: "1rem" }}
                  />
                  <FileUploader
                    handleChange={(e) => handleChange('CaptionMale', e, narratorsUsers[index], index)}
                    name="file"
                    types={fileTypes}
                    style={{
                      border: "2px dashed #ccc",
                      padding: "20px",
                      textAlign: "center",
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.container__inputNarrator} key={index}>
              <label className={styles.label} htmlFor={rol}>
                {rol}
              </label>
              <Autocomplete
                key={index}
                onChange={(e, value) => handleSetRole(value, rol)}
                // value={getRoles(rol) || }
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                placeholder={`Select users to assign ${rol}`}
                options={
                  rol === "Describer"
                    ? usersDescriptors
                    : rol.includes("Narrator")
                      ? usersNarrators
                      : rol === "Editor"
                        ? usersEditors
                        : rol === "Quality Control"
                          ? usersQualityControl
                          : rol === 'Script Adapter'
                            ? usersScriptAdapters
                            : rol === 'Interpreter'
                              ? usersInterpreters
                              : rol === 'Client'
                                ? usersClient 
                                : []

                }
                sx={{ marginBottom: "1rem" }}
              />
            </div>
          )
        )}
      <Button
        variant="contained"
        size="medium"
        onClick={handleClickOpen}
        sx={{
          color: "white",
          background: " linear-gradient(180deg, #582A50 0%, #A93F90 100%)",
          padding: ".7rem",
          fontSize: "1rem",
          marginBottom: "1rem",
          borderRadius: "10px",
        }}
      >
        Create New User
      </Button>
      <FormDialog open={open} handleClose={handleClose} />
    </Box>
  );
};
