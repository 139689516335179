import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopIcon from "@mui/icons-material/Stop";
import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/AudioRecorder.module.css";
import { analysisRecording } from "../../utils/audio/analysisAudio";
import DecibelMeter from "../narrator/DecibelMeter";
export const TestAudioRecorder = () => {
  const { deviceInput, deviceOutput } =useContext(DataUser);
  const wavesurfer = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");

  const [openCounter, setOpenCounter] = useState(false);
  const [count, setCount] = useState(3);
  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    createWave();
  }, []);

  const createWave = () => {
    if (wavesurfer.current) wavesurfer.current.destroy();
    wavesurfer.current = WaveSurfer.create({
      container: "#waveformTest",
      waveColor: "white",
      progressColor: "white",
      cursorColor: "navy",
      height: 60,
      barHeight: 3,
      responsive: true,
      backgroundColor: "#28262E",
    });
  };

  const startRecording = () => {
    restartCount();
    setOpenCounter(true);
    setIsRecording(true);
    setTimeout(() => {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: deviceInput.deviceId } })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
          setMediaRecorder(mediaRecorder);
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          // Crear un analizador para analizar el audio
          const analyser = audioContext.createAnalyser();
          // Crear una fuente de audio a partir del flujo de medios (stream)
          const mediaStreamSource = audioContext.createMediaStreamSource(stream);
          // Conectar la fuente de audio al analizador
          mediaStreamSource.connect(analyser);
          // Configurar el tamaño del búfer del analizador
          const bufferLength = analyser.fftSize;
          // Crear un nuevo arreglo de tipo Float32Array para almacenar los datos del búfer
          const dataArray = new Float32Array(bufferLength);
          // Arreglo para almacenar los datos de la grabación en dBFS
          const recordingData = [];
          const updateDecibelLevel = () => {
            // Obtener los datos del búfer del analizador
            analyser.getFloatTimeDomainData(dataArray);
            // Encontrar el valor máximo absoluto en el búfer
            const max = Math.max(...dataArray.map(Math.abs));
            // Calcular el nivel en dBFS utilizando la fórmula 20 * log10(x / xMax)
            const decibelValue = 20 * Math.log10(max);
            // Almacenar el valor en el arreglo de datos de la grabación en dBFS
            recordingData.push(decibelValue);
            // Solicitar la siguiente animación de fotograma para actualizar continuamente el nivel de decibelios
            requestAnimationFrame(updateDecibelLevel);
          };
          // Iniciar la actualización del nivel de decibelios
          updateDecibelLevel();
          mediaRecorder.start();
          
          mediaRecorder.addEventListener("dataavailable", (e) => {
            const blobAudio = new Blob([e.data], { type: "audio/webm" });
            const blobURLAudio = URL.createObjectURL(blobAudio);
            setBlobURL(blobURLAudio);
            wavesurfer.current.load(blobURLAudio);
            //CUANDO wavesurfer tenga lista la onda de audio se analiza
            wavesurfer.current.on("ready", () => {
              //console.log("ready wave record");
              validateRecord(recordingData);
            });

          });

          mediaRecorder.addEventListener("stop", () => {
            setIsRecording(false);
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    }, 3000);
  };
  const validateRecord = (recordingData) => {
    const validateRecording = analysisRecording(recordingData);
    if (!validateRecording[0]) {
      wavesurfer.current.setWaveColor("red");
      setOpenToast(true);
      setMessage(validateRecording[1]);
      setStatusSave('error')
    } else {
      wavesurfer.current.setWaveColor("green");
      setOpenToast(true);
      setMessage("Good Sound Quality");
      setStatusSave("success");
    }
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    setIsRecording(false);
  };

  const playAudio = () => {
    wavesurfer.current.setSinkId(deviceOutput.deviceId);
    wavesurfer.current.play();
  };

  // todo: ----------------- Aria Countdown -----------------
  // const recordBtn = document.getElementById("recordBtn");
  let audio;
  useEffect(() => {
    audio = new Audio("https://github.com/JCBallen/assets/raw/main/audio/cut_countdown.mp3");
    audio.volume = 0.7;
  });
  const handleClickAriaCountdown = () => {

    speechSynthesis.cancel();
    audio.play();

    audio.addEventListener("ended", () => {
      audio.pause(); // Vuelve a pausar
      audio.currentTime = 0; // Coloca la reproducción en el segundo 0 (inicio)
    });
  };

  // todo: ----------------- Counter -----------------
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount((c) => c - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setOpenCounter(false);
    }
  }, [count]);

  const restartCount = () => {
    setCount(3);
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}

        sx={{ position: 'absolute', top: 0 }}
      >
        <Alert severity={statusSave === "success" ? "success" : "error"} variant="filled">
          {message}
        </Alert>
      </Snackbar>
      <div className={styles.container_testRecord}>

        {!openCounter ? (
          <p aria-hidden="true" className={styles.test_text}>
            Testing 1, 2, 3. Testing 1, 2, 3. Can everyone hear me?
          </p>
        ) : (
          <p className={styles.test_text}>
            {count}
          </p>
        )
        }
        <div className={styles.container_record}>
          {!isRecording ? (
            <>
              <button
                tabIndex={0}
                id="recordBtn"
                className={styles.btn_record + " " + styles.btn_record_test}
                onClick={() => {
                  startRecording();
                  handleClickAriaCountdown();
                }}
                aria-label="For Testing Start recording"
              >
                <KeyboardVoiceIcon fontSize="large" />
              </button>
              {blobURL && (
                <button
                  tabIndex={0}
                  aria-label="Play recorded audio"
                  className={styles.btn_play + " " + styles.btn_playTest}
                  onClick={playAudio}
                  autoFocus
                >
                  <PlayCircleFilledWhiteIcon fontSize="large" />
                </button>
              )}
            </>
          ) : (
            <button tabIndex={0} aria-label="stop" className={styles.btn_stop} onClick={stopRecording} autoFocus>
              <StopIcon fontSize="large" autoFocus />
            </button>
          )}
          <div className={styles.container_timeline_test}>
            <div id="waveformTest"></div>
          </div>
        </div>
      </div >
      <DecibelMeter type='horizontal' regionIn={isRecording} />
    </>
  );
};