import * as srtparsejs from "srtparsejs"; // cjs
import { subripToSeconds } from "../secondToSubrip";

export const srtToJson = async (fileSRT) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const json = srtparsejs.parse(data);
            json.forEach((item) => {
                item.startTime = subripToSeconds(item.startTime);
                item.endTime = subripToSeconds(item.endTime);
                item.speaker = "undefined";
              });
            resolve(json);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(fileSRT);
    });
};