import React, { useContext, useEffect, useState } from "react";
import { Time } from "../components/Time";
import { QCReview } from "../components/qualityControl/QCReview";
import { DataDescriptor } from "../context/DataDescriptor";
import styles from "../css/DescriptorActions.module.css";

export const QCActions = () => {
  const { currentRegion, resized,regions} = useContext(DataDescriptor);

  const [prevRegion, setPrevRegion] = useState();

  useEffect(() => {
    if (currentRegion !== null) setPrevRegion(currentRegion);
  });

  useEffect(() => {
    if (currentRegion === null || prevRegion !== currentRegion) {
      try {
         regions.map((region) => {
        if (region.data.review.approved) {
          region.update({ color: "#8EC36A99" });
        } else if ((!region.data.review.comment[0] && !region.data.review.approved) ||
          (!region.data.review.approved && (region.data.review.comment[0] === region.data.review.comment[1]))) {
          region.update({ color: "#12B4B899" });
        } else {
          region.update({ color: "rgba(255, 19, 0, 0.3)" });
        }

        if(currentRegion && region.id === currentRegion.id){
          region.update({ color: "rgba(255, 199, 0, 0.3)" });
          
        }
      });

      } catch (error) {
          console.error("No se ha seleccionado region previamente");
      }
    }
  }, [currentRegion]);

  return (
    <div
      className={styles.container__descriptorActions}
      style={{width: resized ? "25%" : "40%"}}>

      {currentRegion ? (
        <>
          <Time />
          <QCReview />
        </>
      ) : (
        <div className={styles.select__segment}>
          <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
          <span>Select a Segment</span>
        </div>
      )}
    </div>
  );
};
