import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Alert, Snackbar } from "@mui/material";
import Mousetrap from "mousetrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataNarrator } from "../../context/DataNarrator";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/VerticalActions.module.css";
import {
  addSegment,
  addSegmentSign,
  handleNextRegion,
  handlePreviousRegion,
} from "../../utils/controls/controlsSegment";
import { zoomIn, zoomOut } from "../../utils/controls/zoom";
import { editAnnotation } from "../../utils/descriptions/description";
export const VerticalActions = () => {
  const {
    regions,
    zoomLevel,
    websurfer,
    setZoomLevel,
    setTimeSubRip,
    currentRegion,
    setActualTime,
    setRegions,
    setCurrentRegion,
    setStartHH,
    setStartMM,
    setStartSS,
    setStartMS,
    setEndHH,
    setEndMM,
    setEndSS,
    setEndMS,
    setDuration,
    setDescription,
    setIsActiveAudio,
    duration, setCaption, setIsPlaying, cutRegion, handleSetLoadingZoom, loadingZoom, loadingWave, setLoadingWave, viewAllSegments, changeViewAllSegments, dataLength
  } = useContext(DataDescriptor);

  const { wavesurfer } = useContext(DataNarrator)
  const { user, currentProject } = useContext(DataUser);
  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const ViewAllSegmentsRef = useRef(null);

  const handleAddSegment = async () => {
    let result;
    if (window.location.href.includes("script-adapter")) {
      result = await addSegmentSign(websurfer, regions, currentProject, setRegions);
    } else {
      result = await addSegment(websurfer, regions, currentProject, setRegions);
    }
    if (result.status) {
      setMessage("Segment added");
      setStatusSave(true);
      setOpenToast(true);
    } else {
      setMessage("Error adding segment");
      setStatusSave(false);
      setOpenToast(true);
    }
  };

  const cutActualRegion = async () => {
    const resp = await cutRegion(currentRegion)
    if (resp) {
      setMessage("Segment cutted");
      setStatusSave(true);
      setOpenToast(true);
    } else {
      setMessage("Error cutting segment");
      setStatusSave(false);
      setOpenToast(true);
    }
  }
  //todo: -------------------- Autofocus on the first region --------------------
  useEffect(() => {
    if (regions.length === dataLength && isFirstTime && dataLength > 0) {
      setIsFirstTime(false);
    }
  }, [regions]);

  useEffect(() => {
    if (!isFirstTime) {
      const keys = Object.keys(websurfer.current.regions.list);
      const regions = keys.map((key) => websurfer.current.regions.list[key]);
      regions.sort((a, b) => a.start - b.start);
      editAnnotation(user,
        regions[0],
        setCurrentRegion,
        setStartHH,
        setStartMM,
        setStartSS,
        setStartMS,
        setEndHH,
        setEndMM,
        setEndSS,
        setEndMS,
        setDuration,
        setDescription
      );
    }
  }, [isFirstTime]);


  //todo: --------------- Keyboard Shortcuts ----------------

  const prevRegionRef = useRef();
  const nextRegionRef = useRef();

  const prevRegionShortcut = () => {
    prevRegionRef.current.click();
    prevRegionRef.current?.focus();
  }

  const nextRegionShortcut = () => {
    nextRegionRef.current.click();
    nextRegionRef.current?.focus();
  }

  const toggleViewAllSegmentsShortcut = () => {
    ViewAllSegmentsRef.current.click();
    ViewAllSegmentsRef.current.focus();
  }

  // const keyboardShorcuts = (e) => {
  //   if (e.altKey && e.shiftKey && e.key === "ArrowLeft") {
  //     prevRegionRef.current.click();
  //     prevRegionRef.current.focus();
  //   }
  //   if (e.altKey && e.shiftKey && e.key === "ArrowRight") {
  //     nextRegionRef.current.click();
  //     nextRegionRef.current.focus();
  //   }
  // }
  useEffect(() => {
    Mousetrap.bind(['alt+shift+left', 'option+shift+left'], (e) => {
      prevRegionShortcut()
    })
    Mousetrap.bind(['alt+shift+right', 'option+shift+right'], (e)=>{
      nextRegionShortcut()
    })
    Mousetrap.bind(['alt+shift+v', 'option+shift+v'], (e)=>{
      toggleViewAllSegmentsShortcut()
    })

    return () => {
      // document.removeEventListener("keydown", keyboardShorcuts);
      Mousetrap.unbind(['alt+shift+left', 'option+shift+left'])
      Mousetrap.unbind(['alt+shift+right', 'option+shift+right'])
      Mousetrap.unbind(['alt+shift+v', 'option+shift+v'])
    };
  }, [currentRegion]);
  useEffect(() => {
    if (loadingZoom.type === 'zoomIn' && loadingZoom.state) {
      setTimeout(() => {
        zoomInClick()
      }, 500);
    } else if (loadingZoom.type === 'zoomOut' && loadingZoom.state) {
      setTimeout(() => {
        zoomOutClick()
      }, 500);
    } else {
      setLoadingWave(false)
    }
  }, [loadingZoom.state])

  const clickZoomIn = () => {
    setLoadingWave(true)
    handleSetLoadingZoom('zoomIn', true)

  }
  const clickZoomOut = () => {
    setLoadingWave(true)
    handleSetLoadingZoom('zoomOut', true)
  }
  const zoomInClick = async () => {
    const zoom = await zoomIn(zoomLevel, websurfer, setZoomLevel)
    handleSetLoadingZoom('zoomIn', zoom)
  }

  const zoomOutClick = async () => {
    const zoom = await zoomOut(zoomLevel, websurfer, setZoomLevel)
    handleSetLoadingZoom('zoomOut', zoom)
  }
  const clickPreviusRegion = () => {
    handlePreviousRegion(
      regions,
      websurfer,
      setTimeSubRip,
      currentRegion,
      setActualTime,
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      user,
      setDescription,
      setIsActiveAudio,
      setCaption,
      wavesurfer,
      setIsPlaying,
      viewAllSegments,
      currentProject
    )
  }

  const clickNextRegion = () => {
    handleNextRegion(
      regions,
      websurfer,
      setTimeSubRip,
      currentRegion,
      setActualTime,
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      user,
      setDescription,
      setIsActiveAudio,
      wavesurfer,
      setIsPlaying,
      viewAllSegments,
      currentProject
    )
  }

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        aria-describedby="toast-message"
      >
        <Alert severity={statusSave ? "success" : "error"} id="toast-message">
          {message}
        </Alert>
      </Snackbar>

      <div className={styles.container__actions}>
        <div className={styles.actions__zoom}>
          <button className={styles.btn} tabIndex={-1} aria-hidden="true" onClick={clickZoomOut} disabled={(loadingWave || zoomLevel <= 21)} style={{ cursor: (loadingWave || zoomLevel <= 21) ? 'not-allowed' : 'pointer' }}>
            <ZoomOutIcon fontSize="large" sx={{ color: (loadingWave || zoomLevel <= 21) ? '#696969' : '#fff' }} />
          </button>
          <button className={styles.btn} tabIndex={-1} aria-hidden="true" onClick={clickZoomIn} disabled={(loadingWave || zoomLevel === 100)} style={{ cursor: (loadingWave || zoomLevel === 100) ? 'not-allowed' : 'pointer' }}>
            <ZoomInIcon fontSize="large" sx={{ color: (loadingWave || zoomLevel === 100) ? '#696969' : '#fff' }} />
          </button>
        </div>

        <div className={styles.actions__zoom}>
          {(window.location.href.includes('audio-descriptor') || window.location.href.includes('quality-control') || window.location.href.includes('script-adapter')) && (
            <button className={styles.btn} variant="contained" onClick={() => handleAddSegment()} aria-label="Add Segment">
              <AddCircleIcon sx={{ fontSize: 30 }} />
            </button>
          )}
          {window.location.href.includes('audio-descriptor') && (
            <button className={styles.btn} variant="contained" onClick={() => cutActualRegion()} aria-label="Cut Segment">
              <ContentCutIcon sx={{ fontSize: 30 }} />
            </button>
          )}
        </div>
        <button ref={ViewAllSegmentsRef}
          aria-label={viewAllSegments ? "Show all segments" : "Hide completed segments"}
          className={styles.btn} onClick={() => changeViewAllSegments()}>
          {viewAllSegments ? (
            <VisibilityOffIcon sx={{ fontSize: 30, color: "var(--orange)" }} />
          ) : (
            <VisibilityIcon sx={{ fontSize: 30 }} />
          )}
        </button>
        <div className={styles.actions__zoom}>
          <button className={styles.btn}
            id="prev-region"
            ref={prevRegionRef}
            variant="contained"
            onClick={() => clickPreviusRegion()}
            autoFocus
            aria-label={currentRegion?.data?.comment ? `Previous Region button, Actual segment duration is ${Math.round(duration * 10) / 10} seconds. There are comments in this segment, ${currentRegion.data.comment}` : `Previous Region button, Actual Segment duration is ${Math.round(duration * 10) / 10} seconds.`}
          >
            <SkipPreviousIcon fontSize="large" />
          </button>
          <button className={styles.btn}
            id="next-region"
            ref={nextRegionRef}
            variant="contained"
            onClick={() => clickNextRegion()}
            aria-label={currentRegion?.data?.comment ? `Next Region button, Actual segment duration is ${Math.round(duration * 10) / 10} seconds. There are comments in this segment, ${currentRegion.data.comment}` : `Next Region button, Actual segment duration is ${Math.round(duration * 10) / 10} seconds.`}
          >
            <SkipNextIcon fontSize="large" />
          </button>
        </div>

      </div>
    </>
  );
};
