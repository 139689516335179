import AddIcon from '@mui/icons-material/Add';
import { Box, Button, FormControl, MenuItem, OutlinedInput, Select, Switch, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styles from "../../css/Form.module.css";
import { createCategory, getAllCategories } from '../../utils/project/CRUD';
const filter = createFilterOptions();

export const FormBasicData = ({
  createProject,
  nameProject,
  setNameProject,
  setSelectedDates,
  typeOfService,
  setTypeOfService,
  selectedDates,
  setCategoryProject,
  categoryProject,
  setClientProject,
  clientProject,
}) => {
  const [showAutocompleteCategory, setShowAutocompleteCategory] = useState(false);
  const [showAutocompleteClient, setShowAutocompleteClient] = useState(true);
  const [switchClientAD, setSwitchClientAD] = useState(false);
  const [switchClientAN, setSwitchClientAN] = useState(false);
  useState(false);
  const [value, setValue] = useState(null);
  const toggleAutocompleteCategory = () => setShowAutocompleteCategory(!showAutocompleteCategory);
  const toggleSwitchClientAD = () => setSwitchClientAD(!switchClientAD);
  const toggleSwitchClientAN = () => setSwitchClientAN(!switchClientAN);

  useEffect(() => {
    if (categoryProject.status === true) {
      setShowAutocompleteCategory(true);
      setValue({
        name: categoryProject.name,
      });
    }

    if (clientProject.status === true) {
      setShowAutocompleteClient(true);
      setSwitchClientAD(clientProject.actions[0]);
      setSwitchClientAN(clientProject.actions[1]);
    }

  }, []);

  useEffect(() => {
    if ((value !== null && value !== '') && showAutocompleteCategory === true) setCategoryProject({status: true,name: value.name});
     else if ((value === null || value === '') && showAutocompleteCategory === false)  setCategoryProject({status: false,name: ''});
     else if ((value === null || value === '') && showAutocompleteCategory === true) setCategoryProject({status: true,name: ''});
  }, [value, showAutocompleteCategory]);
  const handleSwitchClientAD = () => {
    setSwitchClientAD((prev) => {
      const newValue = !prev;
      if (!newValue && !switchClientAN) {
        setShowAutocompleteClient(false);
      } else {
        setShowAutocompleteClient(true);
      }
      return newValue;
    });
  };
  
  const handleSwitchClientAN = () => {
    setSwitchClientAN((prev) => {
      const newValue = !prev;
      if (!newValue && !switchClientAD) {
        setShowAutocompleteClient(false);
      } else {
        setShowAutocompleteClient(true);
      }
      return newValue;
    });
  };
  
  useEffect(() => {
    if (!switchClientAD && !switchClientAN) {
      setClientProject({ status: false, actions: [false, false] });
      setShowAutocompleteClient(false); // Set to false if both are empty
    } else {
      setClientProject({ status: true, actions: [switchClientAD, switchClientAN] });
    }
  }, [switchClientAD, switchClientAN]);
  useEffect(() => {
    if (showAutocompleteClient === false) setClientProject({status: false,actions: [false, false]});
    else setClientProject({status: true,actions: [switchClientAD, switchClientAN]});
  }, [switchClientAD, switchClientAN, showAutocompleteClient]);

  const toggleAutocompleteClient = () => {
    setShowAutocompleteClient(!showAutocompleteClient);
    if (showAutocompleteClient === false) setClientProject({status: false,actions: [false, false]});
    else setClientProject({status: true,actions: [switchClientAD, switchClientAN]});
  }
  const [categories, setCategories] = useState([]);
  const handleSetDate = (date) => {
    if (validateDateEnd(date)) setSelectedDates({start: new Date(),end: new Date(date)});
  };

  useEffect(() => {
    getAllCategories().then((res) => setCategories(res.categories));
  }, []);

  const addCategory = async () => {
    if (value !== null) {
      try {
        await createCategory(value.name);
        categories.push(value);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validateDateEnd = (date) => {
    if (date < new Date()) return false;
    return true;
  };

  return (
    <Box
      className={styles.container__form}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <h2 className={styles.title_forms}>Create new project</h2>
      {showAutocompleteCategory === false && (
        <div className={styles.label_spaceBetween}>

          <label className={styles.label}>Category</label>
          <Switch aria-label="checked"
            onChange={toggleAutocompleteCategory} />
        </div>
      )}
      {showAutocompleteCategory && (
        <>
          <div className={styles.label_spaceBetween}>

            <label className={styles.label}>Category</label>
            <Switch aria-label="checked" checked
              onChange={toggleAutocompleteCategory} />
          </div>
          <div className={styles.label_spaceBetween}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setValue({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={categories}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              renderOption={(props, option) => <li {...props}>{option.name}</li>}
              sx={{
                width: '100%',
                backgroundColor: "white",
                color: "black",
                textAlign: "initial",
                m: 1,
              }}
              freeSolo
              renderInput={(params) => (

                <TextField {...params} placeholder='Select a category' />
              )}
            />

            <Button variant="outlined" onClick={() => addCategory()} sx={{ color: 'white', borderColor: 'white', height: '55px', m: 1 }}><AddIcon /></Button>

          </div>
        </>
      )}
      {createProject.map(
        ({ name, type, required, value, placeholder, options }, index) => (
          <FormControl
            key={index}
            sx={{
              m: 1,
            }}
            variant="outlined"
          >
            <label className={styles.label} htmlFor={name}>
              {name} {required && "*"}
            </label>
            {type === "text" && (
              <OutlinedInput
                id={name}
                sx={{ backgroundColor: "white" }}
                value={nameProject}
                placeholder={placeholder}
                onChange={(e) => setNameProject(e.target.value)}
              />
            )}
            {type === "select" && (
              <Select
                id={name}
                input={<OutlinedInput />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  textAlign: "initial",
                  /*marginBottom: "1rem",*/
                }}
                value={typeOfService}
                label={placeholder}

                onChange={(e) => setTypeOfService(e.target.value)}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className={styles.placeholder}>
                        Select a service
                      </span>
                    );
                  }
                  if (selected === "audio-description") {
                    return "Audio Description";
                  } else if (selected === "sign-language-caption") {
                    return "Sign Language Caption";
                  } else if (selected === "dubbing") {
                    return "Dubbing";
                  } else if (selected === "audio-description+dubbing") {
                    return "Audio Description + Dubbing";
                  } else if (selected === "translation+audio-description") {
                    return "Translation + Audio Description";
                  }

                  return selected.label;
                }}
              >
                {options.map(({ value, label }, index) => (
                  <MenuItem
                    key={index}
                    value={value}
                    disabled={value === 'audio-description+dubbing'}> {label}</MenuItem>
                ))}
              </Select>
            )}
            {type === "date" && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={styles.datepicker}
                    sx={{ backgroundColor: "white" }}
                    onChange={handleSetDate}
                    value={dayjs(selectedDates.end)}
                  />
                </LocalizationProvider>
              </>
            )}
          </FormControl>
        )
      )}

      {(typeOfService === "audio-description" && !showAutocompleteClient) && (<div className={styles.label_spaceBetween}>

        <label className={styles.label}>Client</label>
       
      </div>)}
      {(typeOfService === "audio-description" && showAutocompleteClient) && (<div className={styles.label_spaceBetween}>

        <label className={styles.label}>Client</label>
       

      </div>)}
      {typeOfService === "audio-description" && (
  <div className={styles.optionsClient}>
    <div className={styles.label_spaceBetween}>
      <label className={styles.label}>Audio Description</label>
      <Switch aria-label="checked" onChange={handleSwitchClientAD} checked={switchClientAD} />
    </div>
    <div className={styles.label_spaceBetween}>
      <label className={styles.label}>Narrator</label>
      <Switch aria-label="checked" onChange={handleSwitchClientAN} checked={switchClientAN} />
    </div>
  </div>
)}
    </Box>
  );
};
