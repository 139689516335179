import { generateBlobfromURLData, removeBlobURL } from "../audio/generateBlob";
import {
  updateCommentsSegment,
  updateDescriptionSegment,
} from "../descriptions/description";

export const eachRegionsLoop = (currentProjectRole, regionCreate) => {
  const labelRegions = document.querySelectorAll("region");
  const regionId = regionCreate.id;
  labelRegions.forEach((region) => {
    if (region.querySelector("img.loop")) return;
    const handlesStart = region.querySelector(".wavesurfer-handle-start");
    const handlesEnd = region.querySelector(".wavesurfer-handle-end");
    const regionSelect = region.dataset.id === regionId ? regionCreate : null;
    if ((currentProjectRole === "AD" && !regionSelect.data?.review?.approved && !window.location.href.includes("narrator-dubbing") ) || (currentProjectRole === "QC" || window.location.href.includes("script-adapter") ||window.location.href.includes("audio-descriptor/"))) {
      const next = document.createElement("span");
      next.classList.add("next");
      next.innerHTML = ">";
      handlesEnd.appendChild(next);

      const prev = document.createElement("span");
      prev.classList.add("prev");
      prev.innerHTML = "<";
      handlesStart.appendChild(prev);
    } else {
      handlesEnd.style.display = "none";
      handlesStart.style.display = "none";
    }
    const loop = document.createElement("img");
    loop.classList.add("loop");
    loop.src = "/assets/img/circulo.png";
    region.appendChild(loop);
  });
};

export const searchRegion = (id, websurfer) => {
  const region = websurfer.current.regions.list[id];
  return region;
};

export const eachRegions = async (currentProject, user, websurfer,setDataLength) => {
  const hasVoiceOver = currentProject?.users?.some((user) => user?.role === 'AN_D'); 
  let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;
  await fetch(urlPath)
    .then((response) => response.json())
    .then((data) => {
      let count = 0;
      if (data.project.service === 'dubbing'|| (hasVoiceOver && (window.location.href.includes("narrator-dubbing"))) ) {
        setDataLength(data.project.captions.length)
        data.project.captions.forEach(async (region) => {
          let audiorecord = region.audiorecord.initial;
          let blobURL
          if (audiorecord) {
            blobURL = await generateBlobfromURLData(region.audiorecord.initial);
            removeBlobURL(blobURL);
            blobURL = await generateBlobfromURLData(region.audiorecord.initial);
          }
          let regionData = { ...region, id: region._id, blob: blobURL };
          if (regionData.user === user.uid) {
            websurfer.current.addRegion({
              start: region.start,
              end: region.end,
              color: "#12B4B899",
              data: regionData,
            });
          }else if (regionData.user !== user.uid && data.project.rol.role.includes("QC")) {
            websurfer.current.addRegion({
              start: region.start,
              end: region.end,
              color: "#CCCCCC99",
              data: regionData,
            });
        }
        });
      } else if (data.project?.service === 'sign-language-caption') {
        setDataLength(data.project.segmentsSign.length)
        // Ordenar los segmentos por el inicio
        data.project.segmentsSign.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
        // Agregar los segmentos al reproductor
        data.project.segmentsSign.forEach(async (region, index) => {
          let regionData = { ...region, id: region._id };
          delete regionData._id;



          if (data.project?.rol?.role === 'INTERPRETER') {
            let start = index === 0 ? 0 : data.project.segmentsSign[index - 1].end;
            // Agregar la región al reproductor
            websurfer.current.addRegion({
              start: start,
              end: regionData.end,
              data: regionData
            });

            websurfer.current.addRegion({
              start: region.start,
              data: { isMark: true },
              interact: false, // Desactivar la interacción de clic en la región


            });
          } else {
            websurfer.current.addRegion({
              start: region.start,
              end: region.end,
              color: "#12B4B899",
              data: regionData,
            });

          }
        });


      } else {
        const segments = data.project.segments;
        if(window.location.href.includes("/quality-control/")&&data.project.audiofinal){
          // anandir a la lista de segmentos los segmentos de captions
          segments.push(...data.project.captions);
        }

        if(window.location.href.includes("/client/")&&data.project.audiofinal){
          // anandir a la lista de segmentos los segmentos de captions
          segments.push(...data.project.captions);
        }
        
        setDataLength(segments.length)
        segments.forEach(async (region) => {
          if (!region.review?.approved || true) {
            let audiorecord = region.audiorecord.initial
            let blobURL
            if (audiorecord) {
              blobURL = await generateBlobfromURLData(region.audiorecord.initial);
              removeBlobURL(blobURL);
              blobURL = await generateBlobfromURLData(region.audiorecord.initial);
            }
            let regionData = { ...region, id: region._id, blob: blobURL };
            delete regionData._id;
            let addColor = "";

            if (!window.location.href.includes("/quality-control/") && !window.location.href.includes("/client")) {
              addColor = region.description.initial !== region.description.preview ? "#8EC36A99" : "#12B4B899";
              if (region.review?.approved && window.location.href.includes("/audio-descriptor")) {
                addColor = "#CCCCCC99";
              }
            } else {
              if (region.review.approved) {
                addColor = "#8EC36A99";
              } else if (
                (region.review.approved === false && (region.review.comment[0] === "" || region.review.comment[0] === null)) ||
                (region.review.approved === false && (region.review.comment[0] === region.review.comment[1]))
              ) {
                addColor = "#12B4B899";
              } else if (region.review.approved === false && (region.review.comment[0] !== region.review.comment[1])) {
                addColor = "rgba(255, 19, 0, 0.3)";
              } else {
                addColor = "#12B4B899";
              }
            }
            if (window.location.href.includes("/narrator")) {
              if (audiorecord) {
                addColor = "#8EC36A99";
              } else {
                addColor = "#12B4B899";
              }
              if (region.review?.approved) {
                addColor = "#CCCCCC99";
              }
              if (region.review?.comment[0] && region.review?.approved === false) {
                regionData.isDisabled = true;
              } else {
                regionData.isDisabled = false;
              }

            }
            websurfer.current.addRegion({
              start: region.start,
              end: region.end,
              color: addColor,
              data: regionData,
            });
            count++;
          }
        });
      }

    });

};

export const verifiedSegmentsComplete = (websurfer) => {
  const regionsList = websurfer.current.regions.list;
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];
    const { initial, preview } = region.data.description;
    if (!region.data?.review?.approved) {
      if (!initial || initial === "" || initial === " " || (initial === preview && region.isUpdate == false))
        return false;
    }
  }
  return true;
};

export const verifiedSegmentsSignComplete = (websurfer) => {
  const regionsList = websurfer.current.regions.list;
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];
    const { script } = region.data;
    if (script === "" || script === " " || script === null || script === undefined) {
      return false;
    }
  }
  return true;
}

export const verifiedSegmentsRecordSignComplete = (websurfer) => {
  const regionsList = websurfer.current.regions.list;
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];
    if (!region.data.isMark) {
      const { videorecord } = region.data;
      if (videorecord === "" || videorecord === " " || videorecord === null || videorecord === undefined) {
        return false;
      }
    }
  }
  return true;

};


export const verifiedSegmentsRecordComplete = (websurfer) => {
  const regionsList = websurfer.current.regions.list;
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];
    const { initial= "", preview="" } = region.data?.audiorecord;
    if (!region.data?.review?.approved) {
      if (!initial || initial === "" || initial === " " || (initial === preview )){
      return false;
    }
  }
  }
  return true;
};

export const verifiedSegmentsRecordDubbingComplete = (websurfer) => {
  const regionsList = websurfer.current.regions.list;
  for (const regionKey in regionsList) {
    const region = regionsList[regionKey];
    const { initial= "", preview="" } = region.data?.audiorecord;
    if (!region.data?.review?.approved) {
      if (!initial || initial === "" || initial === " " || (initial === preview )){
      return false;
    }
  }
  }
  return true;
};

export const deleteSegment = async (region, setRegions, setCurrentRegion) => {
  const path = window.location.href.includes("/script-adapter/") ? `/api/segmentsSign/${region.data.id}` : `/api/segments/${region.data.id}`;
  try {
    const response = await fetch(`${process.env.REACT_APP_URL}${path}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
    });
    const data = await response.json();
    region.remove();
    setRegions((oldRegions) => oldRegions.filter((r) => r !== region));
    setCurrentRegion(null);
    return data.status;
  } catch (err) {
    console.error("error", err);
    return false;
  }
};

export const getRegionsData = (websurfer) => {
  const regions = websurfer.current.regions.list;
  var regionsData = {};
  regionsData.segments = {};
  for (const region in regions) {
    regionsData.segments[region] = {
      start: regions[region].start,
      end: regions[region].end,
      duration: regions[region].end - regions[region].start,
      data: regions[region].data,
    };
  }
  regionsData.length = Object.keys(regionsData.segments).length;
  return regionsData;
};

export const handleRegionUpdate = (
  region,
  data,
  type,
  regions,
  currentRegion,
  startTime,
  endTime,
  setWordsCount,
  setDuration,
  description,
  setCharactersCount,
  setRecommendCharacterMin,
  setRecommendCharacterMax
) => {
  if (regions.length > 0 && currentRegion !== null) {
    if (type === "description") {
      updateDescriptionSegment(
        region,
        data,
        startTime,
        endTime,
        setWordsCount,
        setDuration,
        description,
        setCharactersCount,
        setRecommendCharacterMin,
        setRecommendCharacterMax
      );
    }
    if (type === "comments") {
      updateCommentsSegment(
        region,
        data,
        startTime,
        endTime,
        setWordsCount,
        setDuration,
        endTime - startTime
      );
    }
  }
};
