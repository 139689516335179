import SendIcon from "@mui/icons-material/Send";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataUser } from "../context/DataUser";
import styles from "../css/Send.module.css";
import { generateMixAudio } from "../utils/audio/generateMix";
import { getProject } from "../utils/project/CRUD";
import { sendEmailNeedExternalAudio } from "../utils/project/sendEmails";
import { ModalCheckSend } from "./ModalCheckSend";

export const Send = () => {
  const { segmentsComplete } = useContext(DataDescriptor);
  const { updateRole, currentProject } = useContext(DataUser);
  const [openToast, setOpenToast] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { getProyects } = useContext(DataUser);
  const [rol, setRol] = useState("")
  const hasVoiceOver = currentProject?.users?.filter((user) => user?.role === 'AN_D');
  const [openModalCheck, setOpenModalCheck] = useState(false);
  const navigate = useNavigate();

  const handleClickOpenModalCheck = () => setOpenModalCheck(true);

  const sendProject = async () => {
    setOpenToast(true);
    setDisabled(true);
    if (!currentProject.rol?.role.includes("QC") && !window.location.href.includes("quality-control")) {
      if (window.location.href.includes("audio-descriptor")) {
        setRol("Quality Control")
        await updateRole("QC");
      }
      else if (window.location.href.includes("/narrator")) {
        if (hasVoiceOver) {
          const resp = await checkNarratorVoiceOver()
          console.log("checkNarratorVoiceOver",resp);
          if (resp) {
            setRol("Audio Editor")
            await updateRole("AE");
            if (currentProject.needExternalAudio) {
              sendEmailNeedExternalAudio(currentProject._id);
            } else {
              generateMixAudio(currentProject._id);
            }
          }else{
            setRol("Narrator await all narrators to finish")
          }
        }
       
      } else if (window.location.href.includes("/script-adapter/")) {
        setRol("Interpreter")
        await updateRole("INTERPRETER");
      } else if (window.location.href.includes("/interpreter/")) {
        setRol("Audio Editor")
        await updateRole("AE");
        generateMixAudio(currentProject._id);
      } else if (window.location.href.includes("narrator-dubbing")) {
        const resp = checkNarratorVoiceOver()

        if (resp) {
          setRol("Quality Control")
          await updateRole("QC2");
        } else {
          setRol("Narrator await all narrators to finish")
          await updateRole("AN_D");

        }
      }
    } else {
      setRol("Audio Descriptor")
      await updateRole("AD");
    }
    setTimeout(() => {
      setDisabled(false);
      setOpenToast(false);
      getProyects()
      navigate("/dashboard");
    }, 3000);
  };

  const checkNarratorVoiceOver = async () => {
    const { project } = await getProject(currentProject._id);
    const { captions } = project;
    const captionsWithoutAudio = captions.filter((caption) => !caption.audiorecord.initial)
console.log(captions);
    const captionsWithoutAudioToCorrect = captions.filter((caption) => caption.review.approved === false && caption.review.comment[0] !== "" && caption.audiorecord.initial === caption.audiorecord.preview);
    console.log("captionsWithoutAudio",captionsWithoutAudio);
    if (captionsWithoutAudio.length > 0 || captionsWithoutAudioToCorrect.length > 0) {
      setRol("Narrator await all narrators to finish")
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"success"} variant="filled">
          Sending to {rol}
        </Alert>
      </Snackbar>
      {disabled ?
        <button
          className={styles.button__send__disabled}
          disabled={disabled}
        >
          <CircularProgress aria-label='Loading' />
        </button>
        : (
          <button
            className={segmentsComplete ? styles.button__send : styles.button__send__disabled}
            onClick={() => handleClickOpenModalCheck()}
            disabled={!segmentsComplete}
            aria-label="Send Button"
            tabIndex="0"
          >
            <SendIcon sx={{ fontSize: 40 }} />
            <span>SEND</span>
          </button>
        )}

      <ModalCheckSend
        open={openModalCheck}
        setOpen={setOpenModalCheck}
        sendProject={sendProject}
        disabled={disabled}
      />

    </>
  );
};
