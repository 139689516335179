import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/common/Footer";
import { Header } from "./components/common/Header";
import { DataDashboardProvider } from "./context/DataDashboard";
import { DataPMProvider } from "./context/DataPM";
import { DataUserProvider } from "./context/DataUser";
import { DataVoiceOverProvider } from "./context/DataVoiceOver";
import { Client } from "./pages/Client";
import { Dashboard } from "./pages/Dashboard";
import { Descriptor } from "./pages/Descriptor";
import { Interpreter } from "./pages/Interpreter";
import { Login } from "./pages/Login";
import { Narrator } from "./pages/Narrator";
import { NarratorVoiceOver } from "./pages/NarratorVoiceOver";
import { QualityControl } from "./pages/QualityControl";
import { SRTtoCSV } from "./pages/SRTtoCSV";
import { ScriptAdapter } from "./pages/ScriptAdapter";
import SegmentationVoiceOver from "./pages/SegmentationVoiceOver";
import { Viewer } from "./pages/Viewer";

function App() {
  return (
    <DataUserProvider>
      <DataPMProvider>
        <DataDashboardProvider>
          <DataVoiceOverProvider>

            <div className="App">
              <Header />
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/audio-descriptor/:id" element={<Descriptor />} />
                <Route path="/quality-control/:id" element={<QualityControl />} />
                <Route path="/narrator/:id" element={<Narrator />} />
                <Route path="/narrator-dubbing/:id" element={<NarratorVoiceOver />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/script-adapter/:id" element={<ScriptAdapter />} />
                <Route path="/interpreter/:id" element={<Interpreter />} />
                <Route path="/viewer/:id" element={<Viewer />} />
                <Route path="/client/:id" element={<Client />} />
                <Route path="/segmentation-voice-over" element={<SegmentationVoiceOver />} />
                <Route path="/segmentation-voice-over/:video" element={<SegmentationVoiceOver />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/srt-to-excel" element={<SRTtoCSV />} />
              </Routes>
              <Footer />
            </div>
          </DataVoiceOverProvider>

        </DataDashboardProvider>
      </DataPMProvider>
    </DataUserProvider>
  );
}

export default App;
