
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import Mousetrap from 'mousetrap';
import React, { useContext, useEffect, useRef } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataNarrator } from "../../context/DataNarrator";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/Narrator.module.css";


export const NarratorTabs = () => {
    const {
        selectedButton, 
        isDescriptionButtonActive, 
        isCommentButtonActive,
        isEditingDescription, setIsEditingDescription,
        currentDescription, setCurrentDescription,
        handleEditingDescription,
        handleButtonClick,
        handleReasonEditor,
        handleDescriptionEditor,
    } = useContext(DataNarrator);
    const { currentRegion, focusDescription, setFocusDescription } = useContext(DataDescriptor);
    const { currentProject } = useContext(DataUser);



    useEffect(() => {
        setIsEditingDescription(false); // to avoid the description editor to be open when changing btwn regions
        handleButtonClick("descriptionBtn")
        
    }, [currentRegion]);


    // todo: ------------------------Read description from DB------------------------
    useEffect(() => {
        (async () => {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
            const result = await response.json();
            const resultID = result.filter(segment => segment._id === currentRegion.data.id);
            console.log(result);
            if (isEditingDescription) {
                document.getElementById("description-editor").value = resultID[0]?.description.initial;
                document.getElementById("reason-editor").value = resultID[0].description.reason4change;
            }
            setCurrentDescription(resultID[0]?.description);
            //console.log(resultID[0].description);
        })();
    }, [isEditingDescription, currentRegion]);



    // todo: ------------------------ Keyboard Shortcuts: Comments and Description Focus ------------------------
    const commentsButtonRef = useRef(null);
    const descriptionButtonRef = useRef(null);

    const descriptionShortcut = (e) => {
        // if (e.key.toLowerCase() === "x" && (e.altKey || e.keyCode == 99 || e.keyCode == 67)) {
        descriptionButtonRef.current?.focus();
        // }
    }

    const commentsShortcut = (e) => {
        // if (e.key.toLowerCase() === "c" && (e.altKey || e.keyCode == 99 || e.keyCode == 67)) {
        if (currentRegion.data.comment) {
            commentsButtonRef.current?.focus();
        } else {
            speechSynthesis.cancel();
            const utterance = new SpeechSynthesisUtterance('No annotation in this segment');
            utterance.rate = 1;
            utterance.lang = "en-US";
            speechSynthesis.speak(utterance);
            utterance.addEventListener('end', () => {
                speechSynthesis.cancel();
            });
        }
        // }
    }
    
    useEffect(() => {
        // mostrar logs de los eventos
        Mousetrap.bind(['alt+t', 'option+t'], (e) => {
            descriptionShortcut(e);
        })

        Mousetrap.bind(['alt+c', 'option+c'], (e) => {
            commentsShortcut(e);
        })
        return () => {
            Mousetrap.unbind(['alt+t', 'option+t'])
            Mousetrap.unbind(['alt+c', 'option+c'])
        };
    }, [currentRegion]);

    //todo ------------------------ Keyboard Shortcuts: Description Editor ------------------------

    const descriptionRef = useRef(null);

    useEffect(() => {
        if (focusDescription) {
            descriptionRef.current.focus();
            setFocusDescription(false)
        }
    }, [focusDescription])


    return (
        <div className={styles.n_container}>
            <div id="buttons_container" className={styles.div__btnsContainer}>
                <div
                    id='descriptionBtn'
                    ref={descriptionButtonRef}
                    className={`
                    ${styles.btn__description} ${styles.btn__n}
                    ${selectedButton === 'descriptionBtn' ? styles.btn__selected : styles.btn__unselected}
                    ${currentRegion.data.comment ? "" : styles.btn__withoutComment}`
                    }
                    onClick={() => handleButtonClick("descriptionBtn")}
                    aria-label='Read description,'
                    aria-describedby='current-description'
                    tabIndex={0}

                >
                    <TextSnippetOutlinedIcon />
                    <p >Description</p>
                    <button id="edit-description-btn" tabIndex={0} aria-label="Edit description" onClick={handleEditingDescription}
                    >
                        {isEditingDescription ? (
                            <HighlightOffIcon
                                aria-label="Cancel editing description"
                            />
                        ) : (
                            <EditOutlinedIcon
                                aria-label="Edit description"
                                sx={currentRegion.data?.review?.approved ? { display: "none" } : { display: "block" }}
                            />
                        )}
                    </button>
                </div>
                {currentRegion.data.comment ? (
                    <button
                        id="commentsBtn"
                        className={`${styles.btn__comments} ${styles.btn__n} ${selectedButton === "commentsBtn"
                            ? styles.btn__selected
                            : styles.btn__unselected
                            }`}
                        ref={commentsButtonRef}
                        onClick={() => handleButtonClick("commentsBtn")}
                        aria-label="Read comments,"
                        aria-describedby='current-comment'
                        tabIndex={0}
                    >
                        <LabelImportantOutlinedIcon />
                        <p>Annotation</p>
                    </button>
                ) : null}
            </div >

            {isDescriptionButtonActive && !isEditingDescription ? (
                <p ref={descriptionRef} id='current-description' aria-label={'current description is,' + currentDescription?.initial + ". Shortcut to focus: Alt + X"} className={styles.n_content} tabIndex="0">
                    {currentDescription?.initial}
                </p>
            ) : null}

            {isEditingDescription ? (
                <>
                    <textarea
                        id='description-editor'
                        onChange={handleDescriptionEditor}
                        className={styles.textArea_editing_description}
                        aria-label="Description editor"
                    />

                    <h2 tabIndex={0} style={{ display: "flex", alignItems: "center" }}><ChatBubbleOutlineIcon />&nbsp;&nbsp;Reason for change</h2>
                    <textarea
                        id='reason-editor'
                        onChange={handleReasonEditor}
                        className={styles.textArea_editing_description}
                        aria-label="Reason editor"
                    />
                </>
            ) : null}

            {isCommentButtonActive ? (
                <h4 className={styles.n_content} id='current-comment' aria-label={'current comment is,' + currentRegion?.data?.comment + ', Shortcut to focus: Alt + C'} tabIndex="0">
                    {currentRegion.data.comment}
                </h4>
            ) : null}

        </div >
    )
};
