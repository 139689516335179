import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import React from "react";
import { Form } from "../components/projectManager/Form";
import styles from "../css/FormsCreateProject.module.css";

export const FormsCreateProject = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header__Projects}>
        <h2>
          <CreateNewFolderIcon /> New Project
        </h2>
      </header>
      <div className={styles.container_createForm}>
        <Form />
      </div>
    </div>
  );
};
