import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Divider, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styles from "../../css/Header.module.css";
export const ModalShortcuts = ({ openShortcuts, setOpenShortcuts }) => {
    const isWinUser = navigator.platform.includes("Win");
    const macShortcuts = [
        "Option + Shift + k",
        "Option + Shift + ⬅️",
        "Option + Shift + ➡️",
        "Option + Shift + o",
        "Option + Shift + p",
        "Option + Shift + v",
        "Option + Shift + r",
        "Option + t",
        "Option + c",
        "Option + q",
        "Option + l",
        "Spacebar"
    ];

    const windowsShortcuts = [
        "Alt + Shift + k",
        "Alt + Shift + ⬅️",
        "Alt + Shift + ➡️",
        "Alt + Shift + o",
        "Alt + Shift + p",
        "Alt + Shift + v",
        "Alt + Shift + r",
        "Alt + t",
        "Alt + c",
        "Alt + q",
        "Alt + l",
        "Spacebar"
    ];

    const downloadShortcuts = () => {
        const element = document.createElement("a");
        const fileMac = "/assets/macShortcuts.pdf"
        const fileWin = "/assets/winShortcuts.pdf"
        element.setAttribute("href", isWinUser ? fileWin : fileMac);
        element.setAttribute("download", "Shortcuts.pdf");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <Dialog open={openShortcuts} onClose={() => setOpenShortcuts(false)}        >
            <DialogTitle
                sx={{
                    color: "white",
                    backgroundColor: "#28262E",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2rem",
                    paddingTop: "2rem",
                }}
                tabIndex={-1}
            >
                <KeyboardIcon fontSize="large" />

                <p tabIndex={0} autoFocus>
                    &nbsp;KEYBOARD HOTKEYS
                </p>
                <IconButton aria-label={`download shortcuts ${isWinUser ? "for windows" : "for mac"}`} sx={{ color: "white", marginLeft:"1rem" }} onClick={downloadShortcuts}>
                    <DownloadForOfflineIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                    tabIndex={10}
                    onClick={() => setOpenShortcuts(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent
                sx={{
                    backgroundColor: "#28262E",
                    width: "100%",
                }}
                className="progressModal"
            >
                <div className={styles.content_progress}>
                    <div className={styles.content_text_shortcuts}>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                            margin={0}
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Open Shortcuts List:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[0] : macShortcuts[0]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                            margin={0}
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Previous Region:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[1] : macShortcuts[1]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Next Region:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[2] : macShortcuts[2]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Change Narration Type:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[3] : macShortcuts[3]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Play Recorded Segment:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[4] : macShortcuts[4]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Toggle View / Hide Completed Segments:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[5] : macShortcuts[5]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Start Recording Narration:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[6] : macShortcuts[6]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Focus Description:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[7] : macShortcuts[7]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Focus Comments:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[8] : macShortcuts[8]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Focus QC Comments:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[9] : macShortcuts[9]}</h5>
                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Current Region Duration:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser ? windowsShortcuts[10] : macShortcuts[10]}</h5>

                        </Typography>
                        <Typography
                            tabIndex={0}
                            variant="h6"
                            component="div"
                            color="#fff"
                            fontSize="1.3rem"
                            fontWeight="bold"
                            display='flex'
                            alignItems='center'
                        >
                            <KeyboardArrowRightIcon fontSize="large" />
                            Play audio:
                            <h5> &nbsp;&nbsp;&nbsp;{isWinUser? windowsShortcuts[11]: macShortcuts[11]}</h5>

                        </Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
