import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Checkbox, FormControlLabel } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { green } from "@mui/material/colors";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataQC } from '../../context/DataQC';
import { DataUser } from "../../context/DataUser";
import styles from "../../css/QCReview.module.css";
import ModalDelete from "../describer/ModalDelete";

export const QCReview = () => {

  const { currentRegion, recommendCharacterMax } = useContext(DataDescriptor);
  const { currentProject } = useContext(DataUser);
  const marketDescription = useRef();

  const {
    selectedButton,
    isDescriptionButtonActive,
    isPreviewButtonActive,
    isClientButtonActive,
    isCommentButtonActive,
    isReasonButtonActive,
    handleButtonClick,
    handleEditingDescription,
    handleDescriptionEditor,
    currentDescription, setCurrentDescription,
    isEditingDescription, setIsEditingDescription,
    handleChangeCHBX,
    handleSaveComment,
    allApproved,
    singleApproved, setSingleApproved,
    setTextarea,
    handleTextAreaComments,
    openToast, setOpenToast,
    message,
    statusSave
  } = useContext(DataQC);

  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(() => {
    handleButtonClick("descriptionBtn")
  }, [currentRegion]);

  // todo: ------------------------Read description from DB------------------------
  useEffect(() => {
    const hasVoiceOver = currentProject?.users?.filter((user) => user?.role === 'AN_D');

    (async () => {
      if (isEditingDescription) {
        document.getElementById("description-editor").value = currentRegion.data?.description?.initial;
      }
      if (currentProject.service === "dubbing") {
        setCurrentDescription(currentRegion.data.caption);
      } else {
        setCurrentDescription(currentRegion.data?.description);
        if (!currentRegion?.data?.description && hasVoiceOver) {
          setCurrentDescription(currentRegion.data.caption);
        }
      }
    })();
  }, [isEditingDescription, currentRegion]);

  // todo: -----------------------QC Review Data from DB-----------------------
  // Read data from DB and color selected region
  useEffect(() => {
    if (currentRegion) {
      document.getElementById("QCTextarea").value = currentRegion?.data?.review?.comment ? currentRegion?.data?.review?.comment[0] : '';
      setTextarea(currentRegion?.data?.review?.comment ? currentRegion.data.review.comment[0] : '');
      setSingleApproved(currentRegion.data.review.approved);
      currentRegion.update({ color: "rgba(255, 199, 0, 0.3)" });
      setIsEditingDescription(false); // to avoid the description editor to be open when changing btwn regions
    }
  }, [currentRegion]);

  const handleClickOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  return (
    <div className={styles.qc_container}>
      <div id="buttons_container" className={styles.div__btnsContainer}>
        <button
          id='descriptionBtn'
          className={`${styles.btn__description} ${styles.btn__qc} ${selectedButton === 'descriptionBtn' ?
            styles.btn__selected :
            styles.btn__unselected
            } ${currentRegion.data.comment === undefined && currentRegion.data.commentClient === "" && currentRegion.data?.description?.preview === ""
              ? styles.btn__comments_only
              : null
            }`}
          onClick={() => handleButtonClick("descriptionBtn")}
          aria-label='description'
          aria-describedby='current-description'
        >
          <TextSnippetOutlinedIcon />
          <p>Description</p>
          {currentProject.rol?.role !== "QC2" && isEditingDescription ? (
            <HighlightOffIcon onClick={handleEditingDescription} />
          ) : currentProject.rol?.role !== "QC2" ? (
            <EditOutlinedIcon onClick={handleEditingDescription} />
          ) : null}
        </button>

        {currentProject.rol?.role === "QC" ? (
          <button
            id="previewBtn"
            className={`
                        ${styles.btn__preview} 
                        ${styles.btn__qc} 
                        ${selectedButton === "previewBtn"
                ? styles.btn__selected
                : styles.btn__unselected
              }
                        ${currentRegion.data.comment === undefined && currentRegion.data.commentClient === ""
                ? styles.btn__comments
                : null
              }
                    `}

            style={{
              display:
                currentRegion.data?.description?.preview === ""
                  ? "none"
                  : "block",
            }}
            onClick={() => handleButtonClick("previewBtn")}
          >
            <p>Preview</p>
          </button>
        ) : null}

        {currentProject.rol?.role === "QC2" ? (
          <button
            id="reasonBtn"
            className={`${styles.btn__preview} ${styles.btn__qc} ${selectedButton === "reasonBtn"
              ? styles.btn__selected
              : styles.btn__unselected
              }`}
            style={{
              display:
                currentRegion?.data?.description?.reason4change === ""
                  ? "none"
                  : "block",
            }}
            onClick={() => handleButtonClick("reasonBtn")}
          >
            <p>Reason</p>
          </button>
        ) : null}

        {currentRegion.data.commentClient ? (
          <button
            id="clientBtn"
            className={`${styles.btn__preview} ${styles.btn__qc} ${selectedButton === "clientBtn"
              ? styles.btn__selected
              : styles.btn__unselected
              } ${currentRegion.data.comment === undefined
                ? styles.btn__comments
                : null
              }`}
            style={{
              display:
                currentRegion.data.commentClient === ""
                  ? "none"
                  : "block",
            }}
            onClick={() => handleButtonClick("clientBtn")}
          >
            <p>Client</p>
          </button>
        ) : null}

        <button
          id='commentsBtn'
          className={`${styles.btn__comments} ${styles.btn__qc} ${selectedButton === 'commentsBtn' ?
            styles.btn__selected :
            styles.btn__unselected
            }`}
          style={{
            display:
              currentRegion.data.comment === undefined
                ? "none"
                : "flex",
          }}
          onClick={() => handleButtonClick("commentsBtn")}
          aria-label="annotation"
          aria-describedby='current-comment'
        >
          <LabelImportantOutlinedIcon />
          <p>Annotation</p>
        </button>

        <div style={{ margin: '0px 15px' }}>
          {currentProject.rol?.role === "QC" ? (
            <>
              <button
                variant="contained"
                onClick={() => handleClickOpenModalDelete()}
                disabled={currentRegion.data?.review?.approved ? true : false}
                className={styles.button}
              >
                <DeleteIcon fontSize="large" />
              </button>
              <ModalDelete open={openModalDelete} setOpen={setOpenModalDelete} />
            </>
          ) : null}
        </div>

      </div>

      {isDescriptionButtonActive && !isEditingDescription ? (
        <>
          <div ref={marketDescription} id='current-description' aria-label={'current description is,' + currentDescription?.initial} className={styles.qc_content} >
            {currentProject.service === "dubbing" ? (
              <p>{currentDescription}</p>
            ) : (
              <p dangerouslySetInnerHTML={{ __html: currentDescription?.initial || currentDescription }}></p>
            )}
            <div
              className={styles.container__count}
              style={{
                color:
                  (currentDescription?.initial || currentDescription) > Math.round(recommendCharacterMax)
                    ? "red"
                    : "green",
              }}
            >
              <span className={styles.span__characterCount}>
                {Math.round(recommendCharacterMax) - (currentDescription?.initial || currentDescription)?.length}
              </span>
            </div>
          </div>
          <div className={styles.container__textCount}>

            {(currentDescription?.initial?.length ? currentDescription?.initial?.length : 0) > Math.round(recommendCharacterMax) && (
              <h4 style={{ color: "var(--orange)" }}>
                {" "}
                WARNING: You have exceeded the limit{" "}
                {Math.round(recommendCharacterMax)} you have {currentDescription?.initial?.length} characters.
              </h4>
            )}
          </div>
        </>
      ) : null}

      {isPreviewButtonActive ? (
        <p className={styles.qc_content} >
          {/* {currentRegion.data.description.preview ? currentRegion.data.description.preview : ''} */}
          {currentDescription?.preview ? currentDescription.preview : ''}
        </p>
      ) : null}

      {isReasonButtonActive ? (
        <p className={styles.qc_content} >
          {/* {currentRegion.data.description.preview ? currentRegion.data.description.preview : ''} */}
          {currentDescription?.reason4change ? currentDescription?.reason4change : ''}
        </p>
      ) : null}

      {isClientButtonActive ? (
        <p className={styles.qc_content} >
          {currentRegion.data?.commentClient ? currentRegion.data?.commentClient : ''}
        </p>
      ) : null}



      {isCommentButtonActive ? (
        <h4 id="current-comment" aria-label={'current comment is,' + currentRegion?.data?.comment} className={styles.qc_content} >
          {currentRegion.data.comment ? currentRegion.data.comment : ''}
        </h4>
      ) : null}

      {isEditingDescription ? (
        <div style={{ position: "relative" }}>
          <textarea
            id='description-editor'
            onChange={handleDescriptionEditor}
            className={styles.textArea_editing_description}
          />
          {/* {currentDescription.initial} */}
          <div
            className={styles.container__count}
            style={{
              color:
                currentDescription?.initial?.length > Math.round(recommendCharacterMax)
                  ? "red"
                  : "green",
            }}
          >
            <span className={styles.span__characterCount}>
              {Math.round(recommendCharacterMax) - currentDescription?.initial?.length}
            </span>
          </div>
        </div>
      ) : null}

      <hr />

      <div className={styles.qc__title}>
        <h2 className={styles.qc__title2}>QC Corrections</h2>
        <button onClick={handleSaveComment} aria-label="Save Quality Control Review">
          <SaveOutlinedIcon />
        </button>
      </div>
      <textarea
        id="QCTextarea"
        disabled={singleApproved || (singleApproved && allApproved)}
        // value={singleApproved || (singleApproved && allApproved) ? "": undefined}
        placeholder="Type Comments..."
        onChange={handleTextAreaComments}
        className={
          singleApproved || (singleApproved && allApproved)
            ? styles.textArea_description_db
            : styles.textArea_description
        }
      />
      <FormControlLabel
        className={styles.lb__approve}
        control={
          <Checkbox
            id="approveCHBX"
            // onChange={handleChangeCHBX}
            onClick={(e) => handleChangeCHBX(e)} // to avoid calls when only the current region changes
            // onChange={
            //   (e) => {
            //console.log('e.target.checked: ', e.target.checked);
            //     setSingleApproved(e.target.checked);
            //   }
            // }
            checked={singleApproved}
            sx={{
              color: green[300],
              "&.Mui-checked": {
                color: green[200],
              },
              // '&.Mui-disabled': {
              //     color: grey[900],
              // }
            }}
          />
        }
        label="Approve *"
        aria-label="Approve Segment"
      />
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusSave ? "success" : "error"}>{message}</Alert>
      </Snackbar>
    </div>
  );
};
