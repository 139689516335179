export const updateManyRegions = async (idProject, segments) => {
    console.log(segments);
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/updateByArray/${idProject}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ segments }),
    });
    const result = await response.json();
    console.log("updateManyRegions", result);

    return result;
};

export const updateManyRegionsVoiceOver = async (idProject, segments) => {
    console.log(segments);
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/updateByArray/${idProject}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ segments }),
    });
    const result = await response.json();
    console.log("updateManyRegionsVoiceOver", result);
    return result;
}