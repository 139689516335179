import { secondsToSubrip } from "./time/time";

export const segmentsToSRT = (segments) => {
  let srt = "";
  segments.forEach((segment, index) => {
    srt += `${index + 1}\n`;
    const [startHH, startMM, startSS, startMS] = secondsToSubrip(segment.start || segment.startTime);
    const [endHH, endMM, endSS, endMS] = secondsToSubrip(segment.end || segment.endTime);
    srt += `${startHH}:${startMM}:${startSS},${startMS} --> ${endHH}:${endMM}:${endSS},${endMS}\n`;

    srt += `${segment?.description?.initial || segment.text}\n\n`;
  });
  return srt;
};

export const requestSegmentstoSrt = async (currentProject) => {
  let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;

  await fetch(urlPath)
    .then((response) => response.json())
    .then((data) => {
      const segments = data.project.segments.sort((a, b) => a.start - b.start);
      const srt = segmentsToSRT(segments);
      generateBlobSRT(srt,currentProject.name);
      
    });
};

export const generateBlobSRT = (srt,name) => {
  const blob = new Blob([srt], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `${name }.srt`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
};
