
export const play3secondsPreviusSegment = (
  currentRegion,
  wavesurfer,
  setIsPlaying,
) => {
  const startTime = currentRegion.start-3;
  const endTime = currentRegion.end + 3;  
  if (startTime <= 0) {
    setTimeout(() => {
    wavesurfer.current.play(0, endTime);
    setIsPlaying(true);
    }, 3000);  
  }else{
    wavesurfer.current.play(startTime, endTime);
    setIsPlaying(true);
  } 
  return { duration: 3000};  
};

export const playSegment = (currentRegion,
  wavesurfer,
  setIsPlaying) => {
  const startTime = currentRegion.start;
  const endTime = currentRegion.end;
  wavesurfer.current.play(startTime, endTime);
  setIsPlaying(true);
  
  }
