export const zoomIn = async(zoomLevel,websurfer,setZoomLevel) => {
    if (zoomLevel < 100) {
      setZoomLevel(zoomLevel + 26.6);      
      await websurfer.current.zoom(zoomLevel + 26.6);
      await websurfer.current.drawBuffer();
    }
     return false;

  };

  export const zoomOut = async(zoomLevel,websurfer,setZoomLevel ) => {
    if (zoomLevel > 26.6) {
      setZoomLevel(zoomLevel - 26.6);      
      websurfer.current.zoom(zoomLevel - 26.6);
      websurfer.current.drawBuffer(); 
    }
    return false;
  };

  export const zoomInY = (zoomY,websurfer,setZoomY) => {
    if (zoomY < 10) {
      websurfer.current.params.barHeight = zoomY + 1;
      setZoomY(zoomY + 1);
      websurfer.current.drawBuffer();
    }
  };
  export const zoomOutY = (zoomY,websurfer,setZoomY) => {
    if (zoomY > 1) {
      websurfer.current.params.barHeight = zoomY - 1;
      setZoomY(zoomY - 1);
      websurfer.current.drawBuffer();
    }
  };
