import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { DataVoiceOver } from "../../context/DataVoiceOver";
import styles from "../../css/SegmentationVoiceOver.module.css";
import { uploadVideoVoiceOver } from "../../utils/audio/uploadS3";
import {
  assignIgnoredSegments,
  checkBDDiarization,
  compareAssignSpeaker,
  compareAssignSpeakerRTTM,
  generateDiarization,
  generateEmptySegmentsArray,
  groupBySpeaker,
  joinRTTM_SRT,
  joinSRTtoRTTM,
  joinSegmentsRTTM,
  verifiedIgnoredSegments,
} from "../../utils/regions/requestsRegions";
import { srtToJson } from "../../utils/regions/srtToJson";

const CardResources = ({ handleNext, setStep, handleLoading, loading,video }) => {
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [severityToast, setSeverityToast] = useState("success");
  const { handleSegmentation, handleVideo } = useContext(DataVoiceOver);

  const fileTypesVideo = ["mp4"];
  const fileTypesCaptions = ["srt"];
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({});

  // function cada 1 min ejecutarse cuando
  const checkDiarization = async () => {
    let existDB = false;
    let cont = 0;

    const interval = setInterval(async () => {
      cont = cont + 1;
      //simular que ya existe la base de datos
      const dataDiariazation = await checkBDDiarization(watch("video").name);
      if (dataDiariazation.status) {
        existDB = true;
        clearInterval(interval);
        filterSRT_RTTM(dataDiariazation);
        handleNext();
        setMessageToast("Diarization completed");
        setSeverityToast("success");
        setOpenToast(true);
        handleLoading(false);
      }
    }, 1000 * 60); // 1 minuto en milisegundos
  };

  const filterSRT_RTTM = (dataDiariazation) => {
    srtToJson(watch("captions")).then((res) => {
      let rttm = dataDiariazation?.diarization;
      const rttmSegments = dataDiariazation?.diarization?.segmentos.map(
        (arr) => arr[0]
      );
      rttm["segmentos"] = rttmSegments;
      const srt = res;

      const srtWithSpeakers = joinRTTM_SRT(srt, rttm);
      const arregloAgrupado = groupBySpeaker(srt);
      const assignUndefined = compareAssignSpeaker(srtWithSpeakers);

      const [rttmWithSpeakers, segmentosIgnorados] = joinSRTtoRTTM(
        assignUndefined,
        rttm
      );

      const containerSegmentsUndefined =
        compareAssignSpeakerRTTM(segmentosIgnorados);
      const rttmJson = joinSegmentsRTTM(
        containerSegmentsUndefined,
        rttmWithSpeakers
      );

      const segmentsSrtIgnored = verifiedIgnoredSegments(srt, rttmJson);
      const rttmJsonWithEmptySegments = generateEmptySegmentsArray(rttmJson.segmentos.sort((a, b) => a.start - b.start));
      const total_segmentos = rttmJson.segmentos.length;
      const joinAll = joinSegmentsRTTM(rttmJsonWithEmptySegments, rttmJson);
      const segmentos=assignIgnoredSegments(segmentsSrtIgnored, joinAll,srt);
      const segmentosSrtIgnorados=verifiedIgnoredSegments(srt, segmentos);
      
      joinAll.total_segmentos = total_segmentos;
      joinAll.total_segmentos_vacios = rttmJsonWithEmptySegments.length;
      joinAll.segmentsSrtIgnored = segmentosSrtIgnorados;
      joinAll.totalSegmentsIgnored = segmentsSrtIgnored.length;
      // console.log(joinAll);

      handleSegmentation({
        ...joinAll,
        spks_srt: arregloAgrupado,
        spks_rttm: groupBySpeaker(joinAll.segmentos),
      });
      
      handleVideo(watch("video").name);
    });
  };

  const startSegmentation = async () => {
    if ((dirtyFields.video || video) && dirtyFields.captions) {
      handleLoading(true);
      // console.log("startSegmentation");
      const dataDiariazation = video? await checkBDDiarization(video) : await checkBDDiarization(watch("video").name);
      if (dataDiariazation.status) {
        setStep(3);
        filterSRT_RTTM(dataDiariazation);
        setMessageToast("Diarization completed");
        setSeverityToast("success");
        setOpenToast(true);
        handleLoading(false);
        return;
      }
      await uploadVideoVoiceOver(watch("video")).then(async (res) => {
        handleNext();
        const nameVideo = watch("video").name;
        generateDiarization(nameVideo)
          .then((res) => {
            console.info(res);
          })
          .catch((err) => {
            setMessageToast("Error in diarization");
            setSeverityToast("error");
            setOpenToast(true);
            setStep(0);
            handleLoading(false);
          });
        handleNext();
        checkDiarization();
      });
    } else if (dirtyFields.captions) {
      setMessageToast("Please upload a video file");
      setSeverityToast("error");
      setOpenToast(true);
    } else if (dirtyFields.video) {
      setMessageToast("Please upload a captions file");
      setSeverityToast("error");
      setOpenToast(true);
    } else {
      setMessageToast("Please upload a video and captions file");
      setSeverityToast("error");
      setOpenToast(true);
    }
  };
  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={severityToast} variant="filled">
          {messageToast}
        </Alert>
      </Snackbar>
      <Card className="card__uploadFile" variant="outlined">
        <React.Fragment>
          <CardContent className="card-voice-over">
            <div className={styles.header}>
              <Typography variant="h6">Upload Resources</Typography>
            </div>
            <div style={{ padding: "0 1rem" }}>
              <Typography variant="h7">
                <AttachFileIcon fontSize="small" /> Upload Video File
              </Typography>
              <Controller
                name="video"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FileUploader
                    name="video_segmentation_voice_over"
                    multiple={false}
                    required={true}
                    className="file_drag_drop"
                    types={fileTypesVideo}
                    handleChange={(e) => onChange(e)}
                    disabled={loading || video}
                  />
                )}
              />
              { watch("video") && <p className={styles.labelVideo}>Video: {watch("video").name}</p>}
              <Typography variant="h7">
                <AttachFileIcon fontSize="small" /> Upload Captions File
              </Typography>
              <Controller
                name="captions"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FileUploader
                    name="caption_segmentation_voice_over"
                    multiple={false}
                    required={true}
                    className="file_drag_drop"
                    types={fileTypesCaptions}
                    handleChange={(e) => onChange(e)}
                    disabled={loading}
                  />
                )}
              />
              { watch("captions") && <p className={styles.labelVideo}>Captions: {watch("captions").name}</p>  }
            </div>
          </CardContent>
          <CardActions>
            <button
              className={styles.btn_create}
              onClick={handleSubmit(startSegmentation)}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Start Segmentation"}
            </button>
          </CardActions>
        </React.Fragment>
      </Card>
    </>
  );
};

export default CardResources;
