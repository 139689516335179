import { putDescriptionSegment, putScriptSegmentSign } from "../descriptions/description";

export const putActualTime = (time, websurfer, setActualTime, currentProject) => {
  console.log("putActualTime", time);
  setActualTime(time);
  websurfer.current.seekTo(parseFloat(time));
  if (currentProject.rol?.role === "QC2"||currentProject.rol?.role === "CLIENT2") {
    const video = document.querySelector("video");
    const timeInSeconds = time * video.duration;
    video.currentTime = parseFloat(timeInSeconds.toFixed(2));
  }
};

export const resetToStart = (websurfer, setIsPlaying, stopAudioInWave, currentProject) => {
  websurfer.current.seekTo(0);

  websurfer.current.pause();

  if (currentProject.rol?.role === "QC2"||currentProject.rol?.role === "CLIENT2") {
    const video = document.querySelector("video");
    video.currentTime = 0;
    video.pause();
  }

  setIsPlaying(false);
  stopAudioInWave();
};

export const secondsToSubrip = (seconds) => {
  var hour = Math.floor(seconds / 3600);
  var minute = Math.floor((seconds % 3600) / 60);
  var second = Math.floor(seconds % 60);
  var millisecond = Math.floor((seconds % 1) * 1000);

  return [
    hour > 9 ? hour : "0" + hour,
    minute > 9 ? minute : "0" + minute,
    second > 9 ? second : "0" + second,
    millisecond > 99
      ? millisecond
      : millisecond > 9
        ? "0" + millisecond
        : "00" + millisecond,
  ];
};

export const secondsToSubripString = (seconds) => {
  var hour = Math.floor(seconds / 3600);
  var minute = Math.floor((seconds % 3600) / 60);
  var second = Math.floor(seconds % 60);
  var millisecond = Math.floor((seconds % 1) * 1000);

  return (
    (hour > 9 ? hour : "0" + hour) +
    ":" +
    (minute > 9 ? minute : "0" + minute) +
    ":" +
    (second > 9 ? second : "0" + second) +
    "," +
    (millisecond > 99
      ? millisecond
      : millisecond > 9
        ? "0" + millisecond
        : "00" + millisecond)
  );
}

export const subripToSeconds = (subripTime) => {
  const [hours, minutes, seconds] = subripTime.split(":");
  const [secondsPart, millisecondsPart] = seconds.split(",");
  const totalSeconds =
    parseInt(hours, 10) * 3600 +
    parseInt(minutes, 10) * 60 +
    parseInt(secondsPart, 10) +
    parseInt(millisecondsPart, 10) / 1000;
  // //console.log(subripTime, "-", totalSeconds);
  return totalSeconds;
};

export const updateSegmentTime = (
  currentRegion,
  startHH,
  startMM,
  startSS,
  startMS,
  endHH,
  endMM,
  endSS,
  endMS,
  name,
  value,
  setDuration
) => {
  if (name === "startHH") {
    startHH = value;
  } else if (name === "startMM") {
    startMM = value;
  } else if (name === "startSS") {
    startSS = value;
  } else if (name === "startMS") {
    startMS = value;
  } else if (name === "endHH") {
    endHH = value;
  } else if (name === "endMM") {
    endMM = value;
  } else if (name === "endSS") {
    endSS = value;
  } else if (name === "endMS") {
    endMS = value;
  }

  setDuration(subripToSeconds(`${endHH}:${endMM}:${endSS},${endMS}`) - subripToSeconds(`${startHH}:${startMM}:${startSS},${startMS}`));

  const data = {
    ...currentRegion.data,
    start: subripToSeconds(`${startHH}:${startMM}:${startSS},${startMS}`),
    end: subripToSeconds(`${endHH}:${endMM}:${endSS},${endMS}`),
  };

  currentRegion.update({
    start: subripToSeconds(`${startHH}:${startMM}:${startSS},${startMS}`),
    end: subripToSeconds(`${endHH}:${endMM}:${endSS},${endMS}`),
    data,
  });

  currentRegion.updateRender();
  if (window.location.href.includes("/script-adapter/")) {
    putScriptSegmentSign(data, data.id);
  } else {
    putDescriptionSegment(data, data.id);
  }
};
export const seekFrameFrame = (type, websurfer, setTimeSubRip,currentProject) => {
  const fps = 25;
  let currentTime = websurfer.current.getCurrentTime();

  //si es mayor a 0 y menor a la duracion del video
  if (currentTime >= 0 && currentTime < websurfer.current.getDuration()) {
    // Suma o resta 1/25 de segundo a la posición actual
    let offset = type === "advance" ? 1 / fps : -1 / fps;
    let newPosition = currentTime + offset;
    //console.log("newPosition", newPosition);

    let progress = newPosition / websurfer.current.getDuration();

    // Adelanta la reproducción a la nueva posición
    websurfer.current.seekTo(progress);

    // Actualiza el tiempo actual
    websurfer.current.setCurrentTime(newPosition);
    setTimeSubRip(secondsToSubrip(newPosition));

    if(currentProject.rol?.role === "QC2"||currentProject.rol?.role === "CLIENT2"){
      const video = document.querySelector("video");
      video.currentTime = newPosition;
    }
  }
};


export const pauseAudioRecord = async (wavesurfer, setIsPlaying, setIsActiveAudio) => {

  if (await wavesurfer.current.backend != null && wavesurfer.current?.isPlaying()) await wavesurfer?.current?.pause();

  setIsPlaying(false);
  setIsActiveAudio(false);
};

export const continuePlayAudioRecord = async (wavesurfer, websurfer, setIsPlaying) => {
  if (wavesurfer.current.backend != null) await wavesurfer?.current?.play();
  await websurfer?.current?.play();
  setIsPlaying(true);
};
