import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopIcon from "@mui/icons-material/Stop";
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import * as WaveSurferTimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.js";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/AudioRecorder.module.css";
import { fastVolume, slowVolume } from "../../utils/controls/volume";
import { editAnnotation } from '../../utils/descriptions/description';
import { play3secondsPreviusSegment } from "../../utils/time/timeActions";

const AudioRecorder = () => {
  const {
    duration,
    currentRegion,
    volume,
    websurfer,
    setCurrentRegion, setDescriptionTest, setStartHH,
    setStartMM,
    setStartSS,
    setStartMS,
    setEndHH,
    setEndMM,
    setEndSS,
    setEndMS,
    setDuration,
    setDescription,
    user
  } = useContext(DataDescriptor);

  const [openCounter, setOpenCounter] = useState(false);
  const [count, setCount] = useState(3);

  const [isRecording, setIsRecording] = useState(false);
  const wavesurfer = useRef(null);
  const [finallyRecord, setFinallyRecord] = useState(false);
  const [init, setInit] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const { deviceInput, narrationType } = useContext(DataUser);

  const handleClickAriaCountdown = () => {
    speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance(`3, 2, 1, speak, ${narrationType === "Standard" ? "" : currentRegion.data?.description?.initial}`);
    utterance.rate = 1.7;
    utterance.lang = "en-US";
    utterance.addEventListener('end', () => {
      speechSynthesis.cancel();
    });
    window.speechSynthesis.speak(utterance);
    console.log("otro count");
  };

  // todo: ----------------- Counter -----------------
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount((c) => c - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setOpenCounter(false);
    }
  }, [count]);

  useEffect(() => {
    if (currentRegion) {
      if (!isRecording) websurfer.current.un("region-in");
    } else {
      websurfer.current.un("region-in");
      websurfer.current.on("region-in", regionClickWavesurfer);
    }
  }, [currentRegion]);

  const restartCount = () => setCount(3);

  useEffect(() => {
    if (!isRecording) return;
    let timer = setTimeout(() => {
      stopRecording(true);
    }, duration * 1000);
    return () => clearTimeout(timer);
  }, [isRecording]);

  const startRecording = (currentRegion) => {
    restartCount();
    setDescriptionTest(currentRegion.data?.description?.initial);
    setFinallyRecord(false);
    setOpenCounter(true);
    const { duration } = play3secondsPreviusSegment(
      currentRegion,
      websurfer,
      setIsPlaying,
    );

    setTimeout(() => {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: deviceInput.deviceId } })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });
          setMediaRecorder(mediaRecorder);
          mediaRecorder.start();
          setIsRecording(true);
          mediaRecorder.addEventListener("dataavailable", (e) => {
            const blob = new Blob([e.data], { type: "audio/webm" });
            const blobURL = URL.createObjectURL(blob);
            wavesurfer.current.load(blobURL);
          });
          mediaRecorder.addEventListener("stop", () => {
            setIsRecording(false);
            setFinallyRecord(true);
          });
        })
        .catch((err) => {
          console.error("error al grabar", err);
        });
    }, duration);
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    mediaRecorder.stream.getTracks().forEach((track) => {
      track.stop()
    });
    setIsPlaying(false);
  };

  useEffect(() => {
    if (init) return;
    createWave();
    setInit(true);
  }, [init]);

  const createWave = () => {
    if (wavesurfer.current) wavesurfer.current.destroy();
    wavesurfer.current = WaveSurfer.create({
      container: "#waveform2",
      waveColor: "black",
      progressColor: "black",
      cursorColor: "navy",
      height: 100,
      responsive: true,
      backgroundColor: "#D3D3D3",
      plugins: [
        WaveSurferTimelinePlugin.create({
          container: "#waveform-timeline2",
        }),
      ],
    });
  };

  const pausePlayAudioRecorder = () => {
    if (wavesurfer.current?.isPlaying()) {
      
      wavesurfer?.current?.pause();
      setIsPlaying(false);
    } else {
      //cuando acabe de reproducir poner en false
      wavesurfer?.current?.play()
      setIsPlaying(true);
      wavesurfer.current?.un("finish");
      wavesurfer?.current?.on("finish", () => {
        setIsPlaying(false);
      })
    }
  };

  const resetRecording = () => {
    wavesurfer.current.destroy();
    setIsRecording(false);
    stopRecording();
    const startTime = currentRegion.start - 3;
    websurfer.current.seekTo(startTime / websurfer.current.getDuration());
    
    websurfer.current.pause();
    setCurrentRegion(currentRegion);
    setInit(false);
    setFinallyRecord(false);
    setIsRecording(false);
  };

  useEffect(() => {
    console.log("volume", volume);
    if (isRecording) slowVolume(volume, websurfer)
    else fastVolume(volume, websurfer);
  }, [isRecording]);

  const regionClickWavesurfer = (region) => {
    editAnnotation(user,
      region,
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      setDescription
    );
  }

  return (
    <>
      <Dialog
        id='modal-counter'
        open={openCounter}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent
          sx={{ color: 'white', backgroundColor: '#28262E' }}
        >
          <DialogContentText
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              backgroundColor: '#C75E43',
              margin: '0 auto',
            }}
          >
            <span style={{ fontSize: '48px', color: 'white' }}>
              {count}
            </span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className={styles.container}>
        <div className={styles.container_timeline}>
          <div id="waveform2" ></div>
          <div id="waveform-timeline2"></div>
        </div>
        <div className={styles.container__actions}>
          {isRecording ? (
            <button className={styles.btn_stop} onClick={() => resetRecording()}>
              <StopIcon fontSize="large" sx={{ color: "white" }} />
            </button>
          ) : (
            <button className={styles.btn_record} onClick={() => {
              startRecording(currentRegion);
              handleClickAriaCountdown();
            }}>
              <KeyboardVoiceIcon fontSize="large" />
            </button>
          )}
          {finallyRecord && (
            <>
              {isPlaying ? (
                <>
                  <button
                    className={styles.btn_play}
                    onClick={pausePlayAudioRecorder}
                  >
                    <PauseCircleIcon fontSize="large" />
                  </button>
                </>
              ) : (
                <button
                  className={styles.btn_play}
                  onClick={pausePlayAudioRecorder}
                >
                  <PlayCircleFilledWhiteIcon fontSize="large" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AudioRecorder;