import React, { useContext, useEffect } from "react";
import { DataPM } from "../context/DataPM";
import { DataUser } from "../context/DataUser";
import { FormsCreateProject } from "./FormsCreateProject";
import { ProjectsList } from "./ProjectsList";

export const ProjectsActions = ({projects,projectsOriginal,loading}) => {
  const { isCreate, searchCount } = useContext(DataPM);

  const { user } = useContext(DataUser);

  useEffect(() => {
    if (projectsOriginal.length > 0) {      
      searchCount(projectsOriginal);
    }
  }, [projectsOriginal]);

  return (
    <>
      {isCreate ? (
        <FormsCreateProject />
      ) : (
        <ProjectsList user={user} projects={projects} loading={loading} />
      )}
    </>
  );
};
