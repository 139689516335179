import DownloadIcon from "@mui/icons-material/Download";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { Alert, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useContext, useState } from "react";
import { DataVoiceOver } from "../../context/DataVoiceOver";
import styles from "../../css/SegmentationVoiceOver.module.css";

const CardJoinSRT = () => {
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [severityToast, setSeverityToast] = useState("success");

  const activateEdit = (index) => {
    handleEditState(index);
    handleNameSpeaker(segmentos[index].speaker);
  };

  const deactivateEdit = () => {
    handleEditState(undefined);
    handleNameSpeaker("");
  };
  const {
    segmentation,
    playSegment,
    segmentPlaying,
    pauseSegment,
    handleCheckboxChange,
    selectedSpeakers,
    segmentos,
    handleSegmentation,
    deselectAllSpeakers,
    downloadSRT,
    editNameSpeaker,
    nameSpeaker,
    handleNameSpeaker,
    handleEditState,
    editingStates 
  } = useContext(DataVoiceOver);

  const theme = useTheme();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const getJoinedCount = () => {
    const joinedKeys = Object.keys(segmentation.spks_rttm).filter((key) =>
      key.startsWith("joined-")
    );
    joinedKeys.sort(
      (a, b) => parseInt(a.split("-")[1]) - parseInt(b.split("-")[1])
    );

    const last_version = joinedKeys[joinedKeys.length - 1]?.split("-")[1] || 0;
    return parseInt(last_version) + 1;
  };

  const handleJoinSpeakers = () => {
    if (selectedSpeakers.length > 1) {
      const segments = selectedSpeakers
        .map((index) => segmentos[index].segments)
        .flat(); //flat() aplana el array
      const joinedSpeakersKey = `joined-${getJoinedCount()}`;
      const newSegmentation = {
        ...segmentation,
        spks_rttm: { ...segmentation.spks_rttm, [joinedSpeakersKey]: segments },
      };

      selectedSpeakers.forEach((index) => {
        delete newSegmentation.spks_rttm[segmentos[index].speaker];
      });

      newSegmentation.spks_rttm[joinedSpeakersKey].sort(
        (a, b) => a.startTime - b.startTime
      );

      handleSegmentation(newSegmentation);
      deselectAllSpeakers();

      setOpenToast(true);
      setMessageToast("Speakers joined successfully");
      setSeverityToast("success");
    } else {
      setOpenToast(true);
      setMessageToast("Select at least two speakers");
      setSeverityToast("error");
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={severityToast} variant="filled">
          {messageToast}
        </Alert>
      </Snackbar>
      <Card className="card__joinSRT" variant="outlined">
        <React.Fragment>
          <CardContent className="card-voice-over" style={{ height: "50%" }}>
            <div
              className={styles.header_cardSRT}
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <Typography
                variant="h4"
                fontSize="1rem"
                style={{ margin: ".5rem" }}
              >
                Speakers
              </Typography>
              {segmentos.map((speaker, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pl: 1,
                    pb: 1,
                    margin: ".1rem 0rem",
                  }}
                  key={index}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!segmentPlaying[index] ? (
                      <IconButton
                        aria-label="play/pause"
                        style={{ color: "#000", background: "#91D2D5" }}
                        onClick={() => playSegment(speaker, index)}
                      >
                        <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="play/pause"
                        style={{ color: "#000", background: "#91D2D5" }}
                        onClick={() => pauseSegment(index)}
                      >
                        <PauseIcon sx={{ height: 38, width: 38 }} />
                      </IconButton>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        margin: "1rem",
                      }}
                    >
                     {!editingStates[index] ? (
            <h5 onClick={()=>activateEdit(index)}>{speaker.speaker}</h5>
          ) : (
            <input
              type="text"
              onBlur={(e) => {
                editNameSpeaker(speaker.speaker, e.target.value);
                deactivateEdit();
              }}
              value={nameSpeaker}
              onChange={(e) => handleNameSpeaker(e.target.value)}
            />
          )}
                      <span style={{ fontSize: ".8rem" }}>
                        {speaker.segments.length} Segments
                      </span>
                    </div>
                  </div>
                  <div>
                    <IconButton
                      aria-label="previous"
                      onClick={() => playSegment(speaker, index, "prev")}
                    >
                      {theme.direction === "rtl" ? (
                        <SkipNextIcon />
                      ) : (
                        <SkipPreviousIcon />
                      )}
                    </IconButton>
                    <IconButton
                      aria-label="next"
                      onClick={() => playSegment(speaker, index, "next")}
                    >
                      {theme.direction === "rtl" ? (
                        <SkipPreviousIcon />
                      ) : (
                        <SkipNextIcon />
                      )}
                    </IconButton>
                    <Checkbox
                      {...label}
                      checked={
                        selectedSpeakers
                          ? selectedSpeakers.includes(index)
                          : false
                      }
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <IconButton
                      aria-label="download"
                      onClick={() => downloadSRT(speaker, index, "download")}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                </Box>
              ))}
            </div>
          </CardContent>
          <CardActions>
            <button className={styles.btn_create} onClick={handleJoinSpeakers}>
              Join Speakers
            </button>
          </CardActions>
        </React.Fragment>
      </Card>
    </ThemeProvider>
  );
};

export default CardJoinSRT;
