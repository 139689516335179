import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VideoControls } from "../components/common/VideoControls";
import { ClientActions } from "../containers/ClientActions";
import Forbidden403 from '../containers/Forbidden403';
import TimelineContainerQC from "../containers/TimelineContainerQC";
import { DataProvider } from "../context/DataDescriptor";
import { DataNarratorProvider } from "../context/DataNarrator";
import { DataQCProvider } from "../context/DataQC";
import { DataUser } from "../context/DataUser";
import styles from "../css/Client.module.css";
import { getProject, updateStage } from '../utils/project/CRUD';

import { IconButton } from "@mui/material";
import ModalUserGuide from '../components/toolbar/UserGuide/ModalUserGuide';
export const Client = () => {
  const [openUserGuide, setOpenUserGuide] = useState(true);
  const { currentProject, setCurrentProject } = useContext(DataUser);
  const { video, _id: idProject, stage } = currentProject;
  let { id } = useParams();
  const handleUserGuide = () => setOpenUserGuide(true);
  useEffect(() => {
    if (!currentProject?._id) {
      getProjectData()
    } else if (currentProject?._id) {
      updateStage(stage, idProject);
    }
  }, [currentProject]);

  const getProjectData = async () => {
    const project = await getProject(id);
    setCurrentProject(project.project);
  }

  return (
    <>
      {(currentProject?.rol?.role === "CLIENT" || currentProject?.rol?.role === "CLIENT2") ? (
        <DataProvider>
          <DataNarratorProvider>
            <DataQCProvider>
              <header style={{ backgroundColor: "#28262E", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h1 style={{ color: "white", textAlign: "center", position: "relative", left: "50%", transform: "translateX(-50%)" }}>
              {currentProject?.name}</h1>
              <IconButton onClick={() => handleUserGuide()} >
                      <HelpCenterIcon fontSize="large" sx={{ color: "white" }} />
                  </IconButton>
              </header>
              <div className={styles.container__client}>
                <div className={styles.container__clientVideo}>
                  <VideoControls video={video} idProject={idProject} />
                  <ClientActions />
                </div>
                <div className={styles.container__timeLine}>
                  <TimelineContainerQC />
                </div>
              </div>
            </DataQCProvider>
          </DataNarratorProvider>
        </DataProvider>) : (
        <Forbidden403 />
      )}
      <ModalUserGuide
            open={openUserGuide}
            setOpen={setOpenUserGuide}
          />
    </>
  )
}
