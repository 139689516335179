import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import Mousetrap from "mousetrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/CommetsQC.module.css";

const CommentsQC = () => {

  const { currentRegion } = useContext(DataDescriptor);
  const [isOpenComments, setIsOpenComments] = useState(false);
  useEffect(() => {
    setIsOpenComments(true)
    //console.log('currentRegion', currentRegion);
  }, [currentRegion])

  // todo: ------------------------ Keyboard Shortcut: QC Comments------------------------
  const qcBtnRef = useRef(null);

  const qcShortcut = async (e) => {
    // if (e.key.toLowerCase() === "q" && e.altKey) {
    if (qcBtnRef.current) {
      qcBtnRef.current?.focus();
    } else {
      speechSynthesis.cancel();
      const utterance = new SpeechSynthesisUtterance('No Quality Control comments in this segment');
      utterance.rate = 0.9;
      utterance.lang = "en-US";
      speechSynthesis.speak(utterance);
      utterance.addEventListener('end', () => {
        speechSynthesis.cancel();
      });
    }
    // }
  }


  useEffect(() => {
    Mousetrap.bind(['alt+q', 'option+q'], (e) => {
      qcShortcut(e);
    })
    return () => {
      Mousetrap.unbind(['alt+q', 'option+q'])
    };
  }, []);

  return (
    <>
      {(!currentRegion?.data?.review?.approved && currentRegion?.data?.review?.comment[0] && currentRegion) && (
        <>
          {isOpenComments ? (
            <div className={styles.comments_qc}>
              <div tabIndex={-1} className={styles.header_comments}>
                <h3>Quality Control</h3>
                <button tabIndex={-1} aria-label="Close Quality control review comments panel" onClick={() => setIsOpenComments(false)}>X</button>
              </div>
              <textarea
                ref={qcBtnRef}
                className={styles.textarea}
                // placeholder="Write your comment here..."
                tabIndex={0}
                aria-label={'This segment have Quality control comments: ' + currentRegion?.data?.review?.comment[0] + ', Shortcut to focus: Alt + Q,'}
                value={currentRegion?.data?.review?.comment[0] ? currentRegion?.data?.review?.comment[0] : ''}
                readOnly
              ></textarea>
            </div>
          ) : (
            <div className={styles.container_comments}>
              <div
                tabIndex={-1}
                aria-label="Open Quality control review comments panel"
                className={styles.comments_btn} onClick={() => setIsOpenComments(true)}>
                <ChatBubbleOutlineOutlinedIcon />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CommentsQC;
