import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VideoControls } from "../components/common/VideoControls";
import CommentsQC from "../components/qualityControl/CommentsQC";
import { DescriptorActions } from "../containers/DescriptorActions";
import Forbidden403 from "../containers/Forbidden403";
import TimelineContainer from "../containers/TimelineContainer";
import { DataProvider } from "../context/DataDescriptor";
import { DataNarratorProvider } from "../context/DataNarrator";
import { DataUser } from "../context/DataUser";
import styles from "../css/Descriptor.module.css";
import { getProject, updateStage } from "../utils/project/CRUD";

export const Descriptor = () => {
  const { currentProject,setCurrentProject,user } = useContext(DataUser);
  const { video, _id: idProject, stage } = currentProject;  
  const [loading, setLoading] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    if (!currentProject?._id){
      getProjectData()
    }else if (currentProject?._id) {
      updateStage(stage, idProject);
    }
  }, [currentProject]);

  const getProjectData = async () => {
    setLoading(true);
    const project = await getProject(id);
      setCurrentProject(project.project);
      setLoading(false);
  }

  return (
    <>
    {(currentProject?.rol?.role === "AD" && user?.uid)? (
      <DataProvider>
      <DataNarratorProvider>
        <div className={styles.container__descriptor}>
          <div className={styles.container__descriptorVideo}>
            <VideoControls video={video} idProject={idProject} />
            <DescriptorActions />
            <CommentsQC />
          </div>
          <div className={styles.container__timeLine}>
            <TimelineContainer />
          </div>
        </div>
        </DataNarratorProvider>
      </DataProvider>): (
        <>
        {loading? (
          <div className={styles.container__loading}>
            <span>Loading...</span>
          </div>
        ) : (
          <Forbidden403 />
        )}
        </>
        
      )}
    </>
  );
};
