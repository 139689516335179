export const slowVolume = (volume,websurfer) => {
  
    
    /*if (volume > 0) {
      // setVolume(10);
      console.log("volume",volume);
      websurfer.current.setVolume(0);
      console.log("volume2",volume);
    }*/
    websurfer.current.setVolume(0);
   // console.log("volumeDown",websurfer.current.volume);
  };
  export const fastVolume = (volume,websurfer) => {
    /*if (volume <= 100) {
      //setiar el ultimo volumen
      // setVolume(lastVolume);
      websurfer.current.setVolume(volume / 100);
    }*/
    websurfer.current.setVolume(1);

  };