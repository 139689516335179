import { createContext, useContext, useRef, useState } from "react";
import { DataDescriptor } from "./DataDescriptor";

export const DataNarrator = createContext();

export const DataNarratorProvider = ({ children }) => {
  const { currentRegion } = useContext(DataDescriptor);
  const [selectedButton, setSelectedButton] = useState("descriptionBtn");
  const [isDescriptionButtonActive, setIsDescriptionButtonActive] =
    useState(true);
  const [isCommentButtonActive, setIsCommentButtonActive] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [maxPeakAudio, setMaxPeakAudio] = useState(0);
  const [currentDescription, setCurrentDescription] = useState(null);
  const [regionIn, setRegionIn] = useState(false);
  const wavesurfer = useRef(null);



  // todo: ----------Change view between Description and Comments----------------
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    setIsDescriptionButtonActive(buttonId === "descriptionBtn");
    setIsCommentButtonActive(buttonId === "commentsBtn");
    if (buttonId !== "descriptionBtn") {
      setIsEditingDescription(false);
    }
  };
  // todo: -----------------------Description and Reason4Change Editing-----------------------
  // Put description in DB
  const handleEditingDescription = () => {
    setIsEditingDescription((prev) => !prev);
  };

  const handleDescriptionEditor = async (e) => {
    const value = e.target.value;
    const data = {
      "description": {
        "initial": value,
        "preview": currentRegion.data.description.preview,
        "reason4change": currentRegion?.data?.description?.reason4change,
      }
    }
    
    currentRegion.update({
      data: {
        ...currentRegion.data,
        description: {
          ...currentRegion.data.description,
          initial: value,
        }
      }
    });

    const result = await putDescriptionSegment(data, currentRegion.data.id);
    //console.log(result);
    setCurrentDescription(data.description);
  };

  const handleReasonEditor = async (e) => {
    const value = e.target.value;
    const data = {
      "description": {
        "initial": currentRegion.data?.description?.initial,
        "preview": currentRegion.data.description.preview,
        "reason4change": value,
      }
    }

    currentRegion.update({
      data: {
        ...currentRegion.data,
        description: {
          ...currentRegion.data.description,
          reason4change: value,
        }
      }
    });


    const result = await putDescriptionSegment(data, currentRegion.data.id);
    //console.log(result);
  };


  // ! Query to DB to update description and reason4change
  const putDescriptionSegment = async (data, id) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();

    return result;
  };

  return (
    <DataNarrator.Provider
      value={{
        selectedButton,
        setSelectedButton,
        isDescriptionButtonActive,
        setIsDescriptionButtonActive,
        isCommentButtonActive,
        setIsCommentButtonActive,
        isEditingDescription,
        setIsEditingDescription,
        currentDescription,
        setCurrentDescription,
        handleEditingDescription,
        handleButtonClick,
        handleDescriptionEditor,
        handleReasonEditor,
        setMaxPeakAudio,
        maxPeakAudio,
        wavesurfer,
        regionIn, setRegionIn
      }}
    >
      {children}
    </DataNarrator.Provider>
  );
};