import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import * as React from "react";
import { useEffect, useState } from "react";
import styles from "../../css/Header.module.css";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { getProject } from "../../utils/project/CRUD";


function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          height: "300px",
          width: "350px",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={Math.round(
            (props.completedFragments / props.totalFragments) * 100
          )}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="#fff"
            fontSize="4.5rem"
            fontWeight="bold"
          >
            {`${(Math.round(
              (props.completedFragments / props.totalFragments) * 100
            ) || 0)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    totalFragments: PropTypes.number,
    completedFragments: PropTypes.number,
  };
const TrackingProject = ({project}) => {
    const [completedFragments, setCompletedFragments] = useState(0);
    const [missingFragments, setMissingFragments] = useState(0);
    const [totalFragments, setTotalFragments] = useState(0);
  
    useEffect(() => {
      if (project?.name) getProjectInfo();
    }, [project]);
  
   
    const getProjectInfo = async () => {
        const { project: projectInfo } = await getProject(project?._id);
        const { segments, segmentsSign,rol } = projectInfo;
    
        let completed = 0;
    
        if (rol.role===('SCRIPT_ADAPTER')) {
          //los que tengan el atributo script y este tenga algun texto estan completos
          completed = segmentsSign.filter((segment) => segment.script && segment.script!=='').length;
          setCompletedFragments(completed ? completed : 0);
          setMissingFragments(segmentsSign.length - (completed ? completed : 0));
          setTotalFragments(segmentsSign.length);
        } else {
          if (rol.role===('AD')) {
            completed = segments.filter((segment) => {
              const { initial, preview } = segment.description;
              return initial !== preview || segment.review.approved === true
            }
            ).length;
          } else if (rol.role===('AN')) completed = segments.filter((segment) => {
            const { initial, preview } = segment.audiorecord;
            return initial !== preview || segment.review.approved === true
          }).length;
          else if (rol.role.includes('QC')) {
            const missing = segments.filter((segment) => ((!segment.review.comment[0] && !segment.review.approved) ||
              (!segment.review.approved && (segment.review.comment[0] === segment.review.comment[1])))
              && !segment.isDisabled);
    
            completed = segments.length - missing.length;
    
          }
          setCompletedFragments(completed ? completed : 0);
          setMissingFragments(segments.length - (completed ? completed : 0));
          setTotalFragments(segments.length);
        }
      }
  return (
    <div className={styles.content_progress}>
    <CircularProgressWithLabel
      completedFragments={completedFragments}
      totalFragments={totalFragments}
    />
    <div className={styles.content_text}>
      <Typography
        tabIndex={0}
        variant="h6"
        component="div"
        color="#fff"
        fontSize="1.5rem"
        fontWeight="bold"
        display='flex'
        alignItems='center'
      >
        <ArticleOutlinedIcon fontSize="large" /> Completed:
      </Typography>
      <span
        tabIndex={0}
        className={styles.content_textSpan + " " + styles.text_green}>{`${completedFragments} segments`}</span>
      <Typography
        tabIndex={0}
        variant="h6"
        component="div"
        color="#fff"
        fontSize="1.5rem"
        fontWeight="bold"
        display='flex'
        alignItems='center'
      ><ArticleOutlinedIcon fontSize="large" /> Missing:
      </Typography>
      <span
        tabIndex={0}
        className={styles.content_textSpan + " " + styles.text_orange}>{` ${missingFragments} segments`}</span>
      <Typography
        tabIndex={0}
        variant="h6"
        component="div"
        color="#fff"
        fontSize="1.5rem"
        fontWeight="bold"
        display='flex'
        alignItems='center'
      ><ArticleOutlinedIcon fontSize="large" /> Total:</Typography>
      <span
        tabIndex={0}
        className={styles.content_textSpan + " " + styles.text_blue}
      >
        {` ${totalFragments} segments`}
      </span>
    </div>
  </div>
  )
}

export default TrackingProject