
export const compressVideo = async(id) => {
    await fetch(`${process.env.REACT_APP_URL}/api/projects/compress-video/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    }).then((res) => res.json())
    .then((data) => {
        return data;
    })
    .catch((err) => console.error(err));
}