import SendIcon from "@mui/icons-material/Send";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataQC } from "../context/DataQC";
import { DataUser } from "../context/DataUser";
import styles from "../css/Send.module.css";
import { generateMixAudio } from "../utils/audio/generateMix";
import { updateStageCompleted } from "../utils/project/CRUD";
import { updateManyRegions, updateManyRegionsVoiceOver } from "../utils/regions/updateManyRegions";
import { ModalCheckSend } from "./ModalCheckSend";

export const SendQC = () => {
  const { currentProject, updateRole } = useContext(DataUser);
  const { resultCheck } = useContext(DataQC);
  const { regions } = useContext(DataDescriptor);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [rol, setRol] = useState("")
  const [clientRevision, setClientRevision] = useState({});

  const [openModalCheck, setOpenModalCheck] = useState(false);

  const handleClickOpenModalCheck = () => setOpenModalCheck(true);
  useEffect(() => {
      setClientRevision({
        AD: currentProject?.client[0],
        AN: currentProject?.client[1],
      });
      
  }, [currentProject]);
  const sendProject = async () => {
    const updateQCInDB = async (approved) => {
      const segmentsAD = [];
      const segmentsVO = [];
      regions.forEach(async (region) => {
          const segment={
              review: {
                comment: [approved ? "" : region.data.review.comment[0], approved ? "" : region.data.review.comment[0]],
                approved: approved ? false : region.data.review.approved,
              },
              description: {
                initial: region.data?.description?.initial,
                preview: region.data?.description?.initial,
                reason4change: region?.data?.description?.reason4change,
              },
              audiorecord: {
                initial: region.data?.audiorecord?.initial,
                preview: region.data?.audiorecord?.initial,
              },
            _id: region.data.id
          };
          if(region.data.user) segmentsVO.push(segment);
          else segmentsAD.push(segment);
      });
      if(currentProject.service==="dubbing" || segmentsVO.length>0){
        await updateManyRegionsVoiceOver(currentProject._id, segmentsVO); 
      }
      if(segmentsAD.length>0){
      await updateManyRegions(currentProject._id, segmentsAD);
      }
    }
    const updateVersionInDB = async () => {
      // Update the version in the DB
      const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stage: {
            version: 0,
            state: 'New'
          }
        }),
      });
      await response.json().catch((error) => console.error(error));
    }
    setDisabled(true);
    if (!currentProject.rol?.role.includes("QC") && !currentProject.rol.role.includes("CLIENT")) {

      if (window.location.href.includes("audio-descriptor")) {
        setRol("Quality Control")
        await updateRole("QC");
      }
      else if (window.location.href.includes("narrator")) {
        await updateRole("QC2");
        setRol("Quality Control")
      }

    }else if(currentProject.service==="dubbing"){
      const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${currentProject._id}`, { method: "GET" });
      const result = await response.json();
      const isThereAnyFalse = result.some(segment => segment.review.approved === false);
      if (isThereAnyFalse) {
        if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(false);
          setRol("Audio Narrator")
          await updateRole("AN_D");
        }
      }else{
        if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(true);
          setRol("Audio Editor to generate mix audio")
          await updateRole("AE");
          generateMixAudio(currentProject._id);
        }

      }
    } else {
      // todo: --------- CLIENT Cases ----------------
      
      const response_client = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
      const result_client = await response_client.json();

      let isThereAnyClientComments = false;

      for (const commentObj of result_client) {
        const commentClient = commentObj.commentClient;

        if (commentClient !== null && commentClient !== '' && commentClient !== undefined) {
          isThereAnyClientComments = true;
          break;
        }
      }

      // console.log("isThereAnyClientComments: ", isThereAnyClientComments)
      // console.log("result_client: ", result_client)

      // ? Client funcs
      const updateClientInDB = async () => {
        const segmentsAD = [];
        const segmentsVO = [];
        regions.forEach(async (region) => {
          
          const segment={
            review: {
              comment: ["", ""],
              approved: false,
            },
            description: {
              initial: region.data?.description?.initial,
              preview: region.data?.description?.initial,
              reason4change: region.data?.description?.reason4change,
            },
            audiorecord: {
              initial: region.data?.audiorecord?.initial,
              preview: region.data?.audiorecord?.initial,
            },
            commentClient: "",
            _id: region.data.id
        }
        if(region.data?.user) segmentsVO.push(segment);
        else segmentsAD.push(segment);
      })
      if(currentProject.service==="dubbing" || segmentsVO.length>0){
        await updateManyRegionsVoiceOver(currentProject._id, segmentsVO); 
      }
      if(segmentsAD.length>0){
      await updateManyRegions(currentProject._id, segmentsAD);
      }
      }

      const disapproveSegmentsWithNoComments = async (result_client) => {
        // Update the QC comments array in the DB
        result_client.forEach(async (region) => {
          try {
              await putReviewSegment(
                {
                  review: {
                    comment: [region.review.comment[0], region.review.comment[1]],
                    approved: false,
                  }
                },
                region._id
              );
            // console.log("Region: ", region)
          } catch (error) {
            console.error(error)
          }
        });
      }

      const approveClientSegmentsWithNoComments = async (result_client) => {
        // Update the QC comments array in the DB
        result_client.forEach(async (region) => {
          // console.log("region: ", region)
          try {
            // todo: Approve all segments with no comments
            if (region.commentClient === null || region.commentClient === '') {
              await putReviewSegment(
                {
                  review: {
                    comment: ["", ""],
                    approved: true,
                  }
                },
                region._id
              );
            } else {
              await putReviewSegment(
                {
                  review: {
                    comment: [region.review.comment[0], region.review.comment[1]],
                    approved: false,
                  }
                },
                region._id
              );
            }
            // console.log("Region: ", region)
          } catch (error) {
            console.error(error)
          }
        });
      }



      if (currentProject.rol.role === "CLIENT") {
        console.log("CLIENT")
        // setRol("Quality Control")
        // await updateFirstTimeClientInDB();
        // await updateRole("QC");
        if (isThereAnyClientComments) {
          console.log("isThereAnyClientComments")
          setRol("Quality Control")
          await updateRole("QC");
          await approveClientSegmentsWithNoComments(result_client);
        } else {
          console.log("No isThereAnyClientComments")
          setRol("Audio Narrator")
          await updateRole("AN");
          await disapproveSegmentsWithNoComments(result_client);
        }
      }

      if (currentProject.rol.role === "CLIENT2") {
        // setRol("Quality Control")
        // await updateFirstTimeClientInDB();
        // await updateRole("QC2");
        if (isThereAnyClientComments) {
          setRol("Quality Control")
          await updateRole("QC2");
          await approveClientSegmentsWithNoComments(result_client);
        } else {
          setRol("Audio Editor")
          await updateRole("AE");
          await approveClientSegmentsWithNoComments(result_client);
          await updateStageCompleted(currentProject._id);
        }
      }


      // todo: --------- QC Cases ----------------
    

      const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
      const result = await response.json();
      const isThereAnyFalse = result.some(segment => segment.review.approved === false);

      if (isThereAnyFalse) {
        if (currentProject.rol?.role === "QC") {
          await updateQCInDB(false);
          setRol("Audio Descriptor")
          await updateRole("AD");

        } else if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(false);
          setRol("Audio Narrator")
          await updateRole("AN");
        }
      } else {
        if (currentProject.rol?.role === "QC") {
          await updateQCInDB(true);
          await updateVersionInDB();
          if (clientRevision.AD && !isThereAnyClientComments) {
            setRol("Client")
            await updateClientInDB();
            await updateRole("CLIENT");
          } else {
            setRol("Audio Narrator")
            await updateClientInDB();
            await updateRole("AN");

          }
        } else if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(true);
          if (clientRevision.AN && !isThereAnyClientComments) {
            setRol("Client")
            await updateClientInDB();
            await updateRole("CLIENT2");
          } else {
            setRol("Audio Editor")
            await updateRole("AE");
            await updateClientInDB();            
            await updateStageCompleted(currentProject._id);
            // generateMixAudio(currentProject._id);
          }
        }
      }

    }
    setOpenToast(true);
    setTimeout(() => {
      setDisabled(false);
      setOpenToast(false);
      navigate("/dashboard");
    }, 3000);
  };

  // Call to DB to save QC comments
  const putReviewSegment = async (data, id) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const dataResponse = await response.json();
    return dataResponse;
  };


  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"success"} variant="filled">
          for review {rol}
        </Alert>
      </Snackbar>
      {disabled ?
        <button
          className={styles.button__send__disabled}
          disabled={disabled}
        >
          <CircularProgress aria-label='Loading' />
        </button>
        : (
          <button
            className={!resultCheck || currentProject.rol.role === "CLIENT" || currentProject.rol.role === "CLIENT2" ? styles.button__send : styles.button__send__disabled}
            onClick={() => handleClickOpenModalCheck()}
            disabled={resultCheck}
          >
            <SendIcon sx={{ fontSize: 40 }} />
            <span>SEND</span>
          </button>
        )}
      <ModalCheckSend
        open={openModalCheck}
        setOpen={setOpenModalCheck}
        sendProject={sendProject}
        disabled={disabled}
      />

    </>
  );
};

