import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DownloadIcon from '@mui/icons-material/Download';
import MovieIcon from '@mui/icons-material/Movie';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useState } from 'react';
import { generateAudioFinal } from '../../../utils/audio/generateAudiofinal';
//import { generateAudioVO } from '../../../utils/audio/generateAudioVO';
import { requestSegmentstoSrt } from '../../../utils/export-srt';
const DownloadResources = ({project}) => {

const [loading, setLoading] = useState(false)
const [type, setType] = useState('')

const downloadLink = async (url,type) => {
  setType(type)
  setLoading(true);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    if (!response.ok) {
      throw new Error(`No se pudo descargar el archivo. Código de estado HTTP: ${response.status}`);
    }

    const blob = await response.blob();
    const fileName = url.split('/').pop(); // Obtener el nombre del archivo de la URL

    // Create blob link to download
    const blobUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);

    // Append to HTML link element to trigger the download
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link after a short delay
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
      setLoading(false);
    }, 100);
  } catch (error) {
    console.error(error);
    window.open(url, '_blank');
    setLoading(false);
  }
};
  return (
    <List style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '1.5rem' }}>
      <Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
        <AttachFileIcon /> Download Original Track 
      </Typography>
      <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <MovieIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project?.name} secondary={project?.video.split('/').pop()} />
        <IconButton aria-label="download" onClick={() => downloadLink(project?.video,'Video') }>
          {(loading && type=='Video') ? <CircularProgress/> : <DownloadIcon />}
        </IconButton>
      </ListItem>
      <Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
        <AttachFileIcon />Download SRT AD
      </Typography>
      <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <ArticleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project?.name} secondary="SRT" />
        <IconButton aria-label="download" onClick={() => requestSegmentstoSrt(project)}>
          <DownloadIcon />
        </IconButton>
      </ListItem>
      <Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
        <AttachFileIcon /> Download MIX Records
      </Typography>
      <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <AudioFileIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project?.audiomix?project?.name:'Audio Records'} secondary={project?.audiomix?.split('/').pop() || 'No audio records'} />
        <IconButton aria-label="download" onClick={() => downloadLink(project?.audiomix,'Audio Records') } disabled={!project?.audiomix}>
        {(loading && type=='Audio Records') ? <CircularProgress/> : <DownloadIcon />}
        </IconButton>
      </ListItem>
      <Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
        <AttachFileIcon /> Download Final MIX
      </Typography>
      <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <AudioFileIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project?.audiofinal? project?.name:'Audio Final'} secondary={project?.audiofinal?.split('/').pop() || 'No final mix'} />
        <IconButton aria-label="generate audiofinal" onClick={()=>generateAudioFinal(project?._id)}  > <RestartAltIcon /> </IconButton>
        <IconButton aria-label="download" onClick={() => downloadLink(project?.audiofinal,'Audio Final') } disabled={!project?.audiofinal}>
          {(loading && type=='Audio Final') ? <CircularProgress/> : <DownloadIcon />}
        </IconButton>
      </ListItem>
      {/*<Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
        <AttachFileIcon /> Download Audio VO
      </Typography>
       <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <AudioFileIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={project?.audioVO? project?.name:'Audio VO'} secondary={project?.audioVO?.split('/').pop() || 'No final VO'} />
        <IconButton aria-label="generate audioVO" onClick={()=>generateAudioVO(project?._id)}  > <RestartAltIcon /> </IconButton>
        <IconButton aria-label="download" onClick={() => downloadLink(project?.audioVO,'Audio VO') } disabled={!project?.audioVO}>
          {(loading && type=='Audio VO') ? <CircularProgress/> : <DownloadIcon />}
        </IconButton>
      </ListItem> */}
    </List>
  )
}

export default DownloadResources