import * as React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import JoinSRT from '../containers/JoinSRT';
import ResourcesVoiceOver from "../containers/ResourcesVoiceOver";
import { DataVoiceOver } from '../context/DataVoiceOver';
const SegmentationVoiceOver = () => {
    const { segmentation, handleSegmentation } = useContext(DataVoiceOver);
    // abstraer param router
    const {video} = useParams()
    console.log(video);

    return (
        <>
            {segmentation ? (
                <JoinSRT />
            ) : (
                <ResourcesVoiceOver handleSegmentation={handleSegmentation} video={video} />
            )}
        </>

    )
}

export default SegmentationVoiceOver 