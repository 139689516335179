import { createContext, useContext, useEffect, useState } from "react";
import { DataDescriptor } from "./DataDescriptor";
import { DataUser } from "./DataUser";

export const DataQC = createContext();

export const DataQCProvider = ({ children }) => {
    const { currentRegion, regions,websurfer } = useContext(DataDescriptor);
    const { currentProject } = useContext(DataUser);

    const [prevRegion, setPrevRegion] = useState();

    const [selectedButton, setSelectedButton] = useState("descriptionBtn");
    const [isDescriptionButtonActive, setIsDescriptionButtonActive] = useState(true);

    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [currentDescription, setCurrentDescription] = useState([]);//?d
    const [isPreviewButtonActive, setIsPreviewButtonActive] = useState(false);
    const [isReasonButtonActive, setIsReasonButtonActive] = useState(false);
    const [isCommentButtonActive, setIsCommentButtonActive] = useState(false);
    const [isClientButtonActive, setIsClientButtonActive] = useState(false);

    const [allApproved, setAllApproved] = useState(false);
    const [toggleSaveAllApproved, setToggleSaveAllApproved] = useState(false);
    const [singleApproved, setSingleApproved] = useState(false);
    // To know when checkbox info is saved in db
    const [toggleSaveSingleApproved, setToggleSaveSingleApproved] = useState(false);
    const [textarea, setTextarea] = useState("");
    const [toggleSaveTextArea, setToggleSaveTextArea] = useState(false);
    const [resultCheck, setResultCheck] = useState(false);


    // Toast management
    const [message, setMessage] = useState("");
    const [statusSave, setStatusSave] = useState(true);
    const [openToast, setOpenToast] = useState(false);


    //todo: ----------Change view between Description and Comments----------------
    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
        setIsDescriptionButtonActive(buttonId === "descriptionBtn");
        setIsPreviewButtonActive(buttonId === "previewBtn");
        setIsReasonButtonActive(buttonId === "reasonBtn");
        setIsCommentButtonActive(buttonId === "commentsBtn");
        setIsClientButtonActive(buttonId === "clientBtn");
        if (buttonId !== "descriptionBtn") {
            setIsEditingDescription(false);
        }
    };

    // todo: -----------------------Description Editing-----------------------
    // Put description in DB
    const handleEditingDescription = () => {
        setIsEditingDescription((prev) => !prev);
    }

    const handleDescriptionEditor = async (e) => {
        const value = e.target.value;
        const data = {
            "description": {
                "initial": value,
                "preview": currentRegion.data.description.preview,
                "reason4change": currentRegion?.data?.description?.reason4change,
            }
        }

        currentRegion.update({
            data: {
                ...currentRegion.data,
                description: {
                    ...currentRegion.data.description,
                    initial: value,
                }
            }
        });

        // //console.log("CurrentRegion: ", currentRegion.data)
        const result = await putDescriptionSegment(data, currentRegion.data.id);
        setCurrentDescription(data.description);
    };

    // Query to DB
    const putDescriptionSegment = async (data, id) => {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();

        return result;
    }

    // todo: -----------------------Checkboxes and AutoSave Management-----------------------
    // "is single approved" checkbox management

    const handleChangeCHBX = (event) => {
        setSingleApproved(event.target.checked);
    }

    // First we if the checkbox changed by the user or just swapped btwn regions
    useEffect(() => {

        (async () => {
            if (singleApproved && (currentRegion === prevRegion)) {
                putSingleApproved();
            } else {
                setPrevRegion(currentRegion);
                if (!singleApproved && (currentRegion === prevRegion)) {
                    handleTextAreaComments();
                }
            }
        })();
    }, [singleApproved]);

    // Listen if the current region is now correct
    useEffect(() => {

        if (singleApproved) {
            putSingleApproved();
        } else {
            handleTextAreaComments();
        }
    }, [prevRegion]);

    // Save when single approved is true
    const putSingleApproved = async () => {
        setSingleApproved(true);
        try {
            const data = {
                "review": {
                    "comment": [currentRegion.data.review.comment[0], currentRegion?.data?.review?.comment[1]],
                    "approved": true
                }
            };

            const result = await putReviewSegment(data, currentRegion.data.id);

            if (result) {
                currentRegion.update({
                    data: {
                        ...currentRegion.data,
                        review: {
                            comment: [currentRegion.data.review.comment[0], currentRegion?.data?.review?.comment[1]],
                            approved: true
                        }
                    }
                });

                setStatusSave(true);

                setToggleSaveSingleApproved(!toggleSaveSingleApproved);
                //console.log("CurrentRegion: ", currentRegion.data)
            } else {
                setStatusSave(false);
            }
        } catch (error) {
            //console.log(error)
        }
    }

    useEffect(() => {

        if (allApproved) setSingleApproved(true);
    }, [allApproved]);

    // Handle autosave whenever the user types in the textarea, when the approve is false
    const handleTextAreaComments = async (e) => {
        if ((currentRegion === prevRegion) && (currentRegion?.data?.id)) {
            setSingleApproved(false);
            let value = e !== undefined ? e.target.value : currentRegion?.data?.review?.comment[0];
            setTextarea(value);
            try {
                const result = await putReviewSegment(
                    {
                        review: {
                            comment: [value, currentRegion?.data?.review?.comment[1] ? currentRegion?.data?.review?.comment[1] : null],
                            approved: false
                        },
                    },
                    currentRegion?.data?.id
                );

                if (result) {
                    currentRegion?.update({
                        data: {
                            ...currentRegion.data,
                            review: {
                                ...currentRegion.data.review,
                                comment: [value, currentRegion?.data?.review?.comment[1]],
                                approved: false
                            }
                        }
                    });
                    setStatusSave(true);
                    setToggleSaveTextArea(!toggleSaveTextArea);
                }
                //console.log("CurrentRegion: ", currentRegion.data)
            } catch (error) {
                //console.log(error)
            }
        } else {
            setPrevRegion(currentRegion);
        }
    };

    // Call to DB to save QC comments
    const putReviewSegment = async (data, id) => {
        let response;
      if(currentProject.service==="dubbing"||currentRegion.data.user){
        response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
      }else{

        response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
      }
        const result = await response.json();

        return result;
    };

    // Manage Snackbar and save QC comments
    const handleSaveComment = () => {
        //console.log("save");

        if (statusSave) {
            //console.log("Review saved");
            setMessage("Review saved");
            setOpenToast(true);
        } else {
            //console.log("Error Saving Review");
            setMessage("Error Saving Review");
            setOpenToast(true);
        }
    };

    // todo: -----------------------Approve All Checkbox Management-----------------------

    const handleChangeAllApproved = (event) => {
        setAllApproved(event.target.checked);
    }

    useEffect(() => {

        (async () => {
            if (allApproved) {
                let response;
                if (currentProject.service === "dubbing") {
                    response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/approveAll/${currentProject._id}`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                } else {
                // Call to DB to save all approved
                response = await fetch(`${process.env.REACT_APP_URL}/api/segments/approveAll/${currentProject._id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            }
                const result = await response.json();

                if (result) {
                    setSingleApproved(true);
                    setToggleSaveAllApproved(!toggleSaveAllApproved);
                }

                //Update colors
                try {
                    regions.forEach(
                        (region) => {
                            region.update({
                                data: {
                                    ...region.data,
                                    review: {
                                        ...region.data.review,
                                        approved: true
                                    }
                                }
                            });
                            try {
                                region.update({ color: "#8EC36A99" });
                            } catch { }
                            //console.log("Region: ", region.data)
                        }
                    )
                } catch {
                    //console.log("No hay regiones para colorear");
                }
            }
        })();
    }, [allApproved]);

    // todo: If all segments are manually approved, auto-check "All Approved" checkbox
    useEffect(() => {
        const manualApproveAll = async () => {
            if (singleApproved === true) {                
                const resultados = regions.some(segment => segment.data.review.approved === false);
                setAllApproved(!resultados);
            } else {
                setAllApproved(false);
            }
        }
        manualApproveAll();
    }, [toggleSaveSingleApproved, toggleSaveTextArea]);


    // todo: Detects if the review is complete, and enables the send button
    useEffect(() => {
        
        let regions2
        const enableSend = async () => {
            let resultado = regions2?.some(region => {
                if ((region.data.review.approved === false && (region.data.review.comment[0] === region.data.review.comment[1])) ||
                    (region.data.review.approved === false && (region.data.review.comment[0] === null || region.data.review.comment[0] === ""))) {
                    return true;
                } else {
                    return false;
                }

            })
            // CLIENT
            if (currentProject.rol.role === "CLIENT" || currentProject.rol.role === "CLIENT2") {
                resultado = false;
            }
            setResultCheck(resultado);
        }

        if(websurfer.current?.regions?.list) regions2 = Object.values(websurfer.current?.regions?.list);

        if (!currentProject._id) return;
        enableSend();
    }, [toggleSaveSingleApproved, toggleSaveAllApproved, toggleSaveTextArea, currentRegion]);



    return (
        <DataQC.Provider value={{
            isDescriptionButtonActive, setIsDescriptionButtonActive,
            isPreviewButtonActive, setIsPreviewButtonActive,
            isCommentButtonActive, setIsCommentButtonActive,
            isClientButtonActive, setIsClientButtonActive,
            isReasonButtonActive, setIsReasonButtonActive,
            isEditingDescription, setIsEditingDescription,
            currentDescription, setCurrentDescription,
            singleApproved, setSingleApproved,
            selectedButton, setSelectedButton,
            resultCheck, setResultCheck,
            allApproved, setAllApproved,
            statusSave, setStatusSave,
            openToast, setOpenToast,
            textarea, setTextarea,
            message, setMessage,
            handleEditingDescription,
            handleDescriptionEditor,
            handleChangeAllApproved,
            handleTextAreaComments,
            handleButtonClick,
            handleSaveComment,
            handleChangeCHBX
        }}>
            {children}
        </DataQC.Provider>
    )
};