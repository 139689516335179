import { createContext, useState } from "react";

export const DataDashboard = createContext();

export const DataDashboardProvider = ({ children }) => {
  const [countToReview, setCountToReview] = useState(0);
  const [countInProgress, setCountInProgress] = useState(0);
  const [countNew, setCountNew] = useState(0);
  const [countActive, setCountActive] = useState(0);
  

  const searchCount = (projects) => {
    const counts = {
      "To review": 0,
      "In progress": 0,
      "New": 0,
    };
    projects.forEach((project) => {if (project.segments.length >0) counts[project.stage.state] += 1;});
    setCountToReview(counts["To review"]);
    setCountInProgress(counts["In progress"]);
    setCountNew(counts["New"]);
  };

  return (
    <DataDashboard.Provider
      value={{
        countToReview,
        countInProgress,
        countNew,
        searchCount
      }}
    >
      {children}
    </DataDashboard.Provider>
  );
};
