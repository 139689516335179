
export const resetReview = async (idProject) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/resetAll/${idProject}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};



export const generateDiarization = (video) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/diarization/generate/${video}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        reject(`Error: ${response.status}`);
        return;
      }
      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(`Error: ${error.message}`);
    }
  });
};


export const checkBDDiarization = async (video) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/api/diarization/${video}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};


export const joinRTTM_SRT = (srt, rttm) => {
  let holgura = 1;

  rttm.segmentos.forEach((item) => {
    let start = item.start;
    let end = item.stop;
    let speaker = item.speaker;

    //si start y end son string convertirlos a numero
    if (typeof start === "string") {
      start = parseFloat(start);
    }
    if (typeof end === "string") {
      end = parseFloat(end);
    }
    srt.forEach((sub) => {
      if (start - holgura <= sub.startTime && end + holgura >= sub.endTime) {
        sub.speaker = speaker;
      }
    });
  });

  return srt;
};

export const compareAssignSpeaker = (json) => {
  // let contador = 0;
  json.forEach((item, index) => {
    if (item.speaker === "undefined") {
      //ver el objeto anterior y el siguiente y asignar si son iguales

      if (index > 0 && index < json.length - 1) {
        if (json[index - 1].speaker === json[index + 1].speaker) {
          item.speaker = json[index - 1].speaker;
        }
        //si los dos no son iguales ver el objeto anterior y asignar el speaker
        else if (json[index - 1].speaker !== json[index + 1].speaker) {
          item.speaker = json[index - 1].speaker;
        }
        //si el objeto anterior no tiene speaker asignar el del siguiente
      } else if (index === 0) {
        item.speaker = json[index + 1].speaker;
        //si el siguiente es undefined seguir hasta encontrar un speaker
        if (item.speaker === "undefined") {
          let i = 1;
          while (item.speaker === "undefined") {
            item.speaker = json[index + i].speaker;
            i++;
          }
        }
      } else {
        item.speaker = json[index - 1].speaker;
      }
      // contador++;
    }
  });
  // console.log(contador);
  return json;
};

export const joinSRTtoRTTM = (srt, rttm) => {
  let holgura = 1.5;
  const segmentosIgnorados = [];
  const segmentosAsignados = new Set();

  //itera sobre los segmentos del rttm
  srt.forEach((item, index) => {
    let startSRT = item.startTime;
    let endSRT = item.endTime;
    let segmentoAceptado = false;

    rttm.segmentos.forEach((itemRTTM) => {
      
      let start = itemRTTM.start;
      let end = itemRTTM.stop;
      let speaker = itemRTTM.speaker;
      if (typeof start === "string") {
        start = parseFloat(start);
      }
      if (typeof end === "string") {
        end = parseFloat(end);
      }

      //si el srt esta entre el tiempo incial y final del rttm asignar segmento con holgura de 1 segundo
      if (segmentosAsignados.has(item.id)){
        return;
      }

      if (start - holgura <= startSRT && end + holgura >= endSRT) {
        
        if (!itemRTTM.segmentosSRT) {
          itemRTTM.segmentosSRT = [];
        }
        //asigna el segmento del srt al segmento del rttm
        itemRTTM.segmentosSRT.push(item);
        segmentosAsignados.add(item.id);
        
        //const text = `(${item.id}) ${item.text}`;
        
        itemRTTM.text ? (itemRTTM.text +=" " + item.text) : (itemRTTM.text = item.text);
        
        segmentoAceptado = true;
      }
    });

    !segmentoAceptado ? segmentosIgnorados.push(item) : null;
  });

  return [rttm, segmentosIgnorados];
};

export const compareAssignSpeakerRTTM = (json) => {
  let segmentos = [];

  json.forEach((element, index) => {
    if (index < json.length - 1) {
      if (json[index].speaker === json[index + 1].speaker) {
        if (json[index].id == json[index + 1].id - 1) {
          //si el speaker no esta en el objeto agregarlo
          if (!segmentos[json[index].speaker]) {
            segmentos[json[index].speaker] = new Set();
          }
          //agregar el id al objeto
          segmentos[json[index].speaker].add(json[index]);
          segmentos[json[index].speaker].add(json[index + 1]);
        }
      }
    }
  });
  let containerSegments = [];
  let endTime = 0;
  for (const speaker in segmentos) {
    const speakerSegment = new Set();
    let startTime = Array.from(segmentos[speaker])[0].startTime;

    let speakerLabel = speaker;
    Array.from(segmentos[speaker]).forEach((element, index) => {
      if (index < Array.from(segmentos[speaker]).length - 1) {
        //si los id estan en secuencia guardar en un arreglo
        if (element.id == Array.from(segmentos[speaker])[index + 1].id - 1) {
          speakerSegment.add(element);
          speakerSegment.add(Array.from(segmentos[speaker])[index + 1]);
          endTime = Array.from(segmentos[speaker])[index + 1].endTime;
        } else {
          //crear un nuevo objeto y guardar el segmento
          const obj = {
            speaker: speakerLabel,
            start: startTime,
            stop: element.endTime,
            segmentosSRT: Array.from(speakerSegment),
          };
          containerSegments.push(obj);
          speakerSegment.clear();
          startTime = Array.from(segmentos[speaker])[index + 1].startTime;
        }
      }
    });
    const obj = {
      speaker: speakerLabel,
      start: startTime,
      stop: endTime,
      segmentosSRT: Array.from(speakerSegment),
    };

    containerSegments.push(obj);
  }
  return containerSegments;
};

export const generateEmptySegmentsArray = (originalArray) => {
  const newArray = [];
  //primer elemento del arreglo es el tiempo inicial 0 y el primero elmento el tiempo inicial
  newArray.push({
    start: 0,
    stop: originalArray[0].start,
    duration: originalArray[0].start - 0.001,
    speaker: "empty",
  });
  //iterar sobre el arreglo original
  for (let i = 0; i < originalArray.length - 1; i++) {
    //si el tiempo entre el segmento y el siguiente es mayor a 2 segundo
    if (originalArray[i + 1].start - originalArray[i].stop > 2) {
      const emptySegment = {
        start: originalArray[i].stop,
        stop: originalArray[i + 1].start,
        duration: originalArray[i + 1].start - originalArray[i].stop,
        speaker: "empty",
      };
      newArray.push(emptySegment);
    }
  }

  return newArray;
};

export const joinSegmentsRTTM = (containerSegments, rttm) => {
  //unir los segmentos con el rttm

  rttm.segmentos.push(...containerSegments);
  return rttm;
};

export const verifiedIgnoredSegments = (srt, rttm) => {
  let segmentsSrt = [];
  rttm.segmentos.forEach((element) => {
    if (element.segmentosSRT) {
      Array.from(element.segmentosSRT).forEach((item) => {
        segmentsSrt.push(parseInt(item.id));
      });
    }
  });
  //hacer un arreglo de uno hasta el ultimo id del srt
  let arraySrt = Array.from({ length: srt.length }, (_, i) => i + 1);

  //obtener los numeros que no estan en el arreglo de los segmentos
  let noEstanEnSegmentos = arraySrt.filter((x) => !segmentsSrt.includes(x));
  let noEstanEnSrt = segmentsSrt.filter((x) => !arraySrt.includes(x));
  let segmentsSrtIgnored = noEstanEnSegmentos.concat(noEstanEnSrt);
  return segmentsSrtIgnored;
}

export const assignIgnoredSegments = (segmentsSrtIgnored, joinAll,srt) => {
  segmentsSrtIgnored.forEach((id) => {
    const segment = srt.find((segment) => parseInt(segment.id) === id )
    const previousSegment = joinAll.segmentos.find((segmentGroup)=> segmentGroup?.text?.includes(`(${parseInt(segment.id)-1})`) );
    const nextSegment = joinAll.segmentos.find((segmentGroup)=> segmentGroup?.text?.includes(`(${parseInt(segment.id)+1})`) );
    
    let segmentAssign 
    if(previousSegment && nextSegment){
      const previousTime = segment.startTime - previousSegment.stop;
      const nextTime = nextSegment.start - segment.endTime;
      // console.log("previousTime",previousTime,"nextTime",nextTime);
      if(previousTime < nextTime){
        segmentAssign = previousSegment;
        segmentAssign.text += `(${segment.id}) ${segment.text}`;
      }else{
        segmentAssign = nextSegment;
        segmentAssign.text = `(${segment.id}) ${segment.text}` + nextSegment.text;
      }
    }else if(previousSegment){
      segmentAssign = previousSegment;
      segmentAssign.text += `(${segment.id}) ${segment.text}`;
    }else if(nextSegment){
      segmentAssign = nextSegment;
      segmentAssign.text = `(${segment.id}) ${segment.text}` + nextSegment.text;
    }
    segmentAssign.segmentosSRT ? segmentAssign.segmentosSRT.push(segment) : segmentAssign.segmentosSRT = [];
    if (!segmentAssign.segmentosSRT.includes(segment)) {
      segmentAssign.segmentosSRT.push(segment);
    }
    
  })
  return joinAll;
};

export const groupBySpeaker = (srt) => {
  return srt.reduce((acc, obj) => {
    const speaker = obj.speaker;

    // Si el speaker aún no existe en el nuevo arreglo, agrégalo
    if (!acc[speaker]) {
      acc[speaker] = [];
    }

    // Agrega el segmento al arreglo correspondiente al speaker
    acc[speaker].push({
      endTime: obj.endTime || obj.stop,
      id: obj.id || obj.index,
      startTime: obj.startTime || obj.start,
      text: obj.text
    });

    return acc;
  }, {});

}