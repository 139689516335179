export const createProject = [
  {
    name: "Name project",
    type: "text",
    required: true,
    value: "",
    placeholder: "Name project",
  },

  {
    name: "Type of service",
    type: "select",
    required: true,
    value: "",
    placeholder: "Select type of service",
    options: [
      {
        value: "audio-description",
        label: "Audio Description",
      }/*,      
      {
        value: "dubbing",
        label: "Dubbing",
      },
      {
        value: "sign-language-caption",
        label: "Sign Language Caption",
      },
      {
        value: "audio-description+dubbing",
        label: "Audio Description + Dubbing",
      },*/
    ],
  },
  {
    name: "Due to",
    type: "date",
    required: true,
    value: "",
    placeholder: "Start date",
  },
];
export const createUser = [
  {
    name: "Name",
    type: "text",
    required: true,
    value: "",
    placeholder: "Enter a name",
  },
  {
    name: "Surname",
    type: "text",
    required: true,
    value: "",
    placeholder: "Enter a surname",
  },
  {
    name: "Email",
    type: "text",
    required: true,
    value: "",
    placeholder: "Enter an email",
  },
  {
    name: "Rol",
    type: "select",
    required: true,
    value: "",
    placeholder: "Select a rol",
    options: [
      {
        value: "Describer",
        label: "Describer",
      },
      {
        value: "Narrator",
        label: "Narrator",
      },
      {
        value: "Editor",
        label: "Editor",
      },
      {
        value: "Quality-Control",
        label: "Quality Control",
      },
      {
        value: "CLIENT",
        label: "Client",
      },
    ],
  },
];
export const uploadResources = [
  {
    name: "Video",
    type: "file",
    required: true,
  },  
  
  {
    name: "Guidelines",
    type: "text",
    required: true,
  },
];
export const uploadResourcesDubbing = [
  {
    name: "Video",
    type: "file",
    required: true,
  },  
  {
    name: "Guidelines",
    type: "text",
    required: true,
  },
];

export const usersDescriptor = [
  {
    name: "userDescriptor 1",
    label: "userDescriptor 1",
  },
  {
    name: "userDescriptor 2",
    label: "userDescriptor 2",
  },
  {
    name: "userDescriptor 3",
    label: "userDescriptor 3",
  },
];
export const userNarrator = [
  {
    name: "userNarrator 1",
    label: "userNarrator 1",
  },
  {
    name: "userNarrator 2",
    label: "userNarrator 2",
  },
  {
    name: "userNarrator 3",
    label: "userNarrator 3",
  },
];
export const userEditor = [
  {
    name: "userEditor 1",
    label: "userEditor 1",
  },
  {
    name: "userEditor 2",
    label: "userEditor 2",
  },
  {
    name: "userEditor 3",
    label: "userEditor 3",
  },
];
export const userQualityControl = [
  {
    name: "userQualityControl 1",
    label: "userQualityControl 1",
  },
  {
    name: "userQualityControl 2",
    label: "userQualityControl 2",
  },
  {
    name: "userQualityControl 3",
    label: "userQualityControl 3",
  },
];

export const rolesAudioDescription = [
  "Describer",
  "Narrator",
  // "Editor",
  "Quality Control",
  "Client",
];
export const rolesVoiceOver = ["Quality Control","Narrators"];
export const rolesAudioDescription_Dubbing = [
  "Describer",
  "Narrators",
  "Editor",
  "Quality Control",
];

export const rolesSignLanguageCaption = ["Script Adapter", "Interpreter"];
export const projects = [
  {
    name: "Project 1",
    service: "Audio Description",
    start_date: "01/01/2021",
    end_date: "01/01/2021",
  },
  {
    name: "Project 2",
    service: "Dubbing",
    start_date: "01/01/2021",
    end_date: "01/01/2021",
  },
  {
    name: "Project 3",
    service: "Audio Description + Dubbing",
    start_date: "01/01/2021",
    end_date: "01/01/2021",
  },
  {
    name: "Project 3",
    service: "Audio Description + Dubbing",
    start_date: "01/01/2021",
    end_date: "01/01/2021",
  },
];
export const rolesTranslation_AudioDescription = [
  "Describer",
  "Narrator Women",
  "Narrator Men",
  "Narrator",
];
