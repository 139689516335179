import { Button, CircularProgress, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataUser } from "../../context/DataUser";

const ModalDeleteProject = ({ open, setOpen, project }) => {

  const [loading, setLoading] = useState(false);
  const { setProjects, setProjectsOriginal, projectsOriginal } = useContext(DataUser);
  const [statusDelete, setStatusDelete] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleDeleteProject = async () => {
    setLoading(true);
    const result = await fetch(`${process.env.REACT_APP_URL}/api/projects/${project}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.status) {
      setStatusDelete(true);
      setOpenToast(true);
      setOpen(false);

      const newProjects = projectsOriginal.filter((item) => item._id.toString() !== project.toString());
      setProjects(newProjects);
      setProjectsOriginal(newProjects);
      setLoading(false);
    }
    else {
      setStatusDelete(false);
      setOpenToast(true);
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusDelete ? "success" : "error"}>
          {statusDelete ? " Project deleted" : "Error deleting Project "}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          Would you really like to delete?
        </DialogTitle>
        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                onClick={() => setOpen(false)}
                sx={{ color: "white", backgroundColor: "#952937" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteProject()}
                autoFocus
                sx={{ color: "white", backgroundColor: "#8EC36A" }}
              >
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDeleteProject;
