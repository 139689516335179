import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { stepsAD, stepsAN, stepsCLT, stepsDASH, stepsDASH_PM, stepsQC } from '../../../utils/user-guide/steps';

export default function GuideStepper({ type }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState([]);
    const maxSteps = steps.length;

    useEffect(() => {
        if (type === 'dashboard') {
            setSteps(stepsDASH);
        }else if (type === 'dashboardPM') {
            setSteps(stepsDASH_PM);
        }else if (type === 'audioDescriber') {
            setSteps(stepsAD);
        } else if (type === 'audioNarrator') {
            setSteps(stepsAN);
        } else if (type === 'qualityControl') {
            setSteps(stepsQC);
        } else if (type === 'client') {
            setSteps(stepsCLT);
        }
    }, [type]);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Paper
                square
                elevation={0}
                className='GuideStepper'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'var(--black)',
                }}
                role="status"
                aria-live="polite"
                aria-relevant="additions"
            >
                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} id="guide-step-label" >{steps[activeStep]?.label}</Typography>
            </Paper>
            <Box sx={{ height: '100%', width: '100%', p: 4, overflow: 'auto' }} role="region" aria-labelledby="guide-step-label" aria-live="polite">
                <div dangerouslySetInnerHTML={{ __html: steps[activeStep]?.description }} />
            </Box>

            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                className='GuideStepper'
                sx={{
                    width: '100%',
                    bgcolor: 'var(--black)',
                    p: 2,
                }}
                role="navigation"
                aria-label="Guide Navigation"
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        aria-label="Next Step"
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0} aria-label="Previous Step">
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}