
const sendEmailNeedExternalAudio = async (projectId) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/send-email-external-audio/${projectId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    });
    try {
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    } finally {
        console.info('end sendEmailNeedExternalAudio');
    }
}

export { sendEmailNeedExternalAudio };
