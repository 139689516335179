import React, { useContext } from "react";
import { Time } from "../components/Time";
import { TextFields } from "../components/describer/TextFields";
import TextField from "../components/scriptAdapter/TextField";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataUser } from "../context/DataUser";
import styles from "../css/DescriptorActions.module.css";

export const DescriptorActions = () => {
  const { currentRegion, resized } = useContext(DataDescriptor);
  const { currentProject } = useContext(DataUser);

  return (
    <div className={styles.container__descriptorActions} style={{width: resized ? "25%" : "40%"}}>
      {currentRegion ? (
        <>
          <Time />
          {currentProject.service === "sign-language-caption"?
          <TextField />:<TextFields /> }
        </>
      ) : (
        <div className={styles.select__segment}>
          <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
          <span>Select a Segment</span>
        </div>
      )}
    </div>
  );
};
