import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import React, { useContext, useRef, useState } from 'react';
import { DataUser } from '../context/DataUser';
import styles from "../css/Viewer.module.css";
export const Viewer  = () => {
    const { currentProject } = useContext(DataUser);

    const video1Ref = useRef(null);
    const video2Ref = useRef(null);
    const [playing, setPlaying] = useState(false);
  
    const togglePlay = () => {
      if (playing) {
        
        video1Ref.current.pause();
        video2Ref.current.pause();
      } else {
        video1Ref.current.play();
        video2Ref.current.play();
      }
      setPlaying(!playing);
    };
  
    const restartVideos = () => {
      video1Ref.current.currentTime = 0;
      video2Ref.current.currentTime = 0;
    };

  return (
    <div className={styles.container__viewer}>
      <div className={styles.videoContainer} >
        <video
          ref={video1Ref}
          width="100%"
          controls
          style={{ width: '100%' }}
        >
          <source src={currentProject.video} type="video/mp4" />
        </video>
      </div>

      <div className={styles.videoContainer} >
        <video
          ref={video2Ref}
          width="100%"
          controls
          style={{ width: '100%' }}
        >
          <source src={currentProject.audiomix} type="video/mp4" />
        </video>
      </div>

     
      <div className={styles.container__videoControls}>
            
            <button onClick={togglePlay} >
              {playing ? (
                <PauseIcon sx={{ fontSize: 40 }} />
              ) : (
                <PlayArrowIcon sx={{ fontSize: 40 }} />
              )}
            </button>

            <button onClick={restartVideos} aria-label="Stop">

              <StopIcon sx={{ fontSize: 40 }} />
            </button>
            
        </div>
    </div>
  );
};
