import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../../css/Form.module.css";

import { useContext, useState } from "react";
import { DataPM } from "../../context/DataPM";
import { createUser } from "../../utils/dataProjectManager";
export const FormDialog = ({ open, handleClose }) => {
  const { isCreateUser, setIsCreateUser } = useContext(DataPM);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("123456");
  const [role, setRole] = useState("AD");
  const [role2, setRole2] = useState("");
  const optionsRole = [
    {
      value: "AD",
      label: "Describer",
    },
    {
      value: "AN",
      label: "Narrator",
    },
    {
      value: "AE",
      label: "Editor",
    },
    {
      value: "QC",
      label: "Quality Control",
    },
    {
      value: "INTERPRETER",
      label: "Interpreter",
    },
    {
      value: "SCRIPT_ADAPTER",
      label: "Script Adapter",
    },
    {
      value: "CLIENT",
      label: "Client",
    }
    /*{
      value: "None",
      label: "Sin rol",
    },*/
  ];

  const setDataProject = (value, name) => {
    switch (name) {
      case "Name":
        setName(value);
        break;
      case "Surname":
        setLastName(value);
        break;
      case "Email":
        setEmail(value);
        break;
      case "Password":
        setPassword(value);
        break;

      default:
        break;
    }
  };
  const handleChangeRole = (event) => {
    //console.log(event.target.value);
    setRole(event.target.value);
  };
  const handleChangeRole2 = (event) => {
    //console.log(event.target.value);
    setRole2(event.target.value);
  };
  const getValues = (nameInput) => {
    switch (nameInput) {
      case "Name":
        return name;
      case "Surname":
        return lastName;
      case "Email":
        return email;
      case "Password":
        return password;
      default:
        break;
    }
  };
  const createUserBD = async () => {
    if (name === "" || lastName === "" || email === "" || role === "None") {
      //console.log("Error en los datos");
      return;
    }

    
    const data = {
      name,
      surname: lastName,
      email,
      password,
      roles: (role !=='QC' && role !=='CLIENT')?[role,role2]:[role],
    };
    const response = await fetch(`${process.env.REACT_APP_URL}/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    if (result.user) {
      setIsCreateUser(true);
    }
    //console.log(result);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{ minWidth: "500px" }}>
      <DialogTitle
        sx={{ color: "white", minWidth: "500px", backgroundColor: "#28262E" }}
      >
        Create user
      </DialogTitle>
      <DialogContent
        className={styles.container__form}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          minWidth: "500px",
          color: "white",
          backgroundColor: "#28262E",
          fontFamily: "Roboto",
        }}
      >
        {createUser.map(({ name, type, required, placeholder }, index) => (
          <FormControl
            key={index}
            sx={{
              m: 1,
              marginBottom: "1rem",
            }}
            variant="outlined"
          >
            <label className={styles.label} htmlFor={name}>
              {name} {required && "*"}
            </label>
            {type === "text" && (
              <OutlinedInput
                id={name}
                sx={{
                  color: "white",
                  backgroundColor: "#28262E",
                  placeholder: {
                    color: "white",
                  },
                  fieldset: {
                    borderColor: "white",
                    color: "white",
                  },
                  label: {
                    color: "white",
                  },
                  text: {
                    color: "white",
                  },
                  button: {
                    color: "white",
                  },
                }}
                value={getValues(name)}
                placeholder={placeholder}
                onChange={(e) => setDataProject(e.target.value, name)}
              />
            )}

            {type === "select" && (
              <>
                <FormControl>
                  <Select
                    id="1"
                    input={<OutlinedInput />}
                    sx={{
                      color: "white",
                      backgroundColor: "#28262E",
                      textAlign: "initial",
                      marginBottom: "1rem",
                      placeholder: {
                        color: "white",
                      },
                      fieldset: {
                        borderColor: "white",
                        color: "white",
                      },
                      label: {
                        color: "white",
                      },
                      text: {
                        color: "white",
                      },
                      button: {
                        color: "white",
                      },
                    }}
                    value={role}
                    label={placeholder}
                    onChange={handleChangeRole}
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(value) => {
                      return value;
                    }}
                  >
                    {optionsRole.map(({ value, label }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {role !=='QC' && role !=='CLIENT' && (
                  <FormControl>
                  <Select
                    id="2"
                    input={<OutlinedInput />}
                    sx={{
                      textAlign: "initial",
                      marginBottom: "1rem",
                      color: "white",
                      backgroundColor: "#28262E",
                      placeholder: {
                        color: "white",
                      },
                      fieldset: {
                        borderColor: "white",
                        color: "white",
                      },
                      label: {
                        color: "white",
                      },
                      text: {
                        color: "white",
                      },
                      button: {
                        color: "white",
                      },
                    }}
                    value={role2}
                    label={placeholder}
                    onChange={handleChangeRole2}
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(value) => {
                      return value;
                    }}
                  >
                    {optionsRole.map(({ value, label }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
              </>
            )}
          </FormControl>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          color: "white",
          backgroundColor: "#28262E",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            color: "white",
            background: " linear-gradient(180deg, #582A50 0%, #A93F90 100%)",
            width: "90%",
            padding: ".7rem",
            fontSize: "1rem",
            marginBottom: "1rem",
          }}
          onClick={createUserBD}
        >
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  );
};
