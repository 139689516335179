import React, { useContext, useEffect } from 'react';
import { DataDescriptor } from '../../context/DataDescriptor';
import { DataNarrator } from '../../context/DataNarrator';
import { DataUser } from '../../context/DataUser';
import styles from "../../css/Narrator.module.css";

export const InterpreterTabs = () => {
    const {
        isEditingDescription, setIsEditingDescription,
        currentDescription, setCurrentDescription,
        handleButtonClick,
    } = useContext(DataNarrator);
    const { currentRegion } = useContext(DataDescriptor);
    const { currentProject } = useContext(DataUser);


    useEffect(() => {
        setIsEditingDescription(false); // to avoid the description editor to be open when changing btwn regions
    }, [currentRegion]);


    // todo: ------------------------Read description from DB------------------------
    useEffect(() => {
        (async () => {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsSign/${currentProject._id}`, { method: "GET" });
            const result = await response.json();
            const resultID = result.filter(segment => segment._id === currentRegion.data.id);
            if (isEditingDescription) {
                document.getElementById("description-editor").value = resultID[0].script;
            }
            setCurrentDescription(resultID[0].script);
        })();
    }, [ currentRegion]);

    return (
        <div className={styles.n_container}>
              <div id="buttons_container" className={styles.div__btnsContainer}>
                <button
                    id='descriptionBtn'
                    className={`
                    ${styles.btn__description} ${styles.btn__n}
                    ${styles.btn__selected}                      
                    ${currentRegion.data.comment ? "" : styles.btn__withoutComment}`
                    }
                    onClick={() => handleButtonClick("descriptionBtn")}
                    aria-label='Read description, Shortcut to focus: Alt + X'
                    aria-describedby='current-description'
                >
                    <p >&nbsp;CAPTION&nbsp;</p>                    
                </button>               
            </div >
                <p id='current-description' aria-label={'current description is,' + currentDescription?.initial} className={styles.n_content} tabIndex="0">
                    {currentDescription}
                </p>

        </div >
    )
};
