export const downloadLink = async (url, name=null,type) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
  
      if (!response.ok) {
        throw new Error(`No se pudo descargar el archivo. Código de estado HTTP: ${response.status}`);
      }
  
      const blob = await response.blob();
      let filename = name;
      if(name){
        const extension = url.split('.').pop();
        filename = name.replace(/ /g, '_') +'_' + type +'.' + extension;
      }else{
        filename = url.split('/').pop(); // Obtener el nombre del archivo de la URL
      }
  
      // Create blob link to download
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
  
      // Append to HTML link element to trigger the download
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link after a short delay
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }, 100);
    } catch (error) {
      console.error(error);
      window.open(url, '_blank');
    }
  };