import AudioFileIcon from '@mui/icons-material/AudioFile';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MovieIcon from '@mui/icons-material/Movie';
import SaveIcon from "@mui/icons-material/Save";
import SpeakerIcon from '@mui/icons-material/Speaker';
import { CircularProgress, Snackbar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/CardProject.module.css";
import { requestSegmentstoSrt } from "../../utils/export-srt";
import { downloadLink } from '../../utils/project/download';
import { ProgressProject } from "../dashboard/ProgressProject";
import ModalDeleteProject from "../projectManager/ModalDeleteProject";
import ModalEditProject from '../projectManager/ModalEditProject/ModalEditProject';

export const CardProject = ({ project }) => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [isEdit] = useState(false);
  const [name, setName] = useState(project.name);
  const [projectLink, setProjectLink] = useState("");
  const { setCurrentProject, user } = useContext(DataUser);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const hasVoiceOver = project?.users?.filter((user) => user?.role === 'AN_D');

  const handleClickOpenModalDelete = () => setOpenModalDelete(true);
  const handleClickOpenModalEdit = () => {
    console.log(project);
    setOpenModalEdit(true);
  }

  // const downloadVideoExcel = () => {
  //   console.log(project);
  //   // const segments =getSegmentsProject(project._id);
  //   const videoUrl = project.video;

  //   const segmentsClean = cleanJson(project.segments);
  //   // console.log(project.segments);
  //   jsonToCSV(segmentsClean, project.name);

  //   //BRIR EN UNA NUEVA PESTAÑA
  //   window.open(videoUrl, "_blank");
  // };
  // const cleanJson = (segments) => {
  //   let newSegments = [];
  //   segments.forEach((segment) => {
  //     segment = {
  //       description: segment.description.initial,
  //       comment: segment.comment ? segment.comment : "",
  //       start: secondsToSubripString(segment.start),
  //       end: secondsToSubripString(segment.end),
  //     };
  //     newSegments.push(segment);
  //   });
  //   return newSegments;
  // };

  // const handleUpdateName = (project, name) => {
  //   updateNameProject(project, name).then((data) => {
  //     if (data.status) {
  //       setOpenToast(true);
  //       setStatusSave("success");
  //       setMessage("Project name updated successfully");
  //       setName(name);
  //     } else {
  //       setOpenToast(true);
  //       setStatusSave("error");
  //       setMessage(data.msg);
  //       setName(project.name);
  //     }
  //     getProyects();
  //     setIsEdit(false);
  //   }).catch((error) => console.error(error));
  // };

  const getUsersDubbing = (rol) => {
    let users = [];
    project.users.forEach((user) => {
      if (user.role?.includes(rol)) {
        users.push(user.user_id);
      }
    }
    );
    return users;
  }

  const downloadMixAudio = async (url, type) => {
    setLoading(true);
    setType(type)
    if (url.includes(",")) {
      const urls = url.split(",");
      urls.forEach(async (url) => {
        await downloadLink(url, project.name, type)
        setLoading(false);
      });
      // const zip = new JSZip();
      // let cont = 0
      // urls.forEach(async (url) => {
      //   await fetch(url)
      //     .then(response => response.arrayBuffer())
      //     .then(data => {
      //       zip.file(`${url.split("/").pop()}`, data, { binary: true });
      //       cont++
      //       if (urls.length === cont) {
      //         zip.generateAsync({ type: "blob" }).then(blob => {
      //           const zipUrl = URL.createObjectURL(blob);
      //           const a = document.createElement("a");
      //           a.href = zipUrl;
      //           a.download = project.name.split(" ").join("_") + ".zip";
      //           a.click();
      //           URL.revokeObjectURL(zipUrl);
      //           setLoading(false);
      //         });
      //       }
      //     });
      //   });


    } else {
      await downloadLink(url, project.name, type);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project) {
      if (hasVoiceOver.find((userVO) => userVO?.user_id?._id === user.uid)) {
        setProjectLink(`/narrator-dubbing/${project._id}`);
      } else {
        switch (project.rol.role) {
          case "AD":
            setProjectLink(`/audio-descriptor/${project._id}`);
            break;
          case "QC":
            setProjectLink(`/quality-control/${project._id}`);
            break;
          case "QC2":
            setProjectLink(`/quality-control/${project._id}`)
            break;
          case "AN":
            setProjectLink(`/narrator/${project._id}`);
            break;
          case 'SCRIPT_ADAPTER':
            setProjectLink(`/script-adapter/${project._id}`);
            break;
          case 'INTERPRETER':
            setProjectLink(`/interpreter/${project._id}`);
            break;
          case "AN_D":
            setProjectLink(`/narrator-dubbing/${project._id}`);
            break;
          case "CLIENT":
            setProjectLink(`/client/${project._id}`);
            break;
          case "CLIENT2":
            setProjectLink(`/client/${project._id}`);
            break;
          case "AE":
            if (project.service === "sign-language-caption") {
              setProjectLink(`/viewer/${project._id}`);
            } else {
              setProjectLink("not-found");
            }
            break;
          default:
            setProjectLink("not-found");
        }
      }
      setName(project.name);
    }
  }, [project]);

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
      </Snackbar>
      {user?.roles.includes("PM") ? (
        <>
          <div className={styles.container__cardPM}>
            <div className={styles.container__actions}>
              <button
                variant="contained"
                onClick={() => handleClickOpenModalEdit()}
                className={styles.button + " " + styles.icon}
              >
                {isEdit ? <SaveIcon /> : <EditIcon />}
              </button>
              <button
                variant="contained"
                onClick={() => handleClickOpenModalDelete()}
                className={styles.button + " " + styles.icon}
              >
                <DeleteIcon />
              </button>
              <button
                variant="contained"
                //disabled={project.rol.role !== 'AN'}
                onClick={() => requestSegmentstoSrt(project)}
                className={styles.button}
              >
                <img src="/assets/icons/srt-file-format-symbol.png" alt="srt-file-format-symbol icon" className={styles.icon} />
              </button>
              {(loading && type === 'VO') ? <CircularProgress /> : <button
                variant="contained"
                disabled={!project.audiomix}
                onClick={() => downloadMixAudio(project.audiomix, 'VO')}
                className={styles.button + " " + styles.icon}
              >
                {project.service === "sign-language-caption" ? (
                  <MovieIcon sx={{ color: "#fff" }} />
                ) : (
                  <AudioFileIcon sx={{ color: "#fff" }} />
                )}
              </button>}

              {(loading && type === 'audiofinal') ? <CircularProgress /> : <button
                variant="contained"
                disabled={!project.audiofinal}
                onClick={() => downloadMixAudio(project.audiofinal, 'audiofinal')}
                className={styles.button + " " + styles.icon}
              ><SpeakerIcon sx={{ color: "#fff" }} />
              </button>}

              {project.segments.length > 0 ? (
                (project.rol.role.includes("AN") || project.rol.role.includes("AE") || project.rol.role.includes("QC2")) ? (
                  project.audio ? (
                    <Tooltip title="Project with audio">
                      <span className={styles.label__stateTrueSegments}>✔</span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Project without audio">
                      <span className={styles.label__stateFalseSegments}>✘</span>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title="Project with segments">
                    <span className={styles.label__stateTrueSegments}>✔</span>
                  </Tooltip>
                )
              ) : (
                <Tooltip title="Project without segments">
                  <span className={styles.label__stateProgressSegments}>...</span>
                </Tooltip>
              )}

            </div>
            <div className={styles.container__header}>
              {isEdit ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={styles.input__name}
                />
              ) : (
                <Link
                  onClick={() => setCurrentProject(project)}
                  to={projectLink}
                  className={styles.link}
                >
                  <h3>{name}</h3>
                  <h5>{project.category?.name}</h5>
                </Link>
              )}
            </div>
            <Link
              onClick={() => setCurrentProject(project)}
              to={projectLink}
              className={styles.link}
            >
              <div className={styles.container__main}>
                <p> {project.guideline}</p>
                {project.service === "audio-description" ? (
                  <>
                    <p>
                      <b>Describer: </b>
                      {(project.users[1]?.user_id?.name || " ") +
                        " " +
                        (project.users[1]?.user_id?.surname || " ")}
                    </p>
                    <p>
                      <b>Quality Control: </b>
                      {(project.users[4]?.user_id?.name || " ") +
                        " " +
                        (project.users[4]?.user_id?.surname || " ")}
                    </p>
                    <p>
                      <b>Narrator: </b>
                      {(project.users[2]?.user_id?.name || " ") +
                        " " +
                        (project.users[2]?.user_id?.surname || " ")}
                    </p>
                    {project.users[5]?.user_id ? (
                      <p>
                        <b>Client: </b>
                        {(project.users[5]?.user_id?.name || " ") +
                          " " +
                          (project.users[5]?.user_id?.surname || " ")}
                      </p>
                    ) : null}


                    {hasVoiceOver.length > 0 ? (
                      <>
                      <h4 style={{marginTop:"1rem"}}>Voice Over</h4>
                      {getUsersDubbing("AN_D").map((user, index) => (
                        <p key={index}>
                          <b>Narrator {index+1}: </b>
                          {user?.name + " " + user?.surname}
                        </p>
                      )) }
                      </>
                    ) : null}

                  </>
                ) : project.service === "dubbing" ? (
                  <>
                    {getUsersDubbing("QC").map((user, index) => (
                      <p key={index}>
                        <b>Quality Control: </b>
                        {user?.name + " " + user?.surname}
                      </p>
                    ))}
                    {getUsersDubbing("AN").map((user, index) => (
                      <p key={index}>
                        <b>Narrator {index + 1}: </b>
                        {user?.name + " " + user?.surname}
                      </p>
                    ))}
                  </>
                ) : project.service === "sign-language-caption" ? (
                  <>
                    <p>
                      <b>Script Adapter: </b>
                      {(project.users[1]?.user_id?.name || " ") +
                        " " +
                        (project.users[1]?.user_id?.surname || " ")}
                    </p>
                    <p>
                      <b>Interpreter: </b>
                      {(project.users[2]?.user_id?.name || " ") +
                        " " +
                        (project.users[2]?.user_id?.surname || " ")}
                    </p>
                  </>
                ) : (null)}

              </div>
              <ProgressProject project={project} />
            </Link>
          </div>
          <ModalDeleteProject
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            project={project._id}
          />
          <ModalEditProject
            open={openModalEdit}
            setOpen={(setOpenModalEdit)}
            project={project}
          />
        </>
      ) : (
        <>
          <section
            //  tabindex="1" aria-label='General Project Info:'
            className={styles.container__card}>
            <div className={styles.container__header}>
              <span aria-hidden="true" className={styles.label__state}
                style={{
                  backgroundColor:
                    project.stage.state === "To review" ?
                      "#8D1B4E" :
                      project.stage.state === "In progress" ?
                        "#A93F90" :
                        project.stage.state === "New" ?
                          "#12B4B8" :
                          "transparent",
                }}
              >
                {project.stage.state}
              </span>
              <div className={styles.container__actions}>
                <button
                  variant="contained"
                  onClick={() => requestSegmentstoSrt(project)}
                  className={styles.button}
                  aria-label={project.name + "download srt file"}
                  tabIndex={-1}
                >
                  {/* <ClosedCaptionIcon sx={{ color: "#fff" }} /> */}
                  <img src="/assets/icons/srt-file-format-symbol.png" alt="srt-file-format-symbol icon" className={styles.icon} />
                </button>
                <span>{format(new Date(project.end_date), 'MM/dd/yyyy')}</span>
              </div>
            </div>

            <Link
              onClick={() => setCurrentProject(project)}
              to={projectLink}
              className={styles.link}
              // tabIndex={1}
              aria-label={"Project Selected, " + project.name + ", Status, " + project.stage.state + ", enter project button"}
            >
              <div className={styles.container__main}>
                <h3>{project.name}</h3>
                <p> {project.guideline}</p>
                <p>

                  <b>Project Manager: </b>
                  {(project.users[0]?.user_id?.name || " ") +
                    " " +
                    (project.users[0]?.user_id?.surname || " ")}
                </p>
              </div>
              <div className={styles.container__footer}>
                {project.rol.role === "AD" || project.rol.role === "QC" || project.rol.role === "CLIENT" ? (
                  <span>Audio Description</span>
                ) : project.rol.role === "AN" || project.rol.role === "QC2" || project.rol.role === "CLIENT2" ? (
                  <span>Narration</span>
                ) : project.rol.role === "AN_D" ? (
                  <span>Narration Dubbing</span>
                ) : (
                  <span>Not found</span>
                )}
                <div aria-label="enter project button"></div>
              </div>
            </Link>
          </section>
        </>
      )}
    </>
  );
};
