import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/Timeline.module.css";

export const Timeline = () => {
  const { waveformLoaded, percent,loadingWave } = useContext(DataDescriptor);
  return (
    <div className={styles.container_timeline}>
      {(!waveformLoaded || loadingWave) && (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }} >
          {" "}
          <p role="progressbar" aria-valuenow={percent} aria-live="polite" aria-label={percent < 100 ? "Loading Project" : "Project Successfully Loaded!"} autoFocus></p>
          {loadingWave &&  <div className='loader'></div>}
          {!waveformLoaded && (<><CircularProgress />
          {percent}%</>) }
        </Box>
      )}
      <div
        id="waveform-timeline"
        className={styles.timeline_row}
        style={{ visibility: `${(waveformLoaded && !loadingWave) ? "visible" : "hidden"}` }}
      ></div>
      {" "}
      <div
        id="waveform"
        style={{ visibility: `${(waveformLoaded && !loadingWave) ? "visible" : "hidden"}` }}
      ></div>
    </div>
  );
};
