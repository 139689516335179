import React, { useContext, useEffect, useRef, useState } from "react";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/DecibelMeter.module.css";

const DecibelMeter = ({  type,regionIn,currentRegion }) => {
  const [decibelLevel, setDecibelLevel] = useState(-Infinity);
  const containerRef = useRef(null);
  const { deviceInput } = useContext(DataUser);

  const mediaStreamSourceRef = useRef(null);

  useEffect(() => {
    let audioContext = null;
    let analyser = null;
    let mediaStreamSource = null;

    const handleAudio = (stream) => {
      
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          // Crear un analizador para analizar el audio
          const analyser = audioContext.createAnalyser();
          // Crear una fuente de audio a partir del flujo de medios (stream)
          const mediaStreamSource = audioContext.createMediaStreamSource(stream);
          mediaStreamSourceRef.current = mediaStreamSource;
          // Conectar la fuente de audio al analizador
          mediaStreamSource.connect(analyser);
          // Configurar el tamaño del búfer del analizador
          const bufferLength = analyser.fftSize;
          // Crear un nuevo arreglo de tipo Float32Array para almacenar los datos del búfer
          const dataArray = new Float32Array(bufferLength);
          // Arreglo para almacenar los datos de la grabación en dBFS
          const updateDecibelLevel = () => {
            // Obtener los datos del búfer del analizador
            analyser.getFloatTimeDomainData(dataArray);
            // Encontrar el valor máximo absoluto en el búfer
            const max = Math.max(...dataArray.map(Math.abs));
            // Calcular el nivel en dBFS utilizando la fórmula 20 * log10(x / xMax)
            const decibelValue = 20 * Math.log10(max);
            // Almacenar el valor en el arreglo de datos de la grabación en dBFS
            setDecibelLevel(decibelValue);
            // Solicitar la siguiente animación de fotograma para actualizar continuamente el nivel de decibelios
            requestAnimationFrame(updateDecibelLevel);
          };
          // Iniciar la actualización del nivel de decibelios
          updateDecibelLevel();
    };

    if ((deviceInput && regionIn)) {
      if (deviceInput === "Same as system") {
        return;
      }
      //console.log("setiando microfono medidor ", deviceInput);
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: deviceInput.deviceId } })
        .then((stream) => {
          handleAudio(stream);
        })
        .catch((error) => console.error("Error accessing microphone:", error));
    } else {
    }

    return () => {
      if (mediaStreamSource) {
        mediaStreamSource.disconnect();
      }
      if (analyser) {
        analyser.disconnect();
      }
      if (audioContext && audioContext.state === "running") {
        audioContext.close();
      }
      if (mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect();
      }
    };
  }, [deviceInput,regionIn]);

  useEffect(() => {
    if (!regionIn) {
      if (mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect();
        mediaStreamSourceRef.current.mediaStream.getTracks().forEach((track) => track.stop());
        setDecibelLevel(-30);
      }
    }
  }, [regionIn,currentRegion]);
  
  const getBarColor = (decibel) => {
    if (decibel >= -18 && decibel <= -6) {
      return "green"; // Bajos
    } else if (decibel > -6 && decibel <= 0) {
      return "red"; // Altos
    }else if (decibel < -18 && decibel >= -60) {
      return "#FFA500"; // Altos
    }
    return "gray";
  };

  const getBarColorText = (decibel) => {
    if (decibel >= -18 && decibel <= -6) {
      return "white"; // Bajos
    } else if (decibel > -6 && decibel <= 0) {
      return "black"; // Altos
    }
  };

  const getBarHeight = (decibel) => {
    // console.log("decibel", decibel);
    const minDecibel = -60;
    const maxDecibel = 0;
    const minHeight = 0; // Valor mínimo de altura de la barra

    const containerHeight = containerRef.current
      ? containerRef.current.clientHeight
      : 0;
    const maxHeight = containerHeight - minHeight; // Restar el valor mínimo para evitar que la barra se desborde

    const normalizedDecibel = Math.max(
      minDecibel,
      Math.min(maxDecibel, decibel)
    );
    const percentage =
      (normalizedDecibel - minDecibel) / (maxDecibel - minDecibel);
    let height = Math.round(percentage * maxHeight + minHeight);

    // // Asegurarse de que la altura no supere el alto del contenedor
    // height = Math.min(height, containerHeight);

    return height;
  };
  const getBarWidth = (decibel) => {
    const minDecibel = -60;
    const maxDecibel = 0;
    const minWidth = 20;

    const containerWidth = containerRef.current
      ? containerRef.current.clientWidth
      : 0;
    const maxWidth = containerWidth - minWidth;

    const normalizedDecibel = Math.max(
      minDecibel,
      Math.min(maxDecibel, decibel)
    );
    const percentage =
      (normalizedDecibel - minDecibel) / (maxDecibel - minDecibel);
    const width = Math.round(percentage * maxWidth + minWidth);

    return width;
  };

  return (
    <>
      {(type === "horizontal" && regionIn) ? (
        <div aria-hidden="true" ref={containerRef} className={styles.container_db_horizontal}>
          <div
            className={styles.bar_db_horizontal}
            style={{
              width: `${getBarWidth(decibelLevel)}px`,
              height: "20px",
              backgroundColor: getBarColor(decibelLevel),
            }}
          ></div>
          <div className={styles.label_db_horizontal}>
            <span>-30</span>
            <span>-20</span>
            <span>-10</span>
            <span>0</span>
          </div>
        </div>
      ) : (
        <>
        {type === "vertical" && (
        <>
          <div ref={containerRef} aria-label={"Decibel Meter"} aria-hidden="true" className={styles.container_db} style={{marginLeft:'10px'}} >
            <div
              className={styles.bar_db}
              style={{
                width: "40px",
                height: `${getBarHeight(decibelLevel)}px`,
                backgroundColor: getBarColor(decibelLevel),
              }}
            ></div>
            
          {/*  <p tabIndex={-1} className={styles.label_db}>
              {(decibelLevel.isNaN || decibelLevel === -Infinity) ? "" : decibelLevel.toFixed(0)}</p>*/}
          </div>
          {/* <div ref={containerRef} aria-label={"Decibel Meter"} aria-hidden="true" className={styles.container_db}>
            <div
              className={styles.bar_db}
              style={{
                width: "40px",
                height: `${getBarHeight(decibelLevel)}px`,
                backgroundColor: getBarColor(decibelLevel),
              }}
            ></div>
            
            <p tabIndex={-1} className={styles.label_db}>
              {(decibelLevel.isNaN || decibelLevel === -Infinity) ? "" : decibelLevel.toFixed(0)}</p>
          </div> */}
          <div aria-label={"Decibel Meter"} className={styles.label_scaleDB}>
            <span aria-hidden="true">0</span>
            <span aria-hidden="true">-6</span>
            <span aria-hidden="true">-12</span>
            <span aria-hidden="true">-18</span>
            <span aria-hidden="true">-24</span>
            <span aria-hidden="true">-30</span>
            <span aria-hidden="true">-36</span>
            <span aria-hidden="true">-42</span>
            <span aria-hidden="true">-48</span>
            <span aria-hidden="true">-54</span>
            <span aria-hidden="true">-dB</span>
          </div>
        </>
        )}
        </>
      )}
    </>
  );
};

export default DecibelMeter;