import React, { useContext } from "react";
import { Time } from "../components/Time";
import DecibelMeter from "../components/narrator/DecibelMeter";
import { NarratorTabs } from "../components/narrator/NarratorTabs";
import { NarratorVoiceOverTabs } from "../components/narrator/NarratorVoiceOverTabs";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataNarrator } from "../context/DataNarrator";
import { DataUser } from "../context/DataUser";
import styles from "../css/DescriptorActions.module.css";

export const NarratorActions = () => {
    const { currentRegion, resized  } = useContext(DataDescriptor);
    const { currentProject } = useContext(DataUser);
    const {regionIn} = useContext(DataNarrator);

    return (
        <div
            className={styles.container__descriptorActions}
            style={{width: resized ? "25%" : "40%"}}>

            {currentRegion ? (
                <>
                    <Time />
                    <div className={styles.container__descriptorActions__region}>
                        {(currentProject.service === "dubbing" || window.location.href.includes("narrator-dubbing")) ? (<NarratorVoiceOverTabs />) : (<NarratorTabs />)}
                        <DecibelMeter type='vertical' regionIn={regionIn} currentRegion={currentRegion}/>
                    </div>
                </>
            ) : (
                <div className={styles.select__segment} tabIndex={0}>
                    <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
                    <span>Select a Segment</span>
                </div>
            )}
        </div>
    );
};
