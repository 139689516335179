export const secondsToSubrip = (seconds) => {
  var hour = Math.floor(seconds / 3600);
  var minute = Math.floor((seconds % 3600) / 60);
  var second = Math.floor(seconds % 60);
  var millisecond = Math.floor((seconds % 1) * 1000);

  return (
    (hour > 9 ? hour : "0" + hour) +
    ":" +
    (minute > 9 ? minute : "0" + minute) +
    ":" +
    (second > 9 ? second : "0" + second) +
    "," +
    (millisecond > 99
      ? millisecond
      : millisecond > 9
      ? "0" + millisecond
      : "00" + millisecond)
  );
};
export const subripToSeconds = (subripTime) => {
  const [hours, minutes, seconds] = subripTime.split(":");
  const [secondsPart, millisecondsPart] = seconds.split(",");
  const totalSeconds =
    parseInt(hours, 10) * 3600 +
    parseInt(minutes, 10) * 60 +
    parseInt(secondsPart, 10) +
    parseInt(millisecondsPart, 10) / 1000;
  
  return totalSeconds;
};
