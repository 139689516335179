import { Button, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { deleteSegment } from "../../utils/regions/regions";

const ModalDelete = ({ open, setOpen }) => {
  
  const { currentRegion, setRegions, setCurrentRegion } = useContext(DataDescriptor);
  const [statusDelete, setStatusDelete] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleDeleteSegment = async () => {
    const result = await deleteSegment(currentRegion, setRegions, setCurrentRegion);
    //console.log(result);
    if (result) {
      //console.log("Segment deleted");
      setStatusDelete(true);
      setOpenToast(true);
    } else {
      //console.log("Error deleting segment");
      setStatusDelete(false);
      setOpenToast(true);
    }
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusDelete ? "success" : "error"}>
          {statusDelete ? " Segment deleted" : "Error deleting segment "}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          {"Would you really like to delete?"}
        </DialogTitle>

        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            sx={{ color: "white", backgroundColor: "#952937" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteSegment()}
            autoFocus
            sx={{ color: "white", backgroundColor: "#8EC36A" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDelete;
