import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import React, { useContext, useRef } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/TextFields.module.css";
import { updateDescriptionSegment } from "../../utils/descriptions/description";
import { handleRegionUpdate } from "../../utils/regions/regions";
import { BarActions } from "../toolbar/BarActions";

export const TextFields = () => {
  const {
    setComments,
    setDescription,
    description,
    comments,
    charactersCount,
    recommendCharacterMax,
    regions,
    currentRegion,
    startTime,
    endTime,
    setWordsCount,
    setDuration,
    setCharactersCount,
    setRecommendCharacterMin,
    setRecommendCharacterMax,
  } = useContext(DataDescriptor);

  const descriptionField=useRef();

  const checkDescription = (e) => {
    setDescription(e.target.value);
  };


  const handleTextAreaDescription = (e) => {
    setDescription(e.target.value);
    updateDescriptionSegment(
      currentRegion,
      e.target.value,
      startTime,
      endTime,
      setWordsCount,
      setDuration,      
      setCharactersCount,
      setRecommendCharacterMin,
      setRecommendCharacterMax
    );
  };

  const handleTextAreaComments = (e) => {
    setComments(e.target.value);
    handleRegionUpdate(
      currentRegion,
      e.target.value,
      "comments",
      regions,
      currentRegion,
      startTime,
      endTime,
      setWordsCount,
      setDuration,
      description,
      setCharactersCount,
      setRecommendCharacterMin,
      setRecommendCharacterMax
    );
  };

  return (
    <>
      <div className={styles.container__TextLabel}>
        <div className={styles.label__TextField}>
          <div className={styles.title__TextField}>
            <ArticleOutlinedIcon /> <h3>Description</h3>
          </div>
          <BarActions />
        </div>
        <div className={styles.container__descriptioTextFiel}>
          <textarea
            ref={descriptionField}
            placeholder="Enter a description"
            value={currentRegion.data?.description?.initial}
            disabled={currentRegion.data?.review?.approved ? true : false}
            onLoadStart={(e) => {
              checkDescription(e);
            }}
            onChange={(e) => {
              handleTextAreaDescription(e);
            }}
            className={styles.textArea_description}
          />
          <div
            className={styles.container__count}
            style={{
              color:
                charactersCount > Math.round(recommendCharacterMax)
                  ? "red"
                  : "green",
            }}
          >
            <span className={styles.span__characterCount}>
              {Math.round(recommendCharacterMax)-charactersCount}
            </span>
          </div>
        </div>
        <div className={styles.container__textCount}>
          {charactersCount > Math.round(recommendCharacterMax) && (
            <h4 style={{ color: "var(--orange)" }}>
              {" "}
              WARNING: You have exceeded the limit{" "}
              {Math.round(recommendCharacterMax)}
            </h4>
          )}
        </div>
      </div>
      <div className={styles.container__TextLabel}>
        <label className={styles.label__TextField}>
          {" "}
          <div className={styles.title__TextField}>
            <ChatBubbleOutlineOutlinedIcon /> <h3>Annotation</h3>
          </div>
        </label>

        <textarea
          placeholder="Enter an annotation"
          value={comments}
          disabled={currentRegion.data?.review?.approved ? true : false}
          onChange={(e) => handleTextAreaComments(e)}
          className={styles.textArea_description}
        />
      </div>
    </>
  );
};
