import { putScriptSegmentSign } from "./descriptions/description";
import { subripToSeconds } from "./time/time";

export const updateScriptSegmentSign = async (
  region,
  descripcion,
  startTime,
  endTime,
  setDuration,
) => {
  let start = subripToSeconds(
    `${startTime.hours}:${startTime.minutes}:${startTime.seconds},${startTime.milliseconds}`
  );
  let end = subripToSeconds(
    `${endTime.hours}:${endTime.minutes}:${endTime.seconds},${endTime.milliseconds}`
  );

  const data = {
    ...region.data,
    script:descripcion,    
  };

  region.update({
    start: region.start,
    end: region.end,
    data,
  });

  setDuration(end - start);  
  
  await putScriptSegmentSign(data, region.data.id);

  
};