export const validationTime = (
  name,
  value,
  startHH,
  startMM,
  startSS,
  startMS,
  endHH,
  endMM,
  endSS,
  endMS,
  minLengthRegion
) => {
  let startHHTime = undefined;
  let startMMTime = undefined;
  let startSSTime = undefined;
  let startMSTime = undefined;
  let endHHTime = undefined;
  let endMMTime = undefined;
  let endSSTime = undefined;
  let endMSTime = undefined;

  switch (name) {
    case "startHH":
      startHHTime = value;
      break;
    case "startMM":
      startMMTime = value;
      break;
    case "startSS":
      startSSTime = value;
      break;
    case "startMS":
      startMSTime = value;
      break;
    case "endHH":
      endHHTime = value;
      break;
    case "endMM":
      endMMTime = value;
      break;
    case "endSS":
      endSSTime = value;
      break;
    case "endMS":
      endMSTime = value;
      break;
    default:
      break;
  }

  let startHHState = startHHTime ?? startHH;
  let startMMState = startMMTime ?? startMM;
  let startSSState = startSSTime ?? startSS;
  let startMSState = startMSTime ?? startMS;
  let endHHState = endHHTime ?? endHH;
  let endMMState = endMMTime ?? endMM;
  let endSSState = endSSTime ?? endSS;
  let endMSState = endMSTime ?? endMS;

  let limitTime =
    startHHState < 0 ||
    startHHState > 23 ||
    startMMState < 0 ||
    startMMState > 59 ||
    startSSState < 0 ||
    startSSState > 59 ||
    startMSState < 0 ||
    startMSState > 999 ||
    endHHState < 0 ||
    endHHState > 23 ||
    endMMState < 0 ||
    endMMState > 59 ||
    endSSState < 0 ||
    endSSState > 59 ||
    endMSState < 0 ||
    endMSState > 999
      ? false
      : true;

  let conditionsTime =
    startHHState < endHHState ||
    (startHHState === endHHState && startMMState < endMMState) ||
    (startHHState === endHHState &&
      startMMState === endMMState &&
      startSSState < endSSState) ||
    (startHHState === endHHState &&
      startMMState === endMMState &&
      startSSState === endSSState &&
      startMSState <= endMSState);

  let durationMS =
    (endHHState - startHHState) * 60 * 60 * 1000 +
    (endMMState - startMMState) * 60 * 1000 +
    (endSSState - startSSState) * 1000 +
    (endMSState - startMSState);
// console.log(startSSState, endSSState, durationMS);
  // let validDuration = (durationMS >= 2000 && durationMS <=10000 ) ? true : false;
  let validDuration = (durationMS >= (minLengthRegion *1000) ) ? true : false;


  startHHTime = undefined;
  startMMTime = undefined;
  startSSTime = undefined;
  startMSTime = undefined;
  endHHTime = undefined;
  endMMTime = undefined;
  endSSTime = undefined;
  endMSTime = undefined;
  // //console.log(
  //   "limitTime",
  //   limitTime,
  //   "conditionsTime",
  //   conditionsTime,
  //   "validDuration",
  //   validDuration
  // );

  return limitTime && conditionsTime && validDuration;
};
