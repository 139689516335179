import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AlertTitle, Collapse, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { DataPM } from "../../context/DataPM";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/Form.module.css";
import {
  createProject,
  rolesAudioDescription,
  rolesAudioDescription_Dubbing,
  rolesSignLanguageCaption,
  rolesVoiceOver
} from "../../utils/dataProjectManager";
import {
  process
} from "../../utils/env";

import { compressVideo } from "../../utils/project/compressVideo";
import { FormAssignRoles } from "./FormAssignRoles";
import { FormBasicData } from "./FormBasicData";
import { FormUploadResources } from "./FormUploadResources";
const fileTypes = ["SRT"];
export const Form = () => {
  const { user, getProyects } = useContext(DataUser);
  const {
    isCreateUser,
    setIsCreateUser,
    handleChangeCaption,
    segments,
    setSegments,
    postSegments,
    setIsCreate,
    generateSegmentDubbing,
    captions,
    postSegmentsDubbing
  } = useContext(DataPM);
  const [nameProject, setNameProject] = useState("");
  const [typeOfService, setTypeOfService] = useState("");
  const [categoryProject, setCategoryProject] = useState({
    status: false,
    name: "",
  })
  const [clientProject, setClientProject] = useState({
    state: false,
    actions: [false, false],
  });
  const [rolesService, setRolesService] = useState([]);
  const [guidelines, setGuidelinesProject] = useState("");
  const [narrators, setNarrators] = useState(2);
  const [usersDescriptors, setUsersDescriptors] = useState([]);
  const [usersNarrators, setUsersNarrators] = useState([]);
  const [usersEditors, setUsersEditors] = useState([]);
  const [usersQualityControl, setUsersQualityControl] = useState([]);
  const [usersScriptAdapter, setUsersScriptAdapter] = useState([]);
  const [usersInterpreter, setUsersInterpreter] = useState([]);
  const [usersClient, setUsersClient] = useState([]);
  const [fileVideo, setFileVideo] = useState(null);
  const [fileAudio, setFileAudio] = useState(null);
  const [open, setOpen] = useState(false);
  const [userDescriptor, setUserDescriptor] = useState("");
  const [userNarrator, setUserNarrator] = useState("");
  const [userNarrators, setUserNarrators] = useState([]);
  const [userEditor, setUserEditor] = useState("");
  const [userQualityControl, setUserQualityControl] = useState("");
  const [userScriptAdapter, setUserScriptAdapter] = useState("");
  const [userInterpreter, setUserInterpreter] = useState("");
  const [userClient, setUserClient] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    start: "",
    end: "",
  });
  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);

  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");



  const [isDataProject, setIsDataProject] = useState(false);
  const [isResources, setIsResources] = useState(false);
  const [isSegments, setIsSegments] = useState(false);

  const [closeData, setCloseData] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const [porcentProgress, setPorcentProgress] = useState(0);

  const [durationVideo, setDurationVideo] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [lastSegment, setLastSegment] = useState({});
  const [switchCaptions, setSwitchCaptions] = useState(false)
  const [switchNeedExternalAudio, setSwitchNeedExternalAudio] = useState(false)


  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const getUsers = async (role) => {
    const data = await fetch(`${process.env.REACT_APP_URL}/api/users/${role}`);
    const dataRole = await data.json();
    dataRole.users.forEach((user) => {
      user.label = user?.email;
      user.value = user?.uid;
    });
    switch (role) {
      case "AD":
        setUsersDescriptors(dataRole.users);
        break;
      case "AN":
        setUsersNarrators(dataRole.users);
        break;
      case "AE":
        setUsersEditors(dataRole.users);
        break;
      case "QC":
        setUsersQualityControl(dataRole.users);
        break;
      case 'SCRIPT_ADAPTER':
        setUsersScriptAdapter(dataRole.users);
        break;
      case 'INTERPRETER':
        setUsersInterpreter(dataRole.users);
        break;
      case 'CLIENT':
        setUsersClient(dataRole.users);
        break
      default:
        break;
    }
  };

  const steps = [
    {
      label: 'Created Project',
      content: isDataProject ? `Sucefully Created Project ${nameProject}` : `Create Project ${nameProject}`,
    },
    {
      label: 'Upload Video',
      content: isResources ? `Sucefully Uploaded Video` : porcentProgress > 0 ? `Uploading ${fileVideo.name} ${porcentProgress}%` : `Upload Video to ${nameProject}`,
    },
    {
      label: 'Generate Regions to Audio Description',
      content: isSegments ? `Sucefully Generated Regions to Audio Description` : `Generating Regions to Audio Description`,
    },
  ];

  useEffect(() => {
    getUsers("AD");
    getUsers("AN");
    getUsers("AE");
    getUsers("QC");
    getUsers("SCRIPT_ADAPTER");
    getUsers("INTERPRETER");
    getUsers("CLIENT");
    setIsCreateUser(false);
  }, [isCreateUser]);

  const uploadResource = async (id, bucket) => {
    (async () => {
      const s3Client = new S3Client({
        region: "us-east-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        },
      });

      const format =  bucket === process.env.REACT_APP_BUCKET_VIDEOS ? 'mp4' : 'wav'
      const nameFile = `${id}.${format}`;

      let upload = new Upload({
        client: s3Client,
        params: {
          Bucket: bucket,
          Key: nameFile,
          Body: bucket === process.env.REACT_APP_BUCKET_VIDEOS ? fileVideo : fileAudio,
        },
      });

      upload.on("httpUploadProgress", (progress) => {
        setPorcentProgress(Math.round((progress.loaded * 100) / progress.total));
      });
      await upload.done().then((data) => {
        fetch(`${process.env.REACT_APP_URL}/${fileVideo.name}`)
        setPorcentProgress(0);
        setIsResources(true);
        handleNextStep();
        if (bucket === process.env.REACT_APP_BUCKET_VIDEOS) {
          const dataVideo = {
            video: `https://videos.all4voicing.com/${nameFile}`,           
          }
          if (!switchCaptions){
            dataVideo.audio = `https://container-audio-wav.s3.amazonaws.com/${nameFile.split('.')[0]}.wav`;
          }
          fetch(`${process.env.REACT_APP_URL}/api/projects/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataVideo),
          })
            .then((res) => {

              if (res.status) {
                if (typeOfService === "dubbing") {
                  postSegmentsDubbing(id, captions[0]);
                  postSegmentsDubbing(id, captions[1]);
                  setIsSegments(true);
                  if(fileSize>200)compressVideo(id)

                  getProyects();
                } else {
                  if (segments && switchCaptions) {
                    segments.length > 0 && postSegments(id, segments);
                    setIsSegments(true);
                    if(fileSize>200)compressVideo(id)
                    getProyects();
                  } else {
                    fetch(
                      `${process.env.REACT_APP_URL}/api/projects/pyannote/${id}/${nameFile}`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    ).then(response => response.json())
                      .then(result => {
                        if (result.errorMessage === undefined) {
                          setIsSegments(true);
                          setStatusSave("success");
                          setMessage(result.msg);
                          setOpenToast(true);
                          getProyects();
                        } else {
                          setStatusSave("error");
                          setMessage(`Comuniquese con soporte ${result.errorMessage}`);
                          setOpenToast(true);
                          //eliminar proyecto
                          fetch(`${process.env.REACT_APP_URL}/api/projects/${id}`, {
                            method: "DELETE",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((res) => {
                              setCloseData(false);
                            }
                            ).catch(err => {
                              console.error(err);
                            })
                        }
                      })
                      .catch(err => {
                        console.error(err);
                        getProyects();
                      })

                    setIsSegments(true);
                    setStatusSave("warning");
                    setMessage("The project is being processed");
                    setOpenToast(true);

                    getProyects();
                  }
                }
              }
            }
            )
            .catch((err) => {
              console.error(err);
            });
        }else if(bucket===process.env.REACT_APP_BUCKET_AUDIOS){
          fetch(`${process.env.REACT_APP_URL}/api/projects/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              audio: `https://container-audio-wav.s3.amazonaws.com/${nameFile}`,
            }),
          }).then((res) => {
          }).catch((err) => {
            console.error(err);
          });
          
        }
      });
    })();
  };

  const createProjectData = async () => {
    let users = [];
    let rol = {};
    if (typeOfService === "audio-description") {
      rol = {
        user_id: userDescriptor.uid,
        role: "AD",
      }
      users = [
        { user_id: user?.uid, role: "PM", active: true },
        { user_id: userDescriptor.uid, role: "AD", active: false },
        { user_id: userNarrator.uid, role: "AN", active: false },
        { user_id: '645c16f23cd56f726b29b3c8', role: "AE", active: false },
        { user_id: userQualityControl.uid, role: "QC", active: false },
      ];
      if (clientProject.status) {
        users.push({ user_id: userClient.uid, role: "CLIENT", active: false });
      }
      rol = {
        user_id: userDescriptor.uid,
        role: "AD",
      }
    }
    if (typeOfService === "sign-language-caption") {
      users = [
        { user_id: user?.uid, role: "PM", active: true },
        { user_id: userScriptAdapter.uid, role: "SCRIPT_ADAPTER", active: false },
        { user_id: userInterpreter.uid, role: "INTERPRETER", active: false },
        { user_id: '645c16f23cd56f726b29b3c8', role: "AE", active: false },
      ];
      rol = {
        user_id: userScriptAdapter.uid,
        role: "SCRIPT_ADAPTER",
      }
    }

    if (typeOfService === "dubbing") {
      users = [
        { user_id: user?.uid, role: "PM", active: true },
        { user_id: userQualityControl.uid, role: "QC", active: false },
      ]
      userNarrators.forEach((user) => {
        users.push({ user_id: user.uid, role: "AN_D", active: true });
      });

      users.push({ user_id: '645c16f23cd56f726b29b3c8', role: "AE", active: false })

      rol = {
        user_id: userNarrators[0].uid,
        role: "AN_D",
      }

    }
    const dataProject = categoryProject.status ? {
      name: nameProject,
      service: typeOfService,
      guideline: guidelines,
      category: categoryProject.name,
      users: users,
      rol: rol,
      start_date: selectedDates.start,
      end_date: selectedDates.end,
      client: [clientProject.actions[0], clientProject.actions[1]],
      needExternalAudio: switchNeedExternalAudio
    } : {
      name: nameProject,
      service: typeOfService,
      guideline: guidelines,
      users: users,
      rol: rol,
      start_date: selectedDates.start,
      end_date: selectedDates.end,
      client: [clientProject.actions[0], clientProject.actions[1]],
      needExternalAudio: switchNeedExternalAudio
    };

    const data = await fetch(`${process.env.REACT_APP_URL}/api/projects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(dataProject),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setStatusSave("success");
          setMessage("The project has been created successfully");
          setOpenToast(true);
        } else {
          setStatusSave("error");
          setMessage(result.msg);
          setOpenToast(true);
        }

        return result;
      })
      .catch((error) => { console.error("Error:", error); });
    const project = await data;

    return project;
  };
  useEffect(() => {
    if (
      (isDataProject && isResources && isSegments) ||
      (segments && isResources && isDataProject)
    ) {
      setLoading(false);

      setStatusSave("success");
      setMessage("The project has been created successfully");
      setOpenToast(true);
      setStepForm(1);

      setFileAudio(null);
      setFileVideo(null);
      setNameProject("");
      setTypeOfService("");
      setRolesService([]);
      setGuidelinesProject("");
      setUserDescriptor("");
      setUserNarrator("");
      setUserNarrators([]);
      setUserEditor("");
      setUserQualityControl("");
      setUserScriptAdapter("");
      setUserInterpreter("");
      setUserClient("");
      setSelectedDates({
        start: "",
        end: "",
      });
      setIsCreate(false);
    }
  }, [isDataProject, isResources, isSegments]);

  /**
   * @description Función que crea un proyecto con los datos y recursos
   * @returns {void}
   * @version 1.0.0
  */
  const createProjectWithDataAndResources = async () => {
    validateInputsUploadResources();
    if ((fileVideo && segments && switchCaptions) || (!switchCaptions && fileVideo)) {
      if (lastSegment?.end > durationVideo && typeOfService === "audio-description") {
        setStatusSave("error");
        setMessage("The duration of the video is less than the last segment");
        setOpenToast(true);
        throw new Error("The duration of the video is less than the last segment");
      }
      setLoading(true);
      setCloseData(true);
      const project = await createProjectData();
      if (project.status) {
        handleNext();
        const id = project.project._id;
        handleNextStep();
        setIsDataProject(true);
        if (id && fileVideo) await uploadResource(id, process.env.REACT_APP_BUCKET_VIDEOS);
        if (id && fileAudio) await uploadResource(id, process.env.REACT_APP_BUCKET_AUDIOS);
      } else {
        setLoading(false);
        setStatusSave("error");
        setMessage(project.msg);
        setOpenToast(true);
        throw new Error("Error al crear el proyecto");
      }
    }
  }

  const handleSetRole = (text, role, index = 0) => {
    switch (role) {
      case "Describer":
        setUserDescriptor(text);
        break;
      case "Narrator":
        setUserNarrator(text);
        break;
      case "Editor":
        setUserEditor(text);
        break;
      case "Quality Control":
        setUserQualityControl(text);
        break;
      case "Script Adapter":
        setUserScriptAdapter(text);
        break;
      case "Interpreter":
        setUserInterpreter(text);
        break;
      case "Client":
        setUserClient(text);
        break;
      case "Narrators":
        let narrators = [...userNarrators];
        narrators[index] = text;
        setUserNarrators(narrators);
        break;
      default:
        break;
    }
  };


  const getRoles = (role) => {
    switch (role) {
      case "Descriptor":
        return userDescriptor.label;
      case "Narrator":
        return userNarrator.label;
      case "Editor":
        return userEditor.label;
      case "Quality Control":
        return userQualityControl.label;
      case "Script Adapter":
        return userScriptAdapter.label;
      case "Interpreter":
        return userInterpreter.label;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = async (name, file, user = {}, index = 0) => {
    switch (name) {
      case "Video":
        setFileVideo(file);
        setFileSize( file.size / 1024 / 1024)

        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          setDurationVideo(duration);
        }
        video.src = URL.createObjectURL(file);
        video.remove();
        break;
      case "Audio":
        setFileAudio(file);
        break;
      case "Caption":
        const lastSegment = await handleChangeCaption(file);
        setLastSegment(lastSegment);
      case "CaptionMale":
        generateSegmentDubbing(file, user, index);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    let roles;

    switch (typeOfService) {
      case "audio-description":
        roles = rolesAudioDescription;
        if (!clientProject.status) {
          roles = rolesAudioDescription.filter((rol) => rol !== "Client");
        }
        break;
      case "sign-language-caption":
        roles = rolesSignLanguageCaption;
        break;
      case "dubbing":
        roles = rolesVoiceOver;
        break;
      default:
        roles = rolesAudioDescription_Dubbing;
    }
    setRolesService(roles);
  }, [typeOfService, clientProject.status]);


  const handleNext = () => {
    if (stepForm >= 1 && stepForm <= 4) {
      switch (stepForm) {
        case 1:
          validateInputsBasicData() && setStepForm(stepForm + 1);
          break;
        case 2:
          validateInputsAssignRoles() && setStepForm(stepForm + 1);
          break;
        case 3:
          validateInputsUploadResources() && setStepForm(stepForm + 1);
          break;
        case 4:
          setStepForm(stepForm + 1);
        default:
          break;
      }
    }
  };

  const handleBack = () => {
    if (stepForm > 1 && stepForm <= 4) {
      setStepForm(stepForm - 1);
    }
  };

  const validateInputsBasicData = () => {
    if (nameProject === "") {
      setStatusSave("error");
      setMessage("Should enter a project name");
      setOpenToast(true);
      return false;
    } else if (typeOfService === "") {
      setStatusSave("error");
      setMessage("Should select a service");
      setOpenToast(true);
      return false;
    } else if (selectedDates.start === "" || selectedDates.end === "") {
      setStatusSave("error");
      setMessage("Should select a end date");
      setOpenToast(true);
      return false;
    } else if (clientProject.status && (!clientProject.actions[0] && !clientProject.actions[1])) {
      setStatusSave("error");
      setMessage("Should select a action to client");
      setOpenToast(true);
      return false;
    } else if (categoryProject.status && (categoryProject.name === "" || categoryProject.name === null)) {
      setStatusSave("error");
      setMessage("Should select a category");
      setOpenToast(true);
      return false;
    } else {
      setStatusSave("success");

      setMessage("Data saved successfully");
      setOpenToast(true);
      return true;
    }
  };
  const validateInputsAssignRoles = () => {
    //si el tipo de servicio es audio description
    if (typeOfService === "audio-description") {
      if (userDescriptor === "") {
        setStatusSave("error");
        setMessage("Should select a descriptor");
        setOpenToast(true);
        return false;
      } else if (userNarrator === "") {
        setStatusSave("error");
        setMessage("Should select a narrator");
        setOpenToast(true);
        return false;
      }
      // else if (userEditor === "") {
      //   setStatusSave("error");
      //   setMessage("Should select a editor");
      //   setOpenToast(true);
      //   return false;
      // }
      else if (userQualityControl === "") {
        setStatusSave("error");
        setMessage("Should select a quality control");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    } else if (typeOfService === "sign-language-caption") {
      if (userScriptAdapter === "") {
        setStatusSave("error");
        setMessage("Should select a script adapter");
        setOpenToast(true);
        return false;
      } else if (userInterpreter === "") {
        setStatusSave("error");
        setMessage("Should select a interpreter");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    } else if (typeOfService === "dubbing") {
      if (userNarrators.length === 0 && captions.length === 0) {
        setStatusSave("error");
        setMessage("Should select a narrator and upload a caption");
        setOpenToast(true);
        return false;
      } else if (userQualityControl === "") {
        setStatusSave("error");
        setMessage("Should select a quality control");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    }
  };
  const validateInputsUploadResources = () => {
    if (typeOfService === "audio-description" && !switchCaptions) {
      if (fileVideo === "" || !fileVideo) {
        setStatusSave("error");
        setMessage("You should select a video");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    }
    else if (typeOfService === "audio-description" && switchCaptions) {
      if (fileVideo === "" || !fileVideo) {
        setStatusSave("error");
        setMessage("You should select a video");
        setOpenToast(true);
        return false;
      // } else if (fileAudio === "" || !fileAudio) {
      //   setStatusSave("error");
      //   setMessage("You should select a audio");
      //   setOpenToast(true);
      //   return false;
      } else if (segments === null) {
        setStatusSave("error");
        setMessage("You should upload a caption");
        setOpenToast(true);
        return false;
      }else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }

    } else if (typeOfService === "dubbing") {
      if (fileVideo === "") {
        setStatusSave("error");
        setMessage("You should select a video");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    } else if (typeOfService === "sign-language-caption") {
      if (fileVideo === "") {
        setStatusSave("error");
        setMessage("You should select a video");
        setOpenToast(true);
        return false;
      } else {
        setStatusSave("success");
        setMessage("Data saved successfully");
        setOpenToast(true);
        return true;
      }
    };
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusSave === "success" ? "success" : "error"}>
          <AlertTitle>
            {statusSave === "success" ? "success" : "error"}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>

      <button
        onClick={handleBack}
        className={styles.btn_Arrow}
        style={{ visibility: stepForm === 1 ? "hidden" : "visible" }}
      >
        <ArrowBackIosNewIcon
          sx={{
            fontSize: 40,
          }}
        />
      </button>
      <section className={styles.container__forms}>
        {stepForm === 1 && (
          <FormBasicData
            createProject={createProject}
            nameProject={nameProject}
            setNameProject={setNameProject}
            setSelectedDates={setSelectedDates}
            typeOfService={typeOfService}
            setTypeOfService={setTypeOfService}
            selectedDates={selectedDates}
            setCategoryProject={setCategoryProject}
            setClientProject={setClientProject}
            clientProject={clientProject}
            categoryProject={categoryProject}
          />
        )}
        {typeOfService && stepForm === 2 && (
          <FormAssignRoles
            typeOfService={typeOfService}
            rolesService={rolesService}
            narrators={narrators}
            usersNarrators={usersNarrators}
            handleChange={handleChange}
            fileTypes={fileTypes}
            setNarrators={setNarrators}
            handleSetRole={handleSetRole}
            usersDescriptors={usersDescriptors}
            usersScriptAdapters={usersScriptAdapter}
            usersInterpreters={usersInterpreter}
            usersClient={usersClient}
            usersEditors={usersEditors}
            usersQualityControl={usersQualityControl}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            getRoles={getRoles}
            open={open}
            clientProject={clientProject}
          />
        )}
        {typeOfService && stepForm === 3 && (
          <FormUploadResources
            typeOfService={typeOfService}
            handleChange={handleChange}
            guidelines={guidelines}
            switchCaptions={switchCaptions}
            setSwitchCaptions={setSwitchCaptions}
            setSwitchNeedExternalAudio={setSwitchNeedExternalAudio}
            switchNeedExternalAudio={switchNeedExternalAudio}
            setGuidelinesProject={setGuidelinesProject}
            createProjectWithDataAndResources={
              createProjectWithDataAndResources
            }
            createProjectData={createProjectData}
            open={open}
            handleClose={handleClose}
            setFileVideo={setFileVideo}
            fileVideo = {fileVideo}
            setFileAudio={setFileAudio}
            setSegments={setSegments}

          />
        )}
        {typeOfService && stepForm === 4 && (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel

                  sx={{ mb: 3, color: 'white', '& .MuiStepLabel-label': { color: 'white' }, '& .MuiStepIcon-root.MuiStepIcon-active': { color: 'white' }, '& .MuiStepIcon-root.MuiStepIcon-completed': { color: 'white' } }}
                >
                  {step.label}
                  <div>
                    {step?.content && (
                      <Typography sx={{ color: 'white' }}>{step?.content ? step?.content : null}</Typography>
                    )}

                  </div>

                </StepLabel>
                <StepContent>
                  <Collapse in={activeStep === index} unmountOnExit={false}>
                  </Collapse>
                </StepContent>
              </Step>
            ))}
          </Stepper>


        )}
      </section>
      {stepForm >= 3 ? (
        <button
          className={styles.btn_Arrow}
          style={{ visibility: "hidden" }}
        ></button>
      ) : (
        <button onClick={handleNext} className={styles.btn_Arrow}>
          <ArrowForwardIosIcon
            sx={{
              fontSize: 40,
            }}
          />
        </button>
      )}


    </>
  );
};
