import { Collapse, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import { useState } from "react";
import CardResources from '../components/voice-over/CardResources';
import styles from '../css/SegmentationVoiceOver.module.css';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const steps = [
    {
        label: 'Upload Video File',
        description: `This is the first step you need to do to start the segmentation process. 
        You need to upload the video file that you want to segment.`,
    },
    {
        label: 'Upload Captions File',
        description: `This is the second step you need to do to start the segmentation process.
        You need to upload the captions file that you want to segment.`,
    },
    {
        label: 'Init Machine Learning Process',
        description: `This is the third step you need to do to start the segmentation process.`
    },
    {
        label: 'Segmentation Process Finished',
        description: `This is the last step you need to do to start the segmentation process.`
    }
];

const ResourcesVoiceOver = ({video}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const setStep= (step) => {
        setActiveStep(step);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleLoading = (state) => { setLoading(state) };
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.container}>
                <Box className={styles.container__card} >
                    <CardResources handleNext={handleNext} setStep={setStep} activeStep={activeStep} handleLoading={handleLoading} loading={loading} video={video}/>
                </Box>
                <div className={styles.container__stepper}>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel>
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Collapse in={activeStep === index} unmountOnExit={false}>
                                        </Collapse>   
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>

        </ThemeProvider>

    )
}

export default ResourcesVoiceOver