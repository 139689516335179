import React, { useContext } from "react";
import { Send } from "../components/Send";
import { AudioRecorderNarator } from "../components/narrator/AudioRecorderNarator";
import { Timeline } from "../components/timeline/Timeline";
import { VerticalActions } from "../components/timeline/VerticalActions";
import { DataDescriptor } from "../context/DataDescriptor";
import styles1 from "../css/Descriptor.module.css";

const TimelineContainer = () => {

  const { fullVideo, waveformLoaded, currentRegion } = useContext(DataDescriptor);

  return (
    <>
      {window.location.href.includes("narrator") && waveformLoaded && currentRegion ? (
        <AudioRecorderNarator tabIndex={200} />
      ) : null}
      <div
        className={styles1.timeline}
        style={{
          display: fullVideo ? "none" : "flex",
        }}
      >
        {waveformLoaded && (
          <VerticalActions className={styles1.btn_Send} />
        )}

        <Timeline aria-hidden="true" className={styles1.timeline_row} />
        {waveformLoaded && (
          <Send className={styles1.btn_Send} />
        )}
      </div>
    </>
  );
};

export default TimelineContainer;
