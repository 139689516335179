import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DataPM } from '../../../context/DataPM';
import { DataUser } from '../../../context/DataUser';
import styles from "../../../css/Form.module.css";
import { rolesVoiceOver } from "../../../utils/dataProjectManager";
import { updateProject } from '../../../utils/project/CRUD';
import { updateManyRegionsVoiceOver } from '../../../utils/regions/updateManyRegions';

const FormVoiceOver = ({ project, setAlert, updateDataVoiceOver, setLoading, setUpdateDataVoiceOver,hasVoiceOver }) => {
  const { getProyects } = useContext(DataUser);
  const [narrators, setNarrators] = useState(2);
  const { generateSegmentsVoiceOver, postSegmentsDubbing } = useContext(DataPM);
  const fileTypes = ["SRT"];
  const { register,setValue, handleSubmit, control, watch, formState: { errors, isDirty, dirtyFields }, } = useForm({
    defaultValues: {
      users: [
        { user_id: hasVoiceOver ? project.users.filter((user) => user.role == "AN_D")[0]?.user_id : null, file: null },
        { user_id: hasVoiceOver ? project.users.filter((user) => user.role == "AN_D")[1]?.user_id : null, file: null },
      ]
    }
  })


  useEffect(() => {
    if (updateDataVoiceOver) executeSubmit()
  }, [updateDataVoiceOver]);


  const executeSubmit = async () => await handleSubmit(onSubmit)()

  useEffect(() => {
    getUsers("AN");
    getUsers("CLIENT");
  }, []);
  const [usersNarrators, setUsersNarrators] = useState([]);


  const getUsers = async (role) => {
    const data = await fetch(`${process.env.REACT_APP_URL}/api/users/${role}`);
    const dataRole = await data.json();
    dataRole.users.forEach((user) => {
      user.label = user?.email;
      user.value = user?.uid;
    });
    switch (role) {
      case "AN":
        setUsersNarrators(dataRole.users.filter((user) => user.uid !== project.users.find((user) => user.role == "AN")?.user_id?._id))
        break;
      default:
        break;
    }
  };

  const valueRole = (role) => {
    switch (role) {

      case "Quality Control AD":
        return {
          role: 'QC',
          user_id: project.users[4]?.user_id._id,
        }
      case "Quality Control AN":
        return {
          role: 'QC2',
          user_id: project.users[4]?.user_id._id,
        }

    }
  };


const validateData = (data) => {
  if(hasVoiceOver) {
    console.log("data", data);
    const user1= data?.users[0]?.user_id?.uid? data?.users[0]?.user_id?.uid : data?.users[0]?.user_id?._id
    const user2= data?.users[1]?.user_id?.uid? data?.users[1]?.user_id?.uid : data?.users[1]?.user_id?._id

    const user1Project = project.users.filter((user) => user.role == "AN_D")[0].user_id
    const user2Project = project.users.filter((user) => user.role == "AN_D")[1]?.user_id // Made optional
    if( user1 === user1Project && user2 === user2Project) {
      setAlert({ open: true, message: 'Please change the narrator', type: 'error' })
      return false
    }
    if (!user1) { // Removed check for user2
      setAlert({ open: true, message: 'Please fill all the fields', type: 'error' })
      return false
    }
    if (user1 === user2) {
      setAlert({ open: true, message: 'Narrators must be different', type: 'error' })
      return false
    }
    return true
  }
  if (!data?.users[0]?.file || !data?.users[0]?.user_id) { // Removed checks for user2
    setAlert({ open: true, message: 'Please fill all the fields', type: 'error' })
    return false
  }
  if (data?.users[0]?.user_id?.uid === data?.users[1]?.user_id?.uid) {
    setAlert({ open: true, message: 'Narrators must be different', type: 'error' })
    return false
  }
  if (project.users.find((user) => user.role == "AN_D")) {
    setAlert({ open: true, message: 'The project has voice over', type: 'error' })
    return false
  }
  return true
}

  const updateSegmentsVoiceOverProject = async (prevUsers,usersVoiceOver) => {
    console.log("prevUsers", prevUsers);
    console.log("usersVoiceOver", usersVoiceOver);
    try {
      const segmentsVoiceOver1 = project.segmentsdubbings.filter((segment) => segment.user == prevUsers[0].user_id._id)
      const segmentsVoiceOver2 = project.segmentsdubbings.filter((segment) => segment.user == prevUsers[1].user_id._id)

     

      //change user id segments voice over
      segmentsVoiceOver1.forEach((segment) => segment.user = usersVoiceOver[0].user_id)
      segmentsVoiceOver2.forEach((segment) => segment.user = usersVoiceOver[1].user_id)

      const segments = [...segmentsVoiceOver1, ...segmentsVoiceOver2]
      await updateManyRegionsVoiceOver(project._id, segments)
    } catch (error) {
      console.error("error", error);
      setAlert({ open: true, message: 'Error updating segments voice over', type: 'error' });
      reject(error)
  }
    
  }

  const updateDataVoiceOverProject = async(data) => {
    try {
      const prevUsers = project.users.filter((user) => user.role == "AN_D")
      const user1 = data.users[0].user_id.uid? data.users[0].user_id.uid : data.users[0].user_id._id
      const user2 = data.users[1].user_id.uid? data.users[1].user_id.uid : data.users[1].user_id._id
      const usersVoiceOver = [{ user_id: user1, role: "AN_D", active: true }, { user_id: user2, role: "AN_D", active: true }]
      const usersProject = project.users.filter((user) => user.role !== "AN_D")
      usersProject.push(...usersVoiceOver)
      const dataProject = { users: usersProject, _id: project._id }
      await updateProject(dataProject);
      await updateSegmentsVoiceOverProject(prevUsers, usersVoiceOver)
      setAlert({ open: true, message: 'Project updated successfully', type: 'success' });
      await getProyects(1, true);
    } catch (error) {
      console.log("error", error);
      setAlert({ open: true, message: 'Error updating project', type: 'error' });
      
    }

  }

  const onSubmit = async (data) => {
    if (!validateData(data)) {
      setUpdateDataVoiceOver(false)
      return
    }
    if (hasVoiceOver) {
      await updateDataVoiceOverProject(data)
      setUpdateDataVoiceOver(false)
      return
    }
    try {
      setLoading(true);

      const users = data.users.map((user) => { return { user_id: user.user_id } })
      const usersVoiceOver = [{ user_id: data.users[0].user_id.uid, role: "AN_D", active: true }]
      if(data.users[1].user_id) usersVoiceOver.push({ user_id: data.users[1].user_id.uid, role: "AN_D", active: true } )
        
      const files = [data.users[0].file, data.users[1].file]
      const segments = await generateSegmentsVoiceOver(users, files)
      const usersProject = [...project.users, ...usersVoiceOver]
      const dataProject = { users: usersProject, _id: project._id }
      //unir los segmentos de los narradores en un array
      const segmentsJoined = [...segments[0]]
      if (segments[1]) segmentsJoined.push(...segments[1])
      const segmentResult = await postSegmentsDubbing(project._id, segmentsJoined);

      if (segmentResult) {
        await updateProject(dataProject);
        setAlert({ open: true, message: 'Project updated successfully', type: 'success' });
        await getProyects(1, true);
      } else {
        throw new Error('Error updating segments dubbing');
      }

    } catch (error) {
      console.error(error); // Aquí puedes manejar el error de forma más específica si es necesario
      setAlert({ open: true, message: 'Error updating project', type: 'error' });
      setUpdateDataVoiceOver(false)

    } finally {
      setLoading(false);
      setUpdateDataVoiceOver(false);
    }


  }

  const getLabelRole = (role) => {
    switch (role) {
      case "AD":
        return 'Describer';
      case "AN":
        return 'Narrator';
      case "QC":
        return 'Quality Control AD';
      case 'SCRIPT_ADAPTER':
        return 'Script Adapter';
      case 'INTERPRETER':
        return 'Interpreter';
      case 'CLIENT':
        return 'Client';
      default:
        break;
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '50%', margin: '1.5rem' }}>
      {rolesVoiceOver.map((rol, index) => (
        rol === "Narrators" && (
          <div key={index} >
            {Array.from({ length: narrators }).map((_, index) => (
              <div key={index}>
                <label className={styles.label} >
                  Audio Narrator {index + 1}
                </label>
                <Controller
                  name={`users[${index}].user_id`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      style={{ marginBottom: '.5rem' }}
                      key={index}
                      value={value?.email}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id;
                      }}
                      onChange={(event, newValue) => onChange(newValue)}
                      options={usersNarrators}
                      renderInput={(params) => <TextField {...params} placeholder="Select Narrator" />}
                    />
                    
                  )}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                  <Controller
                    name={`users[${index}].file`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FileUploader
                        name={`users[${index}].file`}
                        handleChange={(e) => onChange(e)}
                        types={fileTypes}
                        label="SRT"
                        multiple={false}
                        style={{
                          border: "2px dashed #ccc",
                          padding: "20px",
                          textAlign: "center",
                        }}
                        disabled={hasVoiceOver}
                      />
                    )} />
                  <Link to={"/segmentation-voice-over/" + project._id + ".mp4"} target="_blank" className={styles.linkSegmentVoiceOver}><SettingsSuggestIcon className={styles.iconSegmentVoiceOver} /></Link>
                </div>
              </div>
            ))}
          </div>

        )
      ))}
      {/* <Typography
        variant="h6" sx={{ color: "white" }}>
        Project Actual Rol - {project.rol.role}
      </Typography>
      <Controller
        name="rol"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Rol"
          >
            {!project.rol.role.includes('QC') && (<MenuItem value={(project.rol.role)}>{getLabelRole(project.rol.role)}</MenuItem>)}
            {rolesEditProject.map((rol, index) => <MenuItem key={index} value={valueRole(rol).role}>{rol}</MenuItem>)}
          </Select>
        )}
      /> */}
      {/* <button type="submit" className={styles.btn}>Save</button> */}
    </form>



  );
};

export default FormVoiceOver
