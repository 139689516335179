import { editAnnotation, showNote } from "../descriptions/description";
import { putActualTime, secondsToSubrip } from "../time/time";

export const handlePreviousRegion = (
  regions,
  websurfer,
  setTimeSubRip,
  currentRegion,
  setActualTime,
  setCurrentRegion,
  setStartHH,
  setStartMM,
  setStartSS,
  setStartMS,
  setEndHH,
  setEndMM,
  setEndSS,
  setEndMS,
  setDuration,
  user,
  setDescription,
  setIsActiveAudio,
  setCaption,
  wavesurfer,
  setIsPlaying,
  viewAllSegments, currentProject
) => {
  setIsActiveAudio(true)
  let keysRegions = Object.keys(regions);
  let regionsSorter = keysRegions.sort((a, b) => regions[a].start - regions[b].start);
  let regionsSorterReverse = regionsSorter.reverse();
  let regionFound
  if (!viewAllSegments) {
    regionFound = regionsSorterReverse.find((region) => regions[region].start < websurfer.current.getCurrentTime() && regions[region] !== currentRegion);
  } else {
    if (window.location.href.includes('audio-descriptor')) {
      regionFound = regionsSorterReverse.find((region) => {
        return (regions[region].start < websurfer.current.getCurrentTime() && !regions[region].data.review.approved && regions[region] !== currentRegion && (regions[region].data.description?.initial === regions[region].data.description.preview || regions[region].data.description?.initial === ''))
      });
    } else if (window.location.href.includes('narrator')) {
      regionFound = regionsSorterReverse.find((region) => {
        return (regions[region].start < websurfer.current.getCurrentTime() && !regions[region].data.review.approved && regions[region] !== currentRegion && (regions[region].data.audiorecord?.initial === regions[region].data.audiorecord.preview || regions[region].data.audiorecord?.initial === ''))
      });
    } else if (window.location.href.includes('quality-control')) {
      regionFound = regionsSorterReverse.find((region) => {
        return (regions[region].start < websurfer.current.getCurrentTime() && regions[region] !== currentRegion && ((regions[region].data.review.comment[0] === regions[region].data.review.comment[1]) && !regions[region].data.review.approved))
      });
    }
  }

  if (regionFound) {
    editAnnotation(user,
      regions[regionFound],
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      setDescription);
    putActualTime(
      (regions[regionFound].start + 0.2) / websurfer.current.getDuration(),
      websurfer,
      setActualTime, currentProject
    );
    setTimeSubRip(secondsToSubrip(regions[regionFound].start + 0.2));

    websurfer.current.play(
      regions[regionFound].start,
      regions[regionFound].end
    );
    const checkIfPlaying = async () => {
      if (await websurfer.current?.isPlaying() || await wavesurfer.current?.isPlaying()) {
        websurfer.current.pause();
        wavesurfer?.current?.pause();
        if (currentProject.rol?.role === "QC2" || currentProject.rol?.role === "CLIENT2") {
          const video = document.querySelector("video");
          video.pause();
        }
        setIsPlaying(false)
        showNote(regions[regionFound], setCaption)
      } else {
        setTimeout(async () => {
          await checkIfPlaying();
        }, 200);
      }
    }
    checkIfPlaying();
  }
};

export const handleNextRegion = (
  regions,
  websurfer,
  setTimeSubRip,
  currentRegion,
  setActualTime,
  setCurrentRegion,
  setStartHH,
  setStartMM,
  setStartSS,
  setStartMS,
  setEndHH,
  setEndMM,
  setEndSS,
  setEndMS,
  setDuration,
  user,
  setDescription,
  setIsActiveAudio,
  wavesurfer,
  setIsPlaying,
  viewAllSegments, currentProject
) => {
  setIsActiveAudio(true);
  let keysRegions = Object.keys(regions);
  let regionsSorter = keysRegions.sort((a, b) => regions[a].start - regions[b].start);
  let regionFound
  if (!viewAllSegments) {
    regionFound = regionsSorter.find((region) => (regions[region].start > websurfer.current.getCurrentTime() && regions[region] !== currentRegion));
  } else {
    if (window.location.href.includes('audio-descriptor')) {
      regionFound = regionsSorter.find((region) => (regions[region].start > websurfer.current.getCurrentTime() && !regions[region].data.review.approved && regions[region] !== currentRegion && (regions[region].data.description?.initial === regions[region].data.description.preview || regions[region].data.description?.initial === '')))
    } else if (window.location.href.includes('narrator')) {
      regionFound = regionsSorter.find((region) => (regions[region].start > websurfer.current.getCurrentTime() && !regions[region].data.review.approved && regions[region] !== currentRegion && (regions[region].data.audiorecord?.initial === regions[region].data.audiorecord.preview || regions[region].data.audiorecord?.initial === '')))
    } else if (window.location.href.includes('quality-control')) {
      regionFound = regionsSorter.find((region) => (regions[region].start > websurfer.current.getCurrentTime() && regions[region] !== currentRegion && ((regions[region].data.review.comment[0] === regions[region].data.review.comment[1]) && !regions[region].data.review.approved)))
    }
  }
  if (regionFound) {
    editAnnotation(
      user,
      regions[regionFound],
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      setDescription
    );
    putActualTime((regions[regionFound].start + 0.2) / websurfer.current.getDuration(), websurfer, setActualTime, currentProject);
    setTimeSubRip(secondsToSubrip(regions[regionFound].start + 0.5));

    websurfer.current.play(regions[regionFound].start, regions[regionFound].end);

    const checkIfPlaying = async () => {
      if (await websurfer.current?.isPlaying() || await wavesurfer.current?.isPlaying()) {
        websurfer.current.pause();
        wavesurfer?.current?.pause();
        if(currentProject.rol?.role === "QC2"||currentProject.rol?.role === "CLIENT2"){
          const video = document.querySelector("video");
          video.pause();
        }
        setIsPlaying(false)
      } else {
        setTimeout(async () => {
          await checkIfPlaying();
        }, 200);
      }
    }
    checkIfPlaying();
  }
};

export const isSegmentIntersecting = (segment1, segment2) => {
  return (
    (segment1.start <= segment2.start && segment1.end >= segment2.start) ||
    (segment2.start <= segment1.start && segment2.end >= segment1.start)
  );
}

export const addSegment = async (
  websurfer,
  regions,
  currentProject,
  setRegions
) => {
  let currentTime = websurfer.current.getCurrentTime();
  const data = {
    start: currentTime,
    end: currentTime + 1, // duración del segmento en segundos
    color: "#12B4B899",
    drag: false, // permitir arrastrar el segmento
    resize: true, // permitir cambiar el tamaño del segmento
  };
  
  const isIntersecting = regions.some((region) =>
    isSegmentIntersecting(data, region)
  );

  if (isIntersecting) {
    console.error("El nuevo segmento se intersecta con otro segmento existente.");
    return false;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    //console.log("responseData", responseData);
    const { segment } = responseData;
    if (segment.start) {
      const region = websurfer.current.addRegion({
        start: currentTime,
        end: currentTime + 1, // duración del segmento en segundos
        color: "#12B4B899",
        drag: false, // permitir arrastrar el segmento
        resize: true, // permitir cambiar el tamaño del segmento
        data: {
          id: segment._id,
          description: segment.description,
          audiorecord: segment.audiorecord,
          comment: segment.comments,
          start: segment.start,
          end: segment.end,
          review: segment.review,
        },
      });
      setRegions((oldRegions) => [...oldRegions, region]);
    } else {
      //console.log("error al crear segmento");
      return false;
    }
    return responseData;
  } catch (error) {
    //console.log(error);
    return false;
  }
};

export const addSegmentCut = async (region, idProject) => {
  const { start, end } = region;
  const data = {
    start: start,
    end: end,
    color: "#12B4B899",
    drag: false, // permitir arrastrar el segmento
    resize: true, // permitir cambiar el tamaño del segmento
  };
  try {
    const resp = await fetch(`${process.env.REACT_APP_URL}/api/segments/${idProject}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
    return resp.segment;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const addSegmentSign = async (
  websurfer,
  regions,
  currentProject,
  setRegions
) => {
  
  let currentTime = websurfer.current.getCurrentTime();
  const regionExists = regions.some((region) => region.start <= currentTime && region.end >= currentTime);

  if (regionExists) {
    console.error("Ya existe una region en este tiempo");
    return false;
  }

  const data = {
    start: currentTime,
    end: currentTime + 1, // duración del segmento en segundos
    color: "#12B4B899",
    drag: false, // permitir arrastrar el segmento
    resize: true, // permitir cambiar el tamaño del segmento
  };

  try {
    //console.log("currentProject", currentProject);
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsSign/${currentProject._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    //console.log("responseData", responseData);
    const { segmentsSign } = responseData;
    if (segmentsSign.start) {
      const region = websurfer.current.addRegion({
        start: currentTime,
        end: currentTime + 1,
        color: "#12B4B899",
        drag: false,
        resize: true,
        data: {
          id: segmentsSign._id,
          description: segmentsSign.description,
          start: segmentsSign.start,
          end: segmentsSign.end,
        },
      });
      setRegions((oldRegions) => [...oldRegions, region]);
    } else {
      console.error("error al crear segmento");
      return false;
    }
    return responseData;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const loopSegment = (region) => {
  region.update({
    loop: !region.loop,
  });
};
