import { Autocomplete, MenuItem, OutlinedInput, Select, Switch, TextField, Typography, createFilterOptions } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from "../../../css/Form.module.css";
import { createProject } from "../../../utils/dataProjectManager";
import { getAllCategories } from "../../../utils/project/CRUD";

const filter = createFilterOptions();

const FormDataProject = ({ project, setFormData, updateData }) => {
    const [categories, setCategories] = useState([]);
    const { register, handleSubmit, control, watch, formState: { errors, isDirty, dirtyFields }, setValue } = useForm({
        defaultValues: {
            ...project,
            category: project?.category?.name || '',
            has_category: project?.category ? true : false,
            has_client: true, // Valor por defecto siempre activado
            client: project?.client || [false, false], // Valores de los switches de Audio Description y Audio Narration
            completed: project?.stage.state === 'completed' ? true : false,
        }
    });

    const watchHasClient = watch('has_client');
    const watchClientSwitches = watch('client', [false, false]);

    useEffect(() => {
        if (updateData) executeSubmit();
    }, [updateData]);

    const executeSubmit = async () => await handleSubmit(onSubmit)();

    const onSubmit = async (data) => {
        // Verifica si el campo 'client' fue modificado
        const clientChanged = JSON.stringify(data.client) !== JSON.stringify(project.client);
      
        if (clientChanged) {
          setFormData({ isDirty: true, data: { ...data } }); // Forzar el marcado de cambios
        }
      
        if (isDirty || clientChanged) {
          if (data.name === project.name) delete data.name;
          
          if (dirtyFields.category) {
            data.category = dirtyFields.category ? (data.category?.inputValue ? data.category?.inputValue : data.category?.name) : null;
          }
      
          if (!data.has_category) delete data.category;
      
          if (!data.has_client) {
            delete data.client;
          } else {
            data.client = data.client || [false, false];
          }
      
          setFormData({ isDirty: true, data: { ...data } });
        } else {
          setFormData({ isDirty: false, data: { ...data } });
        }
      };
    useEffect(() => {
        getAllCategories().then((res) => setCategories(res.categories));
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '50%', margin: '1.5rem' }}>
            <div className={styles.label_spaceBetween}>
                <label className={styles.label}>Completed</label>
                <Switch {...register('completed')} checked={watch('completed')} />
            </div>

            <div className={styles.label_spaceBetween}>
                <label className={styles.label}>Category</label>
                <Switch {...register('has_category')} checked={watch('has_category')} />
            </div>

            {watch('has_category') && (
                <Controller
                    name="category"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                            value={value}
                            onChange={(e, newValue) => onChange(newValue)}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={categories}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') return option;
                                if (option.inputValue) return option.inputValue;
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => <TextField {...params} placeholder="Select a category" />}
                        />
                    )}
                />
            )}

            <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" }}>
                Name Project
            </Typography>
            <OutlinedInput
                placeholder="Name of the project"
                {...register('name', { required: true, minLength: 3 })}
            />
            {errors.name && <span>This field is required</span>}

            <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" }}>
                Type of Service
            </Typography>
            <Controller
                name="service"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Select
                        placeholder="Select a service"
                        value={value}
                        onChange={onChange}
                        disabled
                    >
                        {createProject[1].options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {errors.service && <span>This field is required</span>}

            <Typography variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" }}>
                Due to date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <DatePicker
                            className={styles.datepicker}
                            label="Date picker"
                            value={dayjs(value) || null}
                            onChange={onChange}
                            input={(params) => <TextField {...params} placeholder="date" />}
                        />
                    )}
                />
                {errors.end_date && <span>This field is required</span>}
            </LocalizationProvider>

            <div className={styles.label_spaceBetween}>
                <label className={styles.label}>Client</label>
              
            </div>

            {watchHasClient && (
                <>
                    <div className={styles.label_spaceBetween}>
                    <label className={styles.label}>Audio Description</label>
                    <Switch
                        {...register('client[0]', { required: false })}
                        checked={watch('client[0]')}
                        onChange={(e) => {
                        setValue('client[0]', e.target.checked); // Actualiza el valor del switch
                        trigger(); // Fuerza la validación y el marcado de cambios
                        }}
                    />
                    </div>
                    <div className={styles.label_spaceBetween}>
                    <label className={styles.label}>Audio Narration</label>
                    <Switch
                        {...register('client[1]', { required: false })}
                        checked={watch('client[1]')}
                        onChange={(e) => {
                        setValue('client[1]', e.target.checked); // Actualiza el valor del switch
                        trigger(); // Fuerza la validación y el marcado de cambios
                        }}
                    />
                    </div>
                </>
                )}
        </form>
    );
};

export default FormDataProject;
