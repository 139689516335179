import { Checkbox, FormControlLabel } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from "react";
import { SendQC } from "../components/SendQC";
import ModalApproveAll from "../components/qualityControl/ModalApproveAll";
import { Timeline } from "../components/timeline/Timeline";
import { VerticalActions } from "../components/timeline/VerticalActions";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataQC } from "../context/DataQC";


import { useParams } from 'react-router-dom';
import { AudioRecorderNarator } from '../components/narrator/AudioRecorderNarator';
import { DataUser } from "../context/DataUser";
import styles from "../css/TimelineContainerQC.module.css";
import { getProject, updateStage } from '../utils/project/CRUD';

const TimelineContainerQC = () => {
  const { fullVideo, waveformLoaded, currentRegion } = useContext(DataDescriptor);
  const { currentProject,setCurrentProject } = useContext(DataUser);
  const { allApproved, setAllApproved, handleChangeAllApproved } = useContext(DataQC);
  
  const { _id: idProject, stage } = currentProject;  
  let { id } = useParams();
  const [openModalApproveAll, setOpenModalApproveAll] = useState(false);
  const [e, setE] = useState(); // event

  useEffect(() => {
    if (!currentProject?._id){
      getProjectData()
    }else if (currentProject?._id) {
      updateStage(stage, idProject);
    }
  }, [currentProject]);

  const getProjectData = async () => {
    const project = await getProject(id);
      setCurrentProject(project.project);
  }

  const handleClickOpenModalApproveAll = (e) => {
    setE(e);
    setOpenModalApproveAll(true);
  };

  // When loading, check if all segments are approved to check "All Approved" checkbox
  useEffect(() => {
    if (!currentProject?._id) return;
    (async () => {
      let response;
      if(currentProject.service==="dubbing"){
        response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${currentProject?._id}`, { method: "GET" });
      }else{

        response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject?._id}`, { method: "GET" });
      }
      const result = await response.json();
      const resultCheck = result.some(segment => segment.review.approved === false);
      setAllApproved(!resultCheck);
    })();
  }, [currentProject]);


  return (
    <>
      {((window.location.href.includes("/quality-control/") && currentProject.service == "dubbing")) && waveformLoaded && currentRegion ? (
        <AudioRecorderNarator />
      ) : null}
      <div
        className={styles.timeline}
        style={{
          display: fullVideo ? "none" : "flex",
        }}
      >
        {waveformLoaded && (
          <VerticalActions className={styles.btn_Send} />
        )}

        <Timeline className={styles.timeline_row} />

        {waveformLoaded && (
          <div className={styles.send__container}>
            {!window.location.href.includes("/client/") &&
              <FormControlLabel required className={styles.lb__approve} control={
                <Checkbox
                  // onChange={handleChangeAllApproved}
                  onClick={!allApproved ? handleClickOpenModalApproveAll : handleChangeAllApproved}
                  checked={allApproved}
                  sx={{
                    color: green[800],
                    '&.Mui-checked': {
                      color: green[500],
                    },
                  }}
                />}
                label="Approve All"
                aria-label="Approve All Segments"
              />
            }
            <SendQC className={styles.btn_Send} />
          </div>
        )}
        <ModalApproveAll open={openModalApproveAll} setOpen={setOpenModalApproveAll} e={e} />

      </div>
    </>
  );
};

export default TimelineContainerQC;
