import React, { useContext, useEffect } from "react";
import { VideoControls } from "../components/common/VideoControls";
import { DescriptorActions } from "../containers/DescriptorActions";
import TimelineContainer from "../containers/TimelineContainer";
import { DataProvider } from "../context/DataDescriptor";
import { DataNarratorProvider } from "../context/DataNarrator";
import { DataUser } from "../context/DataUser";
import styles from "../css/Descriptor.module.css";

export const ScriptAdapter = () => {
  const { currentProject } = useContext(DataUser);
  const { video, _id: idProject, stage } = currentProject;

  const updateStage = () => {
    if (stage.state !== "In progress") {
      fetch(`${process.env.REACT_APP_URL}/api/projects/stage/${idProject}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ stage: "In progress" }),
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
        })
        .catch((err) =>console.error(err));
    }
  };

  useEffect(() => {
    updateStage();
  }, [currentProject]);

  return (
    <>
      <DataProvider>
      <DataNarratorProvider>
        <div className={styles.container__descriptor}>
          <div className={styles.container__descriptorVideo}>
            <VideoControls video={video} idProject={idProject} />
            <DescriptorActions />
          </div>
          <div className={styles.container__timeLine}>
            <TimelineContainer />
          </div>
        </div>
        </DataNarratorProvider>
      </DataProvider>
    </>
  );
};
