export const wavesurferScroll = () => {
    const wavesurferContainer = document.querySelector("#waveform > wave");
    let isDragging = false;
    let lastX;
    let scrollLeft;

    wavesurferContainer.addEventListener("mousedown", (e) => {
      isDragging = true;
      lastX = e.clientX;
      scrollLeft = wavesurferContainer.scrollLeft;
      wavesurferContainer.style.cursor = "grabbing";
    });

    wavesurferContainer.addEventListener("mouseup", (e) => {
      isDragging = false;
      wavesurferContainer.style.cursor = "grab";
    });

    wavesurferContainer.addEventListener("mousemove", (e) => {
      if (!isDragging) return;
      let xMove = e.clientX - lastX;
      wavesurferContainer.scrollLeft = scrollLeft - xMove;
    });

    wavesurferContainer.addEventListener("mouseleave", () => {
      isDragging = false;
      wavesurferContainer.style.cursor = "grab";
    });
  };

  export   const destroyTimeLine = (websurfer,createAll) => {
    if (websurfer.current) {
      websurfer.current.destroy();
      websurfer.current = null;
    }
    createAll();
  };
