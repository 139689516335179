import { createContext, useEffect, useState } from "react";
import { generateBlobSRT, segmentsToSRT } from "../utils/export-srt";

export const DataVoiceOver = createContext();

export const DataVoiceOverProvider = ({ children }) => {
  const [segmentation, setSegmentation] = useState(false);
  const [video, setVideo] = useState("");
  const [segmentPlaying, setSegmentPlaying] = useState([]);
  const [lastSpeaker, setLastSpeaker] = useState(undefined);
  const [cont, setCont] = useState(0);
  const [speaker, setSpeaker] = useState(undefined);
  const [index, setIndex] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);
  const [segmentos, setSegmentos] = useState([]);
  const [editingStates, setEditingStates] = useState([]);
  const [segment, setSegment] = useState(undefined)

  const [nameSpeaker, setNameSpeaker] = useState("");

  const [selectedSpeakers, setSelectedSpeakers] = useState([]);

  let videoTimeout;

  const handleNameSpeaker = (name) => setNameSpeaker(name);
  const handleEditState = (index) => { 
    const newEditingStates = [...editingStates.fill(false)];
    newEditingStates[index] = true;
    setEditingStates(newEditingStates);
  }
  const handleCheckboxChange = (index) => {
    if (selectedSpeakers.includes(index)) {
      // Desmarcar el speaker si ya está seleccionado
      setSelectedSpeakers(selectedSpeakers.filter((item) => item !== index));
    } else {
      // Marcar el speaker si no está seleccionado
      setSelectedSpeakers([...selectedSpeakers, index]);
    }
  };

  const deselectAllSpeakers = () => setSelectedSpeakers([]);

  useEffect(() => {
    if (segmentation) {
      const array = Object.entries(segmentation?.spks_rttm).map(
        ([speaker, segments]) => {
          return { speaker, segments };
        }
      );

      // Ordenar por speaker
      array.sort((a, b) => a.speaker.localeCompare(b.speaker));
      // Elimna los segmentos que no tienen texto o es undefined
      array.map((speaker) => { 
        speaker.segments = speaker.segments.filter((segment) => segment.text !== undefined);
        return speaker;
      }
      );
      setSegmentos(array);     
      if(array[0].speaker === "empty" || array[0].speaker === "undefined") array.shift();
      setSegmentPlaying(Array(array.length).fill(false));
      setEditingStates(Array(array.length).fill(false));
    }
  }, [segmentation]);

  const handleSegmentation = (segmentation) => setSegmentation(segmentation);
  const handleVideo = (video) => setVideo(video);

  const playSegment = async (speaker, index, action = "default") => {
    setSpeaker(speaker);
    setIndex(index);
    setIsPlaying(!isPlaying);

    const max = speaker.segments.length - 1;
    if (speaker.speaker === lastSpeaker && cont < max) {
      if (action === "next") {
        if (cont < max) {
          setCont(cont + 1);
        } else {
          setCont(0);
        }
      } else if (action === "prev") {
        if (cont > 0) {
          setCont(cont - 1);
        } else {
          setCont(max);
        }
      }
    } else {
      setCont(0);
    }
  };

  useEffect(() => {
    if (!speaker) return;
    let segment = speaker.segments[cont];

    segment = speaker.segments[cont];
    const videoElement = document.getElementById("videoVoiceOver");
    const startTime = segment.startTime;
    const endTime = segment.endTime;

    videoElement.currentTime = startTime;

    if (!videoElement.paused) {
      clearTimeout(videoTimeout);
      (async () => {
        await videoElement.pause();
      })();
      (async () => {
        await videoElement.play();
      })();
      // Update state to play segment and pause others segments playing
      setSegmentPlaying((prev) =>
        prev.map((_, i) => (i === index ? true : false))
      );
    } else {
      (async () => {
        await videoElement.play();
      })();
      setSegmentPlaying((prev) =>
        prev.map((_, i) => (i === index ? true : false))
      );
    }
    setLastSpeaker(speaker.speaker); // Actualizar lastSpeaker en el estado

    videoTimeout = setTimeout(async () => {
      if (!videoElement.paused) {
        await videoElement.pause();
        setSegmentPlaying((prev) =>
          prev.map((_, i) => (i === index ? false : _))
        );
      }
    }, (endTime - startTime) * 1000);
  }, [isPlaying, cont]);

  const pauseSegment = async (index) => {
    const videoElement = document.getElementById("videoVoiceOver");
    clearTimeout(videoTimeout);
    await videoElement.pause();
    setSegmentPlaying((prev) => prev.map((_, i) => (i === index ? false : _)));
  };

  const downloadSRT = (speaker, index, action = "default") => {
    speaker.segments = speaker.segments.filter((segment) => segment.text !== undefined);
    let srtString = (segmentsToSRT(speaker.segments));
    generateBlobSRT(srtString, speaker.speaker);
  };

  const editNameSpeaker = (speaker, newName) => {
    if (newName === "") return;
    if (newName === speaker) return;
    const newSegmentation = { ...segmentation };
    const newSpeaker = `${newName}`;
    newSegmentation.spks_rttm[newSpeaker] = newSegmentation.spks_rttm[speaker];
    delete newSegmentation.spks_rttm[speaker];
    setSegmentation(newSegmentation);
    handleNameSpeaker("");
  };

  const contextValue = {
    segmentation,
    handleSegmentation,
    video,
    handleVideo,
    playSegment,
    pauseSegment,
    segmentPlaying,
    handleCheckboxChange,
    selectedSpeakers,
    segmentos,
    deselectAllSpeakers,
    downloadSRT,
    editNameSpeaker,
    nameSpeaker,
    handleNameSpeaker,
    handleEditState,
    editingStates 
  };

  return (
    <DataVoiceOver.Provider value={contextValue}>
      {children}
    </DataVoiceOver.Provider>
  );
};
