import CloseIcon from "@mui/icons-material/Close";
import InsightsIcon from "@mui/icons-material/Insights";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useContext } from "react";
import { DataUser } from "../../context/DataUser";

import { IconButton } from "@mui/material";
import TrackingProject from "./TrackingProject";



export const ModalProgress = ({ infoProject, setOpenInfoProject }) => {
  const { currentProject } = useContext(DataUser);
 

  return (
    <Dialog open={infoProject} onClose={() => setOpenInfoProject(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        sx={{
          color: "white",
          backgroundColor: "#28262E",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "2rem",
          paddingTop: "2rem",
        }}
      >
        <InsightsIcon fontSize="large" />
        {"Progress of " + currentProject.name + " project"}
        <IconButton
          aria-label="close"
          onClick={() => setOpenInfoProject(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#28262E",
          width: "100%",
        }}
        className="progressModal"
      >
     <TrackingProject project={currentProject} />
      </DialogContent>
    </Dialog>
  );
};
