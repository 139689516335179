import React, { useContext } from 'react';
import { InterpreterTabs } from '../components/interpreter/InterpreterTabs';
import { RecordingRegion } from '../components/interpreter/RecordingRegion';
import { DataDescriptor } from '../context/DataDescriptor';
import styles from "../css/DescriptorActions.module.css";

export const InterpreterActions = () => {
    const { currentRegion, resized } = useContext(DataDescriptor);

    return (
        <div
            tabIndex={0}
            className={styles.container__descriptorActions}
            style={{width: resized ? "25%" : "40%"}}
        >
            {currentRegion ? (
                    <div className={styles.container__interpreterActions}>
                        <InterpreterTabs />
                        <RecordingRegion />
                    </div>
            ) : (
                <div className={styles.select__segment} tabIndex={0}>
                    <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
                    <span>Select a Segment</span>
                </div>
            )}
        </div>
    );
};
