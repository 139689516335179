import { useContext } from 'react';
import { ClientReview } from '../components/client/ClientReview';
import { DataDescriptor } from '../context/DataDescriptor';
import styles from "../css/ClientActions.module.css";

export const ClientActions = () => {

    const { currentRegion, resized } = useContext(DataDescriptor);

    return (
        <div
            tabIndex={0}
            className={styles.container__clientActions}
            style={{width: resized ? "25%" : "40%",}}>
            {currentRegion ? (
                    <div className={styles.container__clientActions__region}>
                        <ClientReview />
                    </div>
                ) : (
                    <div className={styles.select__segment} tabIndex={0}>
                        <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
                        <span>Select a Segment</span>
                    </div>
                )
            }
        </div>
    )
}
