import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useContext, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataUser } from "../../context/DataUser";
import AudioRecorder from "../toolbar/AudioRecorder";
import { ModalConfig } from "../toolbar/ModalConfig";

export const Recorder = ({ open, setOpen, PaperComponent }) => {
  const { currentRegion,websurfer,setCurrentRegion,descriptionTest } = useContext(DataDescriptor);
  const {  deviceInput,setDeviceInput, setDeviceOutput } = useContext(DataUser);
  const [openConfig, setOpenConfig] = useState(false); 
  const [devicesInput, setDevicesInput] = useState([]);
  const [devicesOutput, setDevicesOutput] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleGetDevices=()=> {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const devicesInput = devices.filter((device) => device.kind === "audioinput");      
      setDevicesInput(devicesInput);
      const deviceInputHeader = devicesInput.find((device) => device.deviceId === (deviceInput?.deviceId ? deviceInput.deviceId : "default"));
      setDeviceInput(deviceInputHeader);
      const devicesOutput = devices.filter((device) => device.kind === "audiooutput");
      setDevicesOutput(devicesOutput);
      const deviceOutput = devicesOutput.find((device) => device.deviceId === "default");
      setDeviceOutput(deviceOutput);
    });
  }

  const handleConfig = () => {
    setOpenConfig(true);
    handleGetDevices();
  };
  
 const handleCloseRecorder = () => {
  setOpen(false)
  if (currentRegion.start <=3) {
    websurfer.current.seekTo(0);
    
    websurfer.current.pause();
    setCurrentRegion(currentRegion);
    return;
  }
    const startTime = currentRegion.start - 3;
    websurfer.current.seekTo(startTime / websurfer.current.getDuration());
    
    websurfer.current.pause();
    setCurrentRegion(currentRegion);
  
 }
  return (
    <>
    <Dialog
      open={open}
      onClose={() => handleCloseRecorder()}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Description
        <IconButton
          aria-label="setting audio"
          sx={{
            position: "absolute",
            right: 40,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={() => handleConfig()}>
          <TuneIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ width: "100%", color: "white" }}>
          {descriptionTest}
        </DialogContentText>
        <AudioRecorder />
      </DialogContent>
    </Dialog>
      <ModalConfig
      devicesInput={devicesInput}
      devicesOutput={devicesOutput}
      openConfig={openConfig}
      setOpenConfig={setOpenConfig}
      aria live="assertive"
    />
    </>
  );
};
