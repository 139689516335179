import React from "react";

import { useLocation } from "react-router";
import styles from "../../css/Footer.module.css";

export const Footer = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === "/login" ||
      pathname === "/dashboard" ||
      pathname === "/srt-to-excel" ||
      pathname === "/segmentation-voice-over" ? (
        <footer className={styles.container}>
          <div className={styles.container__footer}>
            <div className={styles.copyright}>
              <small>&copy;</small> <span>2023 Dicapta Corporation</span>
            </div>
            <span className={styles.separator}>|</span>
            <div className={styles.rigjts}>
              <p>All rights reserved.</p>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};
