import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import InsightsIcon from "@mui/icons-material/Insights";
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import SubjectIcon from '@mui/icons-material/Subject';
import { Avatar, IconButton, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Mousetrap from "mousetrap";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import { DataUser } from "../../context/DataUser";
import styles from "../../css/Header.module.css";
import { ModalProgress } from "../dashboard/ModalProgress";
import { ModalShortcuts } from "../narrator/ModalShortcuts";
import { ModalConfig } from "../toolbar/ModalConfig";
import { ModalGuidelines } from "../toolbar/ModalGuidelines";
import ModalUserGuide from '../toolbar/UserGuide/ModalUserGuide';
export const Header = () => {
  const { getUser, currentProject, user, handlerSetUser, deviceInput,setDeviceInput, setDeviceOutput, narrationType, setNarrationType } = useContext(DataUser);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfig, setOpenConfig] = useState(false);
  const [infoProject, setOpenInfoProject] = useState(false);
  const [openUserGuide, setOpenUserGuide] = useState(false);
  const [guidelinesProject, setGuidelinesProject] = useState(false);
  const [openShortcuts, setOpenShortcuts] = useState(false);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  let keepOpen = false;
  let name, userName, surname, roles;

  useEffect(() => {    
    fetchData();
  }, []);

  const changeNarrationType = () => {
    if (narrationType === "Standard") {
      setNarrationType("Listen & Speak");
    } else {
      setNarrationType("Standard");
    }
    keepOpen = true;

    //speechSynthesis.cancel();
    //const utterance = new SpeechSynthesisUtterance(`${narrationType === 'Standard' ? 'Along The Text' : 'Standard'}`);
    //utterance.rate = 0.9;
    //utterance.lang = "en-US";

   /* utterance.addEventListener('end', () => {
      speechSynthesis.cancel();
    });*/
    //speechSynthesis.speak(utterance);
  }

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  

  const handleClose = () => {
    setAnchorEl(!keepOpen ? null : (p) => p);
    keepOpen = false;
    setAnchorEl(null)
  };    
  
  async function fetchData() {
    const userDB = await getUser();
    handlerSetUser(userDB);
  }

  if (user) {
    name = currentProject.name;
    userName = user?.name;
    surname = user?.surname;
    roles = user?.roles;
  }
  
  useEffect(() => {       
      if (pathname.includes("/narrator")) handleConfig();    
      handleCloseAllModals();
  }, [pathname]);

  const [devicesInput, setDevicesInput] = useState([]);
  const [devicesOutput, setDevicesOutput] = useState([]);
  const showRole = (role) => {
    switch (role) {
      case "AD":
        return "Audio Describer";
      case "PM":
        return "Project Manager";
      case "AN":
        return "Audio Narrator";
      case "AE":
        return "Audio Editor";
      case "QC":
        return "Quality Control";
      default:
        break;
    }
  };

  const handleGetDevices=()=> {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const devicesInput = devices.filter((device) => device.kind === "audioinput");      
      setDevicesInput(devicesInput);
      const deviceInputHeader = devicesInput.find((device) => device.deviceId === (deviceInput?.deviceId ? deviceInput.deviceId : "default"));
      setDeviceInput(deviceInputHeader);
      const devicesOutput = devices.filter((device) => device.kind === "audiooutput");
      setDevicesOutput(devicesOutput);
      const deviceOutput = devicesOutput.find((device) => device.deviceId === "default");
      setDeviceOutput(deviceOutput);
    });
  }

  const handleConfig = () => {
    setOpenConfig(true);
    handleGetDevices();
  };

  const handleUserGuide = () => setOpenUserGuide(true);

  const handleInfoProject = () => setOpenInfoProject(true);
  
  const handleGuidelinesModal = () => setGuidelinesProject(true);

  const handleShortcutsModal = () => setOpenShortcuts(true);  

  const narrationTypeShortcut = () => {
    if (window.location.href.includes("narrator")) changeNarrationType();      
  }

  const shortcutsShortcut = () => {
    if (window.location.href.includes("narrator")) handleShortcutsModal();
  }
  
  useEffect(() => {
    Mousetrap.bind(['alt+shift+o', 'option+shift+o'], (e)=> {
      narrationTypeShortcut();
      console.log('Alt + Shift + O');
    })
    Mousetrap.bind(['alt+shift+k', 'option+shift+k'], (e)=>{
      shortcutsShortcut();
      console.log('Alt + Shift + K');
    })
    return () => {
      Mousetrap.unbind(['alt+shift+o', 'option+shift+o'])
      Mousetrap.unbind(['alt+shift+k', 'option+shift+k'])
    };
  }, [narrationType]);

  const handleCloseAllModals = () => {
    setOpenConfig(false);
    setOpenInfoProject(false);
    setOpenShortcuts(false);
    setGuidelinesProject(false);
  };
  

  return (
    <>
      {pathname === "/login" || pathname === "/" ? (
        <header>
          <div tabIndex={-1} className={styles.container__logo}>
            <img tabIndex={-1} src="/assets/img/Logo1.png" alt="Logo All 4 VOICING" />
          </div>
        </header>
      ) : (
        <>
          {user?.roles && (
            <header className={styles.container__navBar}>
              <div className={styles.container__logoCompany}>
                <img src="/assets/img/Logo1.png" alt="Logo All 4 VOICING" />
              </div>
              <div className={styles.container__projectName}>
                <h1>{name}</h1>
              </div>
              <div className={styles.container__user}>
                <div
                  className={styles.container__user__name}
                >
                  <span>
                    {userName} {surname}
                  </span>
                  -<span>{showRole(roles[0])}</span>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      tabIndex={0}
                    >
                      <Avatar sx={{ width: 32, height: 32 }}>
                        {userName.charAt(0)}
                      </Avatar>
                      <div className={styles.badge}><span className={styles.row_down}>▼</span></div>
                    </IconButton>
                  </Tooltip>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleClose}  >
                    <Avatar /> {userName.toLowerCase().charAt(0).toUpperCase() + userName.toLowerCase().slice(1)} {surname.toLowerCase().charAt(0).toUpperCase() + surname.toLowerCase().slice(1)}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => navigate('/dashboard')} autoFocus>
                    <ListItemIcon>
                      <GridViewRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={() => handleUserGuide()}>
                    <ListItemIcon>
                      <HelpCenterIcon fontSize="small" />
                    </ListItemIcon>
                    User Guide
                  </MenuItem>
                  <MenuItem onClick={() => handleConfig()}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Device Settings 
                  </MenuItem>
                  {currentProject &&
                    (<MenuItem onClick={() => handleInfoProject()}>
                      <ListItemIcon>
                        <InsightsIcon fontSize="small" />
                      </ListItemIcon>
                      Info Project
                    </MenuItem>)
                  }
                  {(currentProject && pathname.includes("/narrator") || pathname.includes("/audio-descriptor")) &&
                    (<MenuItem onClick={() => handleGuidelinesModal()}>
                      <ListItemIcon>
                        <SubjectIcon fontSize="small" />
                      </ListItemIcon>
                      Guidelines
                    </MenuItem>)
                  }
                  {currentProject && pathname.includes("/narrator") &&
                    (<MenuItem onClick={handleShortcutsModal} >
                      <ListItemIcon>
                        <KeyboardCommandKeyIcon />
                      </ListItemIcon>
                      Keyboard Hotkeys
                    </MenuItem>)
                  }
                  {currentProject && pathname.includes("/narrator") &&
                    (<MenuItem onClick={changeNarrationType} >
                      <ListItemIcon>
                        {narrationType === "Standard" ? (
                          <HearingDisabledIcon fontSize="small" />
                        ) : (
                          <HearingIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      Narration Type: {narrationType}
                    </MenuItem>)
                  }
                  <MenuItem onClick={() => {
                    localStorage.removeItem("token")
                    handlerSetUser(null)
                    navigate('/login');
                  }}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </header>
          )}
          {!window.location.href.includes("client") && (
          <ModalConfig
            devicesInput={devicesInput}
            devicesOutput={devicesOutput}
            openConfig={openConfig}
            setOpenConfig={setOpenConfig}
            aria live="assertive"
          /> )}
          <ModalProgress
            infoProject={infoProject}
            setOpenInfoProject={setOpenInfoProject}
          />
          <ModalShortcuts
            openShortcuts={openShortcuts}
            setOpenShortcuts={setOpenShortcuts}
          />
          <ModalGuidelines
            guidelinesProject={guidelinesProject}
            setGuidelinesProject={setGuidelinesProject}
          />

          <ModalUserGuide
            open={openUserGuide}
            setOpen={setOpenUserGuide}
          />
        </>
      )
      }
    </>
  );
};
