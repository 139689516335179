import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { Menu, MenuItem } from "@mui/material";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/VideoControls.module.css";
import { resetToStart, seekFrameFrame } from "../../utils/time/time";
export const VideoControls = ({ video }) => {

  const {
    playPause,
    isPlaying,
    setTimeSubRip,
    volume,
    setVolume,
    websurfer,
    actualTime,
    timeSubRip,
    durationSubRip,
    putActualTime,
    resized,
    setResized,
    fullVideo,
    caption,
    setActualTime,
    setIsReset,
    setIsPlaying,
    stopAudioInWave,
    showAudioDescription,
    setShowAudioDescription,
    speeds,readyWaveform,currentProject
  } = useContext(DataDescriptor);
  const [anchorEl, setAnchorEl] = useState(null);
  const [speed, setSpeed] = useState(1);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if(video && websurfer.current){
      websurfer.current.load(video);
      const file = document.querySelector("video");
      readyWaveform(file);
    }
  }, [video]);

  const handleClick = (event) =>  setAnchorEl(event.currentTarget);
  
  const handleClose = () => setAnchorEl(null);
  
  return (
    <>
      <div className={styles.container__video} style={{width: resized ? "75%" : "60%", visibility:window.location.href.includes("interpreter") ? "hidden" : "visible"}}>
        <div tabIndex={-1} style={{position: "relative",height:"100%"}}>          
          <video
            style={{
              height: fullVideo ? "100%" : "",
              width: fullVideo ? "100%" : "",
            }}
            src={video}
            aria-labelledby="video-caption"            
            crossOrigin="anonymous"
          >            
          </video>
          {showAudioDescription &&
            <div
              className={styles.captions}
              style={{ visibility: caption ? "visible" : "hidden" }}
              // aria-hidden={!caption}
              aria-hidden="true"
              // aria-label={caption + "This is the audio description"}
              role="tab"
              id="video-caption"
              dangerouslySetInnerHTML={{ __html: caption }}
            >
            </div>}

        </div>

        {/* VIDEO SLIDER */}
        <input
          className={styles.input__rangeTime}
          type="range"
          min="0"
          max="1"
          step="0.01"
          onChange={(e) => putActualTime(e.target.value, websurfer, setActualTime,currentProject)}
          value={actualTime}
          tabIndex={-1}
          aria-hidden="true"
        />

        {/* NUMBERS DURATION */}
        <div className={styles.input__labelsRangeTime} aria-hidden="true">
          {timeSubRip[0] !== undefined ? (
            <span>{`${timeSubRip[0]}:${timeSubRip[1]}:${timeSubRip[2]}:${timeSubRip[3]} `}</span>
          ) : (
            <span>{`00:00:00:000 `}</span>
          )}
          {durationSubRip[0] !== undefined ? (
            <span>{`${durationSubRip[0]}:${durationSubRip[1]}:${durationSubRip[2]}:${durationSubRip[3]} `}</span>
          ) : (
            <span>{`00:00:00:000 `}</span>
          )}
        </div>

        {/* VIDEO CONTROLS BAR  */}
        <div className={styles.container__videoControls}>
          <div className={styles.controls__basic}>
            <button
              onClick={() => seekFrameFrame("back", websurfer, setTimeSubRip)}
              aria-label="Seek back"
              tabIndex={-1}
              aria-hidden="true"
            >
              <FastRewindIcon fontSize="large" />
            </button>

            <button onClick={playPause} aria-label={isPlaying ? "Pause" : "Play"}  >
              {isPlaying ? (
                <PauseIcon sx={{ fontSize: 40 }} />
              ) : (
                <PlayArrowIcon sx={{ fontSize: 40 }} />
              )}
            </button>

            <button onClick={() => {
              setIsReset(true);
              resetToStart(websurfer, setIsPlaying, stopAudioInWave,currentProject);

            }} aria-label="Stop">

              <StopIcon sx={{ fontSize: 40 }} />
            </button>
            <button
              onClick={() =>
                seekFrameFrame("advance", websurfer, setTimeSubRip, currentProject)
              }
              aria-label="Seek forward"
              tabIndex={-1}
              aria-hidden="true"
            >
              <FastForwardIcon fontSize="large" />
            </button>
          </div>
          <div className={styles.controls__advance}>
            <Stack
              className={styles.volume}
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
              aria-label="Volume controls"
            >
              <VolumeDown />
              <Slider
                aria-label="Volume"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
              />
              <VolumeUp />
            </Stack>
            <button aria-hidden="true" onClick={() => setShowAudioDescription(!showAudioDescription)} aria-label={showAudioDescription ? "Hide audio description" : "Show audio description"}>
              {showAudioDescription ? (
                <SubtitlesIcon />
              ) : (
                <SubtitlesOffIcon />
              )}
            </button>
            <button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {speed}x
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {speeds.map((speed, index) => (
                <MenuItem aria-label={speed+"x Speed"} key={index} onClick={() => {
                  setSpeed(speed);
                  websurfer.current.setPlaybackRate(speed, true);
                  handleClose();
                }}>{speed}</MenuItem>
              ))}

            </Menu>
            <button
              tabIndex={-1}
              aria-hidden="true"
              onClick={() => setResized(!resized)}
              aria-label={resized ? "Shrink Video Size" : "Expand Video Size"}
            >
              {resized ? (
                <img src="/assets/icons/amplify.png" alt="amplify" />
              ) : (
                <img src="/assets/icons/isAmplify.png" alt="is amplify" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
