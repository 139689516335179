import { Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/ProjectsList.module.css";

export const ListProject = ({ projects, user }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const { setCurrentProject } = useContext(DataUser);

  useEffect(() => {
    const formattedRows = projects.map((project) => ({
      id: project._id,
      projectName: project.name,
      status: project.stage?.state,
      deadLine: project.end_date,
      role: project.rol?.role,
      describer: project.users[1]?.user_id?.name + " " + project.users[1]?.user_id?.surname,
      qc: project.users[4]?.user_id?.name + " " + project.users[4]?.user_id?.surname
    }));
    setRows(formattedRows);

    const formattedColumns = [
      {
        field: "projectName",
        headerName: "Project Name",
        flex: 1,
        renderCell: (params) => {
          const projectId = params.row.id;
          const projectName = params.value;
          const role = params.row.role;
          const project = projects.find((project) => project._id === projectId);

          let to = "";
          if (role === "AD") { to = `/audio-descriptor/${projectId}`; }
          else if (role && role.includes("QC")) { to = `/quality-control/${projectId}`; }
          else if (role === "AN") { to = `/narrator${projectId}`; }
          else { to = "not-found"; }

          return (
            <>
              {!user?.roles.includes("PM") ? (
                <Link className={styles.link} to={to} onClick={() => setCurrentProject(project)}>
                  <span>{projectName}</span>
                </Link>) : (
                <span>{projectName}</span>
              )}
            </>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          const name = params.value;
          let color;
          if (name === "To review") { color = "#4a0d28"; }
          else if (name === "In progress") { color = "#632755"; }
          else if (name === "New") { color = "#145D5F"; }
          return (
            <Chip
              label={params.value}
              color="primary"
              variant="outlined"
              style={{ backgroundColor: color, color: "#fff", borderColor: "#fff" }}
            />
          );
        },
      },
      {
        field: "role",
        headerName: "Role",
        flex: 1,
        renderCell: (params) => {
          const role = params.value;
          let roleName;
          if (role === "AD") { roleName = "Describer"; }
          else if (role === "AN") { roleName = "Narrator"; }
          else if (role && role.includes("QC")) { roleName = "Quality Control"; }
          return <span>{roleName}</span>;
        },
      },

      { field: "deadLine", headerName: "Deadline", flex: 1 },
    ];
    if (user?.roles.includes("PM")) {
      formattedColumns.push(
        { field: "describer", headerName: "Describer", flex: 1 },
        { field: "qc", headerName: "Quality Control", flex: 1 }
      );
    }
    setColumns(formattedColumns);
  }, [projects]);

  return (
    <div style={{ height: "auto", width: "100%", overflowY: "auto" }}>
      {rows.length > 0 && (
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": { backgroundColor: "rgba(169, 63, 144, 0.4)", color: "#fff", },
            "& .MuiDataGrid-columnHeaders": {
              background:
                "linear-gradient(180deg, rgba(61, 28, 71, 0.4) 0%, rgba(244, 96, 54, 0.1) 100%)",
              color: "#fff",
            },
            "& .MuiDataGrid-footerContainer": {
              background:
                "linear-gradient(180deg, rgba(61, 28, 71, 0.4) 0%, rgba(244, 96, 54, 0.1) 100%)",
              color: "#fff",
            },
            "& .MuiDataGrid-withBorderColor": { border: "none" },
            "& .MuiDataGrid-cell--textCenter": { textAlign: "center" },
            "& .MuiToolbar-root": { color: "#fff" },
            "& .MuiIconButton-root": { color: "#fff" },
            "& .MuiDataGrid-root": { border: "none" },
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
        />
      )}
    </div>
  );
};
