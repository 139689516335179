import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useContext } from "react";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/Header.module.css";

export const ModalGuidelines = ({ guidelinesProject, setGuidelinesProject }) => {
    const { currentProject} = useContext(DataUser);    
    return (
        <Dialog open={guidelinesProject} onClose={() => setGuidelinesProject(false)}>
            <DialogTitle
                sx={{
                    color: "white",
                    backgroundColor: "#28262E",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2rem",
                    paddingTop: "2rem",
                }}
            >
                <Typography variant="h6" component="div" tabIndex={0} autoFocus>
                    Guidelines
                </Typography>
                
                <IconButton
                    tabIndex={10}
                    onClick={() => setGuidelinesProject(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent
                sx={{
                    backgroundColor: "#28262E",
                    width: "100%",
                }}
                className="progressModal"
            >
                <div className={styles.content_guideline}>
                    <p>{currentProject.guideline}</p>
                </div>
            </DialogContent>
        </Dialog>
    )
}
