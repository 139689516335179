import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Mousetrap from "mousetrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataUser } from "../context/DataUser";
import styles from "../css/Time.module.css";
import { updateSegmentTime } from "../utils/time/time";
import { validationTime } from "../utils/time/validations";



export const Time = () => {

  const {
    startTime,
    endTime,
    setStartHH,
    setStartMM,
    setStartSS,
    setStartMS,
    setEndHH,
    setEndMM,
    setEndSS,
    setEndMS,
    duration,
    startHH,
    startMM,
    startSS,
    startMS,
    endHH,
    endMM,
    endSS,
    endMS,
    currentRegion,
    setDuration,
    waveformLoaded,minLengthRegion
  } = useContext(DataDescriptor);
  const { user, currentProject } = useContext(DataUser);
  const [openToast, setOpenToast] = useState(false);

  const handleChange = (e, name) => {
    const { value } = e.target;
    //console.log("handleChange", name, value);
    if (
      validationTime(
        name,
        value,
        startHH,
        startMM,
        startSS,
        startMS,
        endHH,
        endMM,
        endSS,
        endMS,minLengthRegion
      )
    ) {
      if (name === "startHH") {
        setStartHH(prevStartHH => value);
      } else if (name === "startMM") {
        setStartMM(prevStartMM => value);
      } else if (name === "startSS") {
        setStartSS(prevStartSS => value);
      } else if (name === "startMS") {
        setStartMS(prevStartMS => value);
      } else if (name === "endHH") {
        setEndHH(prevEndHH => value);
      } else if (name === "endMM") {
        setEndMM(prevEndMM => value);
      } else if (name === "endSS") {
        setEndSS(prevEndSS => value);
      } else if (name === "endMS") {
        setEndMS(prevEndMS => value);
      }
      updateSegmentTime(
        currentRegion,
        startHH,
        startMM,
        startSS,
        startMS,
        endHH,
        endMM,
        endSS,
        endMS,
        name,
        value,
        setDuration

      );
    } else {
      setOpenToast(true);
    }
  };

  //todo: -------------------- Duration Shortcut --------------------

  const durationRef = useRef(null);

  const durationShortcut = () => {
    // e.preventDefault();
    // if (e.key.toLowerCase() === "l" && e.altKey) {

    durationRef.current.focus();

    // console.log("duration", Math.round(duration * 10) / 10);
    // durationRef.current.setAttribute("aria-live", "assertive");
    // // durationRef.current.setAttribute("aria-label", "Region duration is " + Math.round(duration * 10) / 10);

    // setTimeout(() => {
    //   durationRef.current.setAttribute("aria-live", "off");
    // }, 1000);


    // speechSynthesis.cancel();
    // const utterance = new SpeechSynthesisUtterance(`Segment duration is ${Math.round(duration * 10) / 10} seconds`);
    // utterance.rate = 0.9;
    // utterance.lang = "en-US";
    // speechSynthesis.speak(utterance);
    // utterance.addEventListener('end', () => {
    //   speechSynthesis.cancel();
    // });
    // }
  }

  useEffect(() => {
    Mousetrap.bind(['alt+l', 'option+l'], () => {
      durationShortcut();
    })
    return () => {
      Mousetrap.unbind(['alt+l', 'option+l'])
    };
  }, []);



  return (
    <>
      {waveformLoaded && (
        <>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={() => setOpenToast(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={"error"}>
              El tiempo de inicio no puede ser mayor al tiempo final
            </Alert>
          </Snackbar>

          <div className={styles.container}>
            <div tabIndex={-1} aria-hidden="true" className={styles.container__separator}>
              <label htmlFor="start-time" className={styles.labelTime}>
                Start:
              </label>
              <input
                disabled={true}
                type="number"
                id="start-time-hours"
                min="0"
                max="23"
                className={styles.inputTime}
                value={startTime.hours}
                onChange={(e) => handleChange(e, "startHH")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="start-time-minutes"
                min="0"
                max="59"
                className={styles.inputTime}
                value={startTime.minutes}
                onChange={(e) => handleChange(e, "startMM")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="start-time-seconds"
                min="0"
                max="59"
                className={styles.inputTime}
                value={startTime.seconds}
                onChange={(e) => handleChange(e, "startSS")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="start-time-milliseconds"
                min="0"
                max="999"
                className={styles.inputTime}
                value={startTime.milliseconds}
                onChange={(e) => handleChange(e, "startMS")}
              />
            </div>
            <div tabIndex={-1} aria-hidden="true" className={styles.container__separator}>
              <label htmlFor="end-time" className={styles.labelTime}>
                End:
              </label>
              <input
                disabled={true}
                type="number"
                id="end-time-hours"
                min="0"
                max="23"
                className={styles.inputTime}
                value={endTime.hours}
                onChange={(e) => handleChange(e, "endHH")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="end-time-minutes"
                min="0"
                max="59"
                className={styles.inputTime}
                value={endTime.minutes}
                onChange={(e) => handleChange(e, "endMM")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="end-time-seconds"
                min="0"
                max="59"
                className={styles.inputTime}
                value={endTime.seconds}
                onChange={(e) => handleChange(e, "endSS")}
              />
              <span className={styles.input__separator}>:</span>
              <input
                disabled={currentProject.rol?.role === "QC2" || window.location.href.includes("/narrator") ? true : false || currentRegion.data?.review?.approved ? true : false}
                type="number"
                id="end-time-milliseconds"
                min="0"
                max="999"
                className={styles.inputTime}
                value={endTime.milliseconds}
                onChange={(e) => handleChange(e, "endMS")}
              />
            </div>
            <div
              ref={durationRef}
              aria-label={`Current Region duration is ${Math.round(duration * 10) / 10} seconds`} aria-describedby="duration-label"
              tabIndex={0}
              className={styles.container__separator}
            >
              <label htmlFor="duration" className={styles.labelTime}>
                Duration:&nbsp;
              </label>
              <p id="duration-label" className={styles.inputTime} >
                {Math.round(duration * 10) / 10} s
              </p>

            </div>

          </div>
        </>
      )}

    </>
  );
};
