export const generateAudioFinal = async (projectId) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/regenerate-audio/${projectId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
   
    try {
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }finally{
        console.info('end generateMixAudio');
    }
    
}