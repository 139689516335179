import React, { useContext, useEffect } from "react";
import { VideoControls } from "../components/common/VideoControls";
import { InterpreterActions } from "../containers/InterpreterActions";
import TimelineContainer from "../containers/TimelineContainer";
import { DataProvider } from "../context/DataDescriptor";
import { DataNarratorProvider } from "../context/DataNarrator";
import { DataUser } from "../context/DataUser";
import styles from "../css/Descriptor.module.css";

export const Interpreter = () => {
  const { currentProject } = useContext(DataUser);
  const { video, _id: idProject, stage } = currentProject;

  const updateStage = () => {
    if (stage.state !== "In progress") {
      fetch(`${process.env.REACT_APP_URL}/api/projects/stage/${idProject}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ stage: "In progress" }),
      })
        .then((res) => res.json())
        .then((data) => {
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    updateStage();
  }, [currentProject]);

  return (
    <DataProvider>
      <DataNarratorProvider>
        <div className={styles.container__descriptor}>
          <div className={styles.container__descriptorVideo} tabIndex={2}>
            <VideoControls video={video} idProject={idProject} />

            <InterpreterActions />
          </div>

          <div className={styles.container__timeLine} tabIndex={1}>
            <TimelineContainer tabIndex={1} />
          </div>
        </div>
      </DataNarratorProvider>
    </DataProvider>
  );
};
