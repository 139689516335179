import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SaveIcon from "@mui/icons-material/Save";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useContext, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/BarActions.module.css";
import {
  handleNextRegion,
  handlePreviousRegion,
} from "../../utils/controls/controlsSegment";

import { DataNarrator } from "../../context/DataNarrator";
import { putDescriptionSegment, putScriptSegmentSign } from "../../utils/descriptions/description";
import ModalDelete from "../describer/ModalDelete";
import { Recorder } from "../describer/Recorder";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    //establecer style
    >
      <Paper
        {...props}
        style={{ backgroundColor: "#28262E", color: "white", width: "50%" }}
      />
    </Draggable>
  );
}
export const BarActions = () => {
  const {
    regions,
    websurfer,
    setTimeSubRip,
    currentRegion,
    setActualTime,
    setCurrentRegion,
    setStartHH,
    setStartMM,
    setStartSS,
    setStartMS,
    setEndHH,
    setEndMM,
    setEndSS,
    setEndMS,
    setDuration,
    user,
    setDescription,
    setIsActiveAudio, 
    setCaption, 
    setIsPlaying, 
    setDescriptionTest, 
    viewAllSegments,
    currentProject
  } = useContext(DataDescriptor);
  const { wavesurfer } = useContext(DataNarrator)
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");

  const handleClickOpen = () => {
    setDescriptionTest(currentRegion.data?.description?.initial);

    setOpen(true);
  };
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleClickOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleSaveSegment = async () => {
    currentRegion.update({
      data: {
        ...currentRegion.data,
      },
    });
    const result = window.location.href.includes("script-adapter") ? await putScriptSegmentSign(currentRegion.data, currentRegion.data.id) : await putDescriptionSegment(currentRegion.data, currentRegion.data.id);

    if (result.status) {
      setMessage("Segment saved");
      setStatusSave(true);
      setOpenToast(true);
    } else {
      setMessage("Error saving segment");
      setStatusSave(false);
      setOpenToast(true);
    }
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusSave ? "success" : "error"}>{message}</Alert>
      </Snackbar>
      <div className={styles.actions}>
        <button
          variant="contained"
          onClick={() =>
            handlePreviousRegion(
              regions,
              websurfer,
              setTimeSubRip,
              currentRegion,
              setActualTime,
              setCurrentRegion,
              setStartHH,
              setStartMM,
              setStartSS,
              setStartMS,
              setEndHH,
              setEndMM,
              setEndSS,
              setEndMS,
              setDuration,
              user,
              setDescription,
              setIsActiveAudio,
              setCaption,
              wavesurfer, setIsPlaying, viewAllSegments, currentProject
            )
          }
        >
          <SkipPreviousIcon fontSize="large" />
        </button>
        <button
          variant="contained"
          onClick={() =>
            handleNextRegion(
              regions,
              websurfer,
              setTimeSubRip,
              currentRegion,
              setActualTime,
              setCurrentRegion,
              setStartHH,
              setStartMM,
              setStartSS,
              setStartMS,
              setEndHH,
              setEndMM,
              setEndSS,
              setEndMS,
              setDuration,
              user,
              setDescription,
              setIsActiveAudio,
              wavesurfer, setIsPlaying, viewAllSegments, currentProject
            )
          }
        >
          <SkipNextIcon fontSize="large" />
        </button>
        {!window.location.href.includes('script-adapter') && (
          <button onClick={() => handleClickOpen()}>
            <KeyboardVoiceIcon fontSize="large" />
          </button>)}
        <button
          variant="contained"
          onClick={() => handleClickOpenModalDelete()}
          disabled={currentRegion.data?.review?.approved ? true : false}
          className={styles.button}
        >
          <DeleteIcon fontSize="large" />
        </button>
        <button
          variant="contained"
          onClick={() => handleSaveSegment()}
          disabled={currentRegion.data?.review?.approved ? true : false}
          className={styles.button}
        >
          <SaveIcon fontSize="large" />
        </button>

        <Recorder
          open={open}
          setOpen={setOpen}
          PaperComponent={PaperComponent}
        />
        <ModalDelete open={openModalDelete} setOpen={setOpenModalDelete} />
      </div>
    </>
  );
};
