import CloseIcon from '@mui/icons-material/Close';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import { useContext, useState } from 'react';
import { DataUser } from '../../../context/DataUser';
import styles from '../../projectManager/ModalEditProject/ModalEditProject.module.css';
import GuideStepper from './GuideStepper';
import TabsUserGuide, { a11yProps } from './TabsUserGuide';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const ModalUserGuide = ({ open, setOpen }) => {
    const [value, setValue] = useState(1);
    const { user } = useContext(DataUser);

    const handleChange = (event, newValue) => setValue(newValue);

    const handleCloseModal = async () => setOpen(false);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Modal
                open={open}
                onClose={() => handleCloseModal()}
                aria-labelledby="modal-user-guide-title"
                aria-describedby="modal-user-guide-description"
            >
                <Box className={styles.modal} >
                    <button aria-label="close" onClick={() => handleCloseModal()} className={styles.closeButton}> <CloseIcon /> </button>

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="User Guide Tabs"
                        className={styles.tabs}
                    >
                        <Tab label='USER GUIDE' className={styles.tab_title} sx={{
                            '&.Mui-disabled': {
                                color: 'var(--text-color)',
                            },
                        }} disabled />
                        {(user?.roles.includes('AD') || user?.roles.includes('AN') || user?.roles.includes('QC')) &&
                            <Tab label="Dashboard" icon={<SpaceDashboardIcon sx={{ mr: 1 }} />} {...a11yProps(1)} />}
                        {(user?.roles.includes('PM')) &&
                            <Tab label="Dashboard" icon={<SpaceDashboardIcon sx={{ mr: 1 }} />} {...a11yProps(1)} />}
                        {(user?.roles.includes('AD') || user?.roles.includes('PM')) &&
                            <Tab label="Audio Describer" icon={<TextSnippetIcon sx={{ mr: 1 }} />} {...a11yProps(2)} />}
                        {(user?.roles.includes('AN') || user?.roles.includes('PM')) &&
                            <Tab label="Audio Narrator" icon={<GraphicEqIcon sx={{ mr: 1 }} />} {...a11yProps(3)} />}
                        {(user?.roles.includes('QC') || user?.roles.includes('PM')) &&
                            <Tab label="Quality Control" icon={<ReviewsIcon sx={{ mr: 1 }} />} {...a11yProps(4)} />}
                        {(user?.roles.includes('CLIENTO') || user?.roles.includes('PM') || window.location.href.includes('client')) &&
                            <Tab label="Client" icon={<SupervisedUserCircleIcon sx={{ mr: 1 }} />} {...a11yProps(5)} />}

                    </Tabs>
                    <TabsUserGuide value={value} index={0} />

                    {user?.roles.includes('PM') && (
                        <>
                            <TabsUserGuide value={value} index={1} >
                                <GuideStepper type='dashboardPM' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={2}>
                                <GuideStepper type='audioDescriber' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={3}>
                                <GuideStepper type='audioNarrator' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={4}>
                                <GuideStepper type='qualityControl' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={5}>
                                <GuideStepper type='client' />
                            </TabsUserGuide>
                        </>
                    )}
                    {(user?.roles.includes('AD')) && (
                        <>
                            <TabsUserGuide value={value} index={1} >
                                <GuideStepper type='dashboard' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={2}>
                                <GuideStepper type='audioDescriber' />
                            </TabsUserGuide>
                            {user?.roles.includes('AN') && (
                                <TabsUserGuide value={value} index={3}>
                                    <GuideStepper type='audioNarrator' />
                                </TabsUserGuide>
                            )}
                        </>
                    )}

                    {user?.roles.includes('AN') && (
                        <>
                            <TabsUserGuide value={value} index={1} >
                                <GuideStepper type='dashboard' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={2}>
                                <GuideStepper type='audioNarrator' />
                            </TabsUserGuide>
                        </>
                    )}

                    {user?.roles.includes('QC') && (
                        <>
                            <TabsUserGuide value={value} index={1} >
                                <GuideStepper type='dashboard' />
                            </TabsUserGuide>
                            <TabsUserGuide value={value} index={2}>
                                <GuideStepper type='qualityControl' />
                            </TabsUserGuide>
                        </>
                    )}

                    {(user?.roles.includes('CLIENT') || (window.location.href.includes('client')) && !user?.roles.includes('PM')) && (
                        <>
                            <TabsUserGuide value={value} index={1}>
                                <GuideStepper type='client' />
                            </TabsUserGuide>
                        </>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default ModalUserGuide