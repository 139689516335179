import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './TabPanel.module.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        className={styles.tabPanel}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


  export function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      sx: {
        width: '100%', flexDirection: 'row', justifyContent: 'flex-start', minHeight: '50px',
        //cuando este activo el tab se cambia el color del fondo 
        '&.Mui-selected': {
          backgroundColor: 'var(--orange)',
          color: 'var(--text-color)',
        },
      },
    };
  }

export default TabPanel;
