import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Alert, Snackbar } from "@mui/material";
import { useContext, useEffect, useRef, useState } from 'react';
import { DataDescriptor } from '../../context/DataDescriptor';
import styles from "../../css/ClientReview.module.css";


export const ClientReview = () => {

    const { currentRegion } = useContext(DataDescriptor);
    const clientCommentsRef = useRef(null);

    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState("");
    const [statusSave, setStatusSave] = useState(true);


    // todo: --------- Manage Snackbar and save QC comments ----------------
    const handleSaveComment = () => {
        //console.log("save");

        if (statusSave) {
            //console.log("Review saved");
            setMessage("Review saved");
            setOpenToast(true);
        } else {
            //console.log("Error Saving Review");
            setMessage("Error Saving Review");
            setOpenToast(true);
        }
    };

    // todo: ------------------------Manage Client comments------------------------

    const putReviewSegment = async (data, region) => {

        console.log("putReviewSegment",region);
        if(region?.data?.caption){
            const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${region?.data?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();
    
            return result;

        }else{
        const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${region?.data?.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();

        return result;
        }
    };



    const handleTextAreaComments = async (e) => {
        let value = e !== undefined ? e.target.value : currentRegion?.data?.commentClient;
        try {
            const result = await putReviewSegment(
                {
                    commentClient: value,
                },
                currentRegion
            );

            if (result) {
                currentRegion?.update({
                    data: {
                        ...currentRegion.data,
                        commentClient: value,
                    }
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    // todo: -----------------------Client Review Data from DB-----------------------
    // Read data from DB and color selected region
    useEffect(() => {
        if (currentRegion) {
            clientCommentsRef.current.value = currentRegion?.data?.commentClient ? currentRegion?.data?.commentClient : '';
            currentRegion.update({ color: "rgba(255, 199, 0, 0.3)" });
        }
    }, [currentRegion]);

    // // todo: ------------------------Read description from DB------------------------
    // useEffect(() => {
    //     (async () => {
    //         const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
    //         const result = await response.json();
    //         const resultID = result.filter(segment => segment._id === currentRegion.data.id);

    //         setCurrentDescription(resultID[0].description);
    //         // setCurrentDescription({ initial: "Temporal Description" });
    //         //console.log(resultID[0].description);
    //     })();
    // }, [currentRegion]);

    return (
        <div className={styles.c_container}>
            {/* <div id="buttons_container" className={styles.div__btnsContainer}>
                <button
                    id='descriptionBtn'
                    className={styles.btn__description}
                    aria-label='Read description,'
                    aria-describedby='current-description'
                    tabIndex={0}
                >
                    <TextSnippetOutlinedIcon />
                    <p >&nbsp;DESCRIPTION&nbsp;</p>
                </button>
            </div >

            <p id='current-description' aria-label={'current description is,' + currentDescription?.initial + ". Shortcut to focus: Alt + X"} className={styles.n_content} tabIndex="0">
                {currentDescription.initial ? currentDescription.initial : "No description available (New Region)"}
            </p> */}



            <div className={styles.clientComments__title}>
                <h2 className={styles.clientComments__title2}>Comments</h2>
                <button onClick={handleSaveComment} aria-label="Save Quality Control Review">
                    <SaveOutlinedIcon />
                </button>
            </div>
            <textarea
                ref={clientCommentsRef}
                // disabled={singleApproved || (singleApproved && allApproved)}
                // value={singleApproved || (singleApproved && allApproved) ? "": undefined}
                placeholder="Type your feedback about this segment..."
                onChange={handleTextAreaComments}
                className={styles.textArea_description}
            />


            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={() => setOpenToast(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity={statusSave ? "success" : "error"}>{message}</Alert>
            </Snackbar>

        </div >


    )
}
