import { updateBlobSegment } from "./analysisAudio";

export const generateBlobfromURL=async(url,wavesurfer,currentRegion)=>{
    await fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
    const blobAudio = new Blob([blob], { type: "audio/webm" });
    //console.log("blobAudio",blobAudio);
    const blobURLAudio = URL.createObjectURL(blobAudio);
    //console.log("blobURLAudio",blobURLAudio);
    wavesurfer.current.load(blobURLAudio);
    updateBlobSegment(currentRegion, blobURLAudio);

    }).catch((err) => {
      //console.log(err);
    });
    

  }

  export const generateBlobfromURLData = async (url) => {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
  
      const blobAudio = new Blob([blob], { type: "audio/webm" });
      const blobURLAudio = URL.createObjectURL(blobAudio);
      //console.log("blobURLAudio", blobURLAudio);
      return blobURLAudio;
    } catch (err) {
      //console.log(err);
    }
  };

  export const removeBlobURL = (blobURL) => {
    URL.revokeObjectURL(blobURL);
  }