import { Textarea } from "@mui/joy";
import { Box, Button, Switch } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styles from "../../css/Form.module.css";
import { uploadResources, uploadResourcesDubbing } from "../../utils/dataProjectManager";
import { FormDialog } from "./FormDialog";

export const FormUploadResources = ({
  typeOfService,
  handleChange,
  guidelines,
  setGuidelinesProject,
  createProjectWithDataAndResources,
  open,
  handleClose,
  setFileVideo,
  setFileAudio,
  fileVideo,
  setSegments,
  setSwitchCaptions,
  switchCaptions,
  setSwitchNeedExternalAudio,
  switchNeedExternalAudio
}) => {
  const fileTypesVideo = ["mp4"];
  const fileTypesAudio = ["wav"];
  const fileTypesCaptions = ["srt"];
  const [IUploadResource, setIUploadResource] = useState(null);

  const toggleSwitchCaptions = () => setSwitchCaptions(!switchCaptions)
  const toggleSwitchNeedExternalAudio = () => setSwitchNeedExternalAudio(!switchNeedExternalAudio)

  useEffect(() => {
    if (typeOfService === "dubbing") {
      setIUploadResource(uploadResourcesDubbing)
    }
    else {
      setIUploadResource(uploadResources)
    }
    setFileAudio(null)
    setFileVideo(null)
    setSegments(null)
  }, [])

  return (
    <Box
      className={styles.container__form}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <h2>Upload resources</h2>
      <div className={styles.label_spaceBetween}>
        <label className={styles.label}>Captions</label>
        <Switch aria-label="checked" onChange={toggleSwitchCaptions} checked={switchCaptions} />
      </div>
      <div className={styles.label_spaceBetween}>
        <label className={styles.label}>External Audio</label>
        <Switch aria-label="checked" onChange={toggleSwitchNeedExternalAudio} checked={switchNeedExternalAudio} />
      </div>

      {typeOfService &&
        IUploadResource?.map(({ name, type }, index) => (
          <div key={index} className={styles.container__inputNarrator}>
            {type === "file" && (
              <>
                <label className={styles.label} htmlFor={name}>
                  {name}*
                </label>
                <FileUploader
                  handleChange={(e) => handleChange(name, e)}
                  name="file"
                  types={
                    name === "Video"
                      ? fileTypesVideo
                      : fileTypesCaptions
                  }
                  multiple={false}
                  required={true}
                />
                {/* mostrar el nombre del archivo que se cargo */}
                { name === "Video" && fileVideo && <p className={styles.labelVideo}>Video: {fileVideo.name}</p>}
              </>
            )}
          </div>
        ))}
      {
        switchCaptions &&
        <div className={styles.container__inputNarrator}>
          <label className={styles.label} >SRT*</label>
          <FileUploader
            handleChange={(e) => handleChange("Caption", e)}
            name="file"
            types={fileTypesCaptions}
            multiple={false}
            required={true}
          />

          <label className={styles.label} >Audio</label>
          <FileUploader
            handleChange={(e) => handleChange("Audio", e)}
            name="file"
            types={fileTypesAudio}
            multiple={false}
            required={true}
          />
        </div>
      }

      <label className={styles.label} htmlFor={name} style={{ marginTop: "1rem" }} >
        Guidelines*
      </label>
      <Textarea
        id='Guidelines*'
        sx={{ backgroundColor: "white" }}
        value={guidelines}
        onChange={(e) => setGuidelinesProject(e.target.value)}
        placeholder="Type in here…"
        variant="outlined"
        minRows={3}
        maxRows={3}
      />
      <Button
        variant="contained"
        size="medium"
        onClick={createProjectWithDataAndResources}
        sx={{
          color: "white",
          background: " linear-gradient(180deg, #582A50 0%, #A93F90 100%)",
          padding: ".7rem",
          fontSize: "1rem",
          marginBottom: "1rem",
          borderRadius: "10px",
          marginTop: "1rem",
        }}
      >
        Create Project
      </Button>
      <FormDialog open={open} handleClose={handleClose} />
    </Box>
  );
};
