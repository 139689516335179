import { Button, CircularProgress, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
export const ModalCheckSend = ({ open, setOpen, sendProject, disabled }) => {

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          {window.location.href.includes('/client/') ?
            "This will send your comments to Quality Control, \n Would you like to send the project?" :
            'Would you like to send the project?'}
        </DialogTitle>
        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {disabled ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                onClick={() => setOpen(false)}
                sx={{ color: "white", backgroundColor: "#952937" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => sendProject()}
                autoFocus
                sx={{ color: "white", backgroundColor: "#8EC36A" }}
              >
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};