
const generateMixAudio = async (id) => {
    // {{url}}/api/projects/mix/64ad94894f32f10094c96e77 POST 
    const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/mix/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    });
   
    try {
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }finally{
        console.info('end generateMixAudio');
    }
    
};


export { generateMixAudio };
