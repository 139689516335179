import { secondsToSubrip, subripToSeconds } from "../time/time";
import { countWords } from "./words";

export const putAudioProject = async (data, id) => {
  const resp=await fetch(`${process.env.REACT_APP_URL}/api/projects/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await resp.json();
  return result;
};

export const putDescriptionSegment = async (data, id) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};
export const putCaptionSegment = async (data, id) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export const putScriptSegmentSign = async (data, id) => {

  const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsSign/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  // console.log("putScriptSegmentSign", response);
  const result = await response.json();
  return result;
};

export const editAnnotation = (
  user,
  region,
  setCurrentRegion,
  setStartHH,
  setStartMM,
  setStartSS,
  setStartMS,
  setEndHH,
  setEndMM,
  setEndSS,
  setEndMS,
  setDuration,
  setDescription
) => {
  setCurrentRegion(null);
  const start = secondsToSubrip(region.start);
  const end = secondsToSubrip(region.end);

  setStartHH(start[0]);
  setStartMM(start[1]);
  setStartSS(start[2]);
  setStartMS(start[3]);
  setEndHH(end[0]);
  setEndMM(end[1]);
  setEndSS(end[2]);
  setEndMS(end[3]);

  setDuration(region.end - region.start);
  setCurrentRegion(region);
  setDescription(region.data?.description);

  if (!window.location.href.includes("/quality-control/") && !window.location.href.includes("/narrator") && !window.location.href.includes("/client")) {
    region.update({
      drag: true,
    });
  }
  region.updateRender();
};

export const updateCommentsSegment = async (
  region,
  comments,
  startTime,
  endTime
) => {
  let start = subripToSeconds(
    `${startTime.hours}:${startTime.minutes}:${startTime.seconds},${startTime.milliseconds}`
  );
  let end = subripToSeconds(
    `${endTime.hours}:${endTime.minutes}:${endTime.seconds},${endTime.milliseconds}`
  );
  const data = { ...region.data, comment: comments };

  region.update({
    start: start,
    end: end,
    data,
  });
  await putDescriptionSegment(region.data, region.data.id);
};

export const updateDescriptionSegment = async (
  region,
  descripcion,
  startTime,
  endTime,
  setWordsCount,
  setDuration,
  setCharactersCount,
  setRecommendCharacterMin,
  setRecommendCharacterMax
) => {
  let start = subripToSeconds(
    `${startTime.hours}:${startTime.minutes}:${startTime.seconds},${startTime.milliseconds}`
  );
  let end = subripToSeconds(
    `${endTime.hours}:${endTime.minutes}:${endTime.seconds},${endTime.milliseconds}`
  );

  const data = {
    ...region.data,
    description: {
      ...region.data.description,
      initial: descripcion,
    }
  };

  region.update({
    start: region.start,
    end: region.end,
    data,
  });

  setWordsCount(countWords(data.description.initial));
  setDuration(end - start);
  recommendCharacterCount(
    data.description.initial,
    end - start,
    setCharactersCount,
    setRecommendCharacterMin,
    setRecommendCharacterMax
  );
  await putDescriptionSegment(data, region.data.id);
};

export const recommendCharacterCount = (
  text,
  segmentDurationInSeconds,
  setCharactersCount,
  setRecommendCharacterMin,
  setRecommendCharacterMax
) => {
  // Calculate recommended characters per second
  const recommendedCharactersPerSecond = 6;
  const recommendedCharactersPerSecondMax = 12;
  const recommendedCharacterCountMin = recommendedCharactersPerSecond * segmentDurationInSeconds;
  const recommendedCharacterCountMax = recommendedCharactersPerSecondMax * segmentDurationInSeconds;

  if (text === undefined) {
    text = "";
  }
  setCharactersCount(text.length);
  setRecommendCharacterMin(recommendedCharacterCountMin);
  setRecommendCharacterMax(recommendedCharacterCountMax);
};
export const showNote = (region, setCaption) => {
  if (region.data?.caption) {
    setCaption(region.data?.caption);
  } else {
    setCaption(region.data?.description?.initial);
  }
}

export const hideNote = (region, setCaption) => {
  setCaption("");
}

