import { Parser } from "@json2csv/plainjs";
import React from "react";
import * as srtparsejs from "srtparsejs"; // cjs

import "../App.css";
import { Footer } from "../components/common/Footer";
import styles from "../css/Header.module.css";
export const SRTtoCSV = () => {

  const [isGenerate, setIsGenerate] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const handleChange = (e) => {
    setIsGenerate(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      const data = e.target.result;
      const json = srtparsejs.parse(data);
      const cleanSegmentsRttm = cleanRttm(json);

      const emptySegments = generateEmptySegmentsArray(cleanSegmentsRttm);
      const joinSegments = joinedSegments(cleanSegmentsRttm, emptySegments);
      const joinSegmentsEmpty = mergeEmptySegments(joinSegments);
      const result = parseTimeJson(joinSegmentsEmpty);

      //generar el csv
      try {
        const parser = new Parser();
        const csv = parser.parse(result);
        setFile(csv);
        setFileName(`${file.name.split(".")[0]}_descriptor.csv`);
        //generar blob para descargar
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", `${file.name.split(".")[0]}_descriptor.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsGenerate(true);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    //reset input file value
  };
  const joinedSegments = (array1, array2) => {
    const newArray = [...array1, ...array2];
    newArray.sort((a, b) => a.start - b.start);
    return newArray;
  };
  const mergeEmptySegments = (arr) => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].speaker === "empty") {
        if (i + 1 < arr.length && arr[i + 1].speaker === "empty") {
          arr[i].duration = arr[i + 1].stop - arr[i].start;
          arr[i].stop = arr[i + 1].stop;
          arr[i].text += " " + arr[i + 1].text;
          arr.splice(i + 1, 1);
          i--;
        }
      }
    }
    //retornar  solo los segmentos que tienen speaker === empty
    arr.forEach((element) => {
      if (element.speaker === "empty") {
        newArray.push(element);
      }
    });
    return newArray;
  };
  const generateEmptySegmentsArray = (originalArray) => {
    // //console.log(originalArray);
    const newArray = [];
    //primer elemento del arreglo es el tiempo inicial 0 y el primero elmento el tiempo inicial
    if (parseFloat(originalArray[0].start) > 2) {
      newArray.push({
        start: 0,
        stop: parseFloat(originalArray[0].start),
        duration: parseFloat(originalArray[0].start),
        speaker: "empty",
      });
    }
    //iterar sobre el arreglo original

    for (let i = 0; i < originalArray.length - 1; i++) {
      //si el tiempo entre el segmento y el siguiente es mayor a 2 segundo
      if (
        parseFloat(originalArray[i + 1].start) -
          parseFloat(originalArray[i].stop) >
        2
      ) {
        const emptySegment = {
          start: parseFloat(originalArray[i].stop),
          stop: parseFloat(originalArray[i + 1].start),
          duration:
            parseFloat(originalArray[i + 1].start) -
            parseFloat(originalArray[i].stop),
          speaker: "empty",
        };

        newArray.push(emptySegment);
      }
    }

    return newArray;
  };
  const subripToSeconds = (subripTime) => {
    const [hours, minutes, seconds] = subripTime.split(":");
    const [secondsPart, millisecondsPart] = seconds.split(",");
    const totalSeconds =
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(secondsPart, 10) +
      parseInt(millisecondsPart, 10) / 1000;
    //console.log(subripTime + "-" + totalSeconds + " segundos");
    return totalSeconds;
  };
  function secondsToSubrip(seconds) {
    var hour = Math.floor(seconds / 3600);
    var minute = Math.floor((seconds % 3600) / 60);
    var second = Math.floor(seconds % 60);
    var millisecond = Math.floor((seconds % 1) * 1000);

    return (
      (hour > 9 ? hour : "0" + hour) +
      ":" +
      (minute > 9 ? minute : "0" + minute) +
      ":" +
      (second > 9 ? second : "0" + second) +
      "," +
      (millisecond > 99
        ? millisecond
        : millisecond > 9
        ? "0" + millisecond
        : "00" + millisecond)
    );
  }
  const cleanRttm = (array) => {
    //console.log("empieza limpieza");
    let cont = 0;
    const newArray = [];

    for (let i = 0; i < array.length; i++) {
      //puedes hacer una expresion regular para verificar si tiene los siguientes caracteres = ♪,[,] en js si los tiene retornar true
      let speaker = "spk_none";
      const regexValidText = /♪|\[|\]/g;
      if (regexValidText.test(array[i].text)) {
        speaker = "empty";
        cont++;
      }

      newArray.push({
        start: subripToSeconds(array[i].startTime),
        stop: subripToSeconds(array[i].endTime),
        duration:
          subripToSeconds(array[i].endTime) -
          subripToSeconds(array[i].startTime),
        speaker: speaker,
        text: array[i].text,
      });
    }
    //console.log("fin de limpieza", newArray, cont);
    return newArray;
  };

  const parseTimeJson = (array) => {
    const newArray = [];
    array.forEach((element) => {
      if (element.duration >= 2) {
        newArray.push({
          start: secondsToSubrip(element.start),
          stop: secondsToSubrip(element.stop),
          //duracion en segundos al entero mas cercano
          duration: Math.round(element.duration),
          text: element.text ? element.text : "Don't have text",
        });
      }
    });
    return newArray;
  };
  const handleDownload = () => {
    const blob = new Blob([file], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${fileName}_descriptor.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="container_srttocsv">
      <header>
        <div className={styles.container__logo}>
          <img src="/assets/img/Logo1.png" alt="Logo All 4 VOICING" />
        </div>
      </header>
      <div className="container_download">
        <div class="file-upload">
          <label>
            <input type="file" name="file" onChange={(e) => handleChange(e)} />

            <span>Seleccionar archivo</span>
          </label>
        </div>
        {isGenerate && (
          <button className="container__button" onClick={handleDownload}>
            Descargar {fileName}
          </button>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
};
