import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OngoingProjects } from "../components/dashboard/OngoingProjects";
import { ProjectsActions } from "../containers/ProjectsActions";
import { ProjectsList } from "../containers/ProjectsList";
import { DataUser } from "../context/DataUser";
import styles from "../css/Dashboard.module.css";

export const Dashboard = () => {
  const { projects, setProjects, projectsOriginal, setProjectsOriginal, setCurrentProject, user, getProyects, loading, setLoading,projectsFilter } = useContext(DataUser);  

  const navigate=useNavigate();

  useEffect(() => {
    setCurrentProject("");
    setLoading(true);
  }, []);

  useEffect(() => {
    if (user?.roles) {
      setProjects([]);
      setProjectsOriginal([]);
      //si es PM
      if (user?.roles.includes("PM")) {
        getProyects(0, true);
      } else if (user?.roles.includes("QC")) {
        getProyects(0, true, "QC");
      }else{
        getProyects(0, true);
      }
    }else{
      if (!localStorage.getItem('token') && !user?.uid ) {
        
        navigate('/login');
      }
      
    }
  }, [user]);
console.log(projectsOriginal);
  useEffect(() => {
    setProjects(projectsOriginal);
    

    let timeoutId;

    if (projects?.length > 0) {
      const projectsWithoutSegments = projects.filter(project => project.segments.length === 0);
      if (projectsWithoutSegments.length > 0) {
        timeoutId = setTimeout(() => {
          getProyects();
        }, 120000); // 2 minutos
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [projects]);



  return (
    <div className={styles.container}>

      {user?.roles &&
        <OngoingProjects className={styles.container_stagging} roles={user?.roles} user={user} setProjects={setProjects} projects={projects} projectsOriginal={projectsOriginal} projectsFilter={projectsFilter} />}

      {user?.roles && user?.roles.includes("PM") ? (
        <ProjectsActions projects={projects} projectsOriginal={projectsOriginal} loading={loading} />
      ) : (
      <>
       
          {(user?.roles?.length) &&
        <ProjectsList tabIndex={2} user={user} projects={projects} loading={loading} className={styles.container_cards} autoFocus />  }
        </>
      
      )}

    </div>
  );
};
