const stepsAN = [
  {
    label: 'Explore the Timeline',
    description: 'At the bottom of the screen, you will find a timeline displaying various regions of the project. <img style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-1.webp" />',
  },
  {
    label: 'Navigate Between Regions',
    description: [
      '<div style ><ul> <li>- Use the left and right arrows to navigate between different regions on the timeline.</li> <li>- Alternatively, scroll through time by holding down outside the regions and dragging.</li><li>- Click directly on regions for precise navigation.</li> </ul> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-2.webm" autoplay loop muted></video> </div>'

    ],
  },
  {
    label: 'View Descriptions',
    description: 'When in a region, the corresponding description will be displayed in the description container. <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-3.webm" autoplay loop muted></video>',
  },
  {
    label: 'Activate Narration Component',
    description: [
      '<ul> <li>- When in a region, the narration component will automatically activate, allowing you to record the segment\'s description.</li> <li>- Two buttons will be available: one for recording and another for playback. Initially, only the recording button will be active.</li> <li>- After recording, you can play back the recorded narration.</li> </ul> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-4.webm" autoplay loop muted></video>'
    ],
  },
  {
    label: 'Record Description',
    description: [
      '<ul> <li>- Press the recording button to start recording your description of the current region.</li> <li>- After recording, the system will perform an audio analysis.</li> <li>- If the system suggests adjustments, you will have the option to re-record with a higher or lower volume as recommended.</li> <li>- During recording, you will see a decibel meter to monitor your voice volume, ensuring it is within the range of -18 to -6 dB.</li> </ul> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-5.webm" autoplay loop muted></video>'
    ],
  },
  {
    label: 'Complete All Recordings',
    description: 'Repeat the above steps for all regions of the project. <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-6.webm" autoplay loop muted></video>',
  },
  {
    label: 'Send Recordings',
    description: 'Once all recordings are complete and within an acceptable range, the "Send" button will activate. <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-7.webm" autoplay loop muted></video>',
  },
];

const stepsDASH = [
  {
    label: 'Dashboard View',
    description: `
          <p>After logging in, you will be directed to the Dashboard view.</p>
          <p>You will find several cards, each representing a project, with information such as the project name, project guidelines, end date, and assigned role.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH-1.webm" autoplay loop muted></video>`
  },
  {
    label: 'Filter Projects',
    description: `
          <p>On the left, you will see three filter options to organize projects:</p>
          <ul>
            <li><strong>"New":</strong> Displays newly created projects.</li>
            <li><strong>"In Progress":</strong> Shows projects that are currently in development.</li>
            <li><strong>"To Review":</strong> Presents projects that are ready for review.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH-2.webm" autoplay loop muted></video>`
  },
  {
    label: 'Open Project',
    description: `
        <p>Click on a project card to be redirected to the corresponding project based on your assigned role.</p> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH-3.webm" autoplay loop muted></video>`
  },
];

const stepsCLT = [
  {
    label: 'Explore the Timeline:',
    description: `
          <p>At the bottom of the screen, you will find a timeline displaying various audio and description regions of the project.</p> <img style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-1.webp" />`
  },
  {
    label: 'Navigate Between Regions:',
    description: `
          <p>Use the arrows to navigate between different regions or segments of the timeline.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-2.webm" autoplay loop muted></video>
          <p>If you are reviewing audio and description, you can view each description corresponding to the current region and provide comments.</p> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-2_2.webm" autoplay loop muted></video>`
  },
  {
    label: 'Listen to Narration (if applicable):',
    description: `
          <p>If you are reviewing a narration region, you can listen to the narration of the current segment.</p>
          <p>It provides the ability to make comments for specific corrections in that segment.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-3.webm" autoplay loop muted></video>`
  },
  {
    label: 'Timeline Features:',
    description: `
          <p>Zoom in and out to adjust the timeline scale according to your needs.</p>
          <p>Use standard playback controls: play, pause, stop, forward, and rewind by frames.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-4.webm" autoplay loop muted></video>`
  },
  {
    label: 'Submit Comments:',
    description: `
          <p>Provide comments on the descriptions or narrations of the segments as needed.</p>
          <p>Use the "Submit" button to communicate your comments and corrections to the corresponding team.</p> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-5.webm" autoplay loop muted></video>`
  }
];

const stepsQC = [
  {
    label: 'Explore the Timeline:',
    description: `
          <p>At the bottom of the screen, you will find a timeline displaying various audio description regions of the project.</p>
          <img style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-1.webp" />`,
  },
  {
    label: 'Navigate Between Regions',
    description: `
          <ul>
            <li>Use the left and right arrows to navigate between different regions on the timeline.</li>
            <li>Alternatively, scroll through time by holding down outside the regions and dragging.</li>
            <li>Click directly on regions for precise navigation.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-2.webm" autoplay loop muted></video>`,
  },
  {
    label: 'View Descriptions and Edit',
    description: `
          <p>When you are in a region, the corresponding description will appear in the description container. How Quality Control can edit the description made by the descriptor.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepQC-3.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Review and Comment',
    description: `
          <ul>
            <li>Review descriptions and narrations in each region.</li>
            <li>Add comments for corrections or improvements.</li>
            <li>No option to cut segments or perform test descriptions.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepQC-4.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Manage Regions',
    description: `
          <ul>
            <li>Use the actions menu to add or delete regions.</li>
            <li>Change the duration and position of regions while correcting AD segments.</li>
            <li>Approval option for all segments.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepQC-5.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Approve Segments',
    description: 'After reviewing and making necessary corrections, use the "Approve" option to finalize the segments.<video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepQC-6.webm" autoplay loop muted></video>',
  },
];

const stepsAD = [
  {
    label: 'Explore the Timeline:',
    description: `
          <p>At the bottom of the screen, you will find a timeline displaying various regions of the project.</p> <img style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepCLT-1.webp" />`
  },
  {
    label: 'Navigate Between Regions',
    description: `
          <ul>
            <li>Use the left and right arrows to navigate between different regions on the timeline.</li>
            <li>Alternatively, scroll through time by holding down outside the regions and dragging.</li>
            <li>Click directly on regions for precise navigation.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAN-2.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Edit Region Details',
    description: `
          <p>When in a region, edit the initial and final time in SubRip format. Use the text area to describe the region and provide annotations for the narrator.</p> <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAD-3.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Perform Test Descriptions',
    description: `
          <ul>
            <li>In the actions menu, there is an option to perform a test description by recording the entered details.</li>
            <li>This allows the describer to review and refine their description before finalizing.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAD-4.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Manage Regions',
    description: `
          <ul>
            <li>Use the actions menu to add new regions. A segment of two seconds will be created at the current timeline position.</li>
            <li>If a new segment overlaps with an existing one, an error will be displayed.</li>
            <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAD-5.webm" autoplay loop muted></video>
            <li>There is an option to cut segments, allowing you to trim the selected region.</li>
            <li>The menu also provides options to delete segments and force saving.</li>
          </ul>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAD-6.webm" autoplay loop muted></video>`,
  },
  {
    label: 'Save and Finalize',
    description: `
          <p>Ensure all edits and descriptions are complete. The "Save" button will be available to save the changes made by the describer.</p>
          <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepAD-7.webm" autoplay loop muted></video>`,
  },
];

const stepsDASH_PM = [
  {
    label: 'Dashboard View',
    description: `
      <p>After logging in, you will be directed to the Dashboard view.
      You will find several cards, each representing a project, with information such as the project name, project guidelines, completion date and current progress role, percentage and assigned persons.</p>
      <img style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH_PM-1.webp" />`
  },
  {
    label: 'Filter Projects',
    description: `
      <p>On the left, you will see options to filter projects by roles or categories:</p>
      <ul>
        <li><strong>"Describer":</strong> Displays projects assigned to Describers.</li>
        <li><strong>"Narrator":</strong> Shows projects assigned to Narrators.</li>
        <li><strong>"Audio Editor":</strong> Displays projects assigned to Audio Editors.</li>
        <li><strong>"Dubbing":</strong> Shows projects assigned to Dubbing roles.</li>
        <li><strong>"Quality Control":</strong> Presents projects assigned to Quality Control roles.</li>
        <li><strong>"Completed":</strong> Displays Completed projects.</li>
      </ul>
      <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH_PM-2.webm" autoplay loop muted></video>`
  },
  {
    label: 'Open Project',
    description: `
      <p>By clicking on a project card to be redirected to the project where the progress bar is according to the current role.</p>
      <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH_PM-3.webm" autoplay loop muted></video>`
  },
  {
    label: 'Project Management Actions',
    description: `
      <p>For each project, the PM has a menu of actions:</p>
      <ul>
        <li><strong>Edit Project:</strong> Open a modal to edit project settings, such as name and end date, and update assigned roles.</li>
        <li><strong>Delete Project:</strong> Remove the project from the dashboard.</li>
        <li><strong>Download Resources:</strong> Access options to download SRT, final audio, and audio mix.</li>
        <li><strong>Project Status Indicator:</strong> Displays project status - green (segments present), yellow (in process), red (missing audio).</li>
      </ul>
      <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH_PM-4.webm" autoplay loop muted></video>`
  },
  {
    label: 'Edit Project Details',
    description: `
      <p>In the "Edit Project" modal, the PM can:</p>
      <ul>
        <li>Edit project configuration, including name and end date.</li>
        <li>Update roles assigned to the project.</li>
        <li>Switch to the "Audio Update" tab to update the project's audio and download resources.</li>
        <li>Visit the "Progress" tab to view the current project progress.</li>
      </ul>
      <video aria-hidden="true" style="margin-top: 3rem; width: 100%" src="/assets/img/user-guide/stepDASH_PM-5.webm" autoplay loop muted></video>`
  }
]

export { stepsAD, stepsAN, stepsCLT, stepsDASH, stepsDASH_PM, stepsQC };

