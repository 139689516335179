import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Stack from "@mui/joy/Stack";
import { Slider, StepLabel, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import style from "../../css/ProgressProject.module.css";

export const ProgressProject = ({ project }) => {
  console.log("project", project);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressStep, setProgressStep] = useState(0);
  const [isDateOver, setIsDateOver] = useState(false);
  const hasVoiceOver = project?.users?.some((user) => user?.role === 'AN_D');

  useEffect(() => {
    if (project.service === "dubbing") {
      setSteps([
        {
          label: "AN_D",
          progress: 25
        },
        {
          label: "QC2",
          progress: 50
        },
        {
          label: "AE",
          progress: 75
        },
        {
          label: "DONE",
          progress:100
        }
      ]);
    } else if (project.service === "sign-language-caption") {
      setSteps([
        {
          label: "SCRIPT_ADAPTER",
          progress: 33.3
        },
        {
          label: "INTERPRETER",
          progress: 66.6,
        },
        {
          label: "AE",
          progress: 100
        }
      ]);
    } else {
      const [AD, AN] = project?.client ? project.client : [false, false];

      if (AN && AD) {
        if (hasVoiceOver) {
          setSteps([{
            label: "AD",
            progress: 11.11
          }, {
            label: "QC",
            progress: 11.11 * 2
          }, {
            label: "CLIENT",
            progress: 11.11 * 3
          }, {
            label: "AN",
            progress: 11.11 * 4
          }, {
            label: "AN_VO",
            progress: 11.11 * 5
          }, {
            label: "AE",
            progress: 11.11 * 6
          }, {
            label: "QC2",
            progress: 11.11 * 7
          }, {
            label: "CLIENT2",
            progress: 11.11 * 8
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        } else {
          setSteps([{
            label: "AD",
            progress: 12.5
          }, {
            label: "QC",
            progress: 12.5 * 2
          }, {
            label: "CLIENT",
            progress: 12.5 * 3
          }, {
            label: "AN",
            progress: 12.5 * 4
          }, {
            label: "AE",
            progress: 12.5 * 5
          }, {
            label: "QC2",
            progress: 12.5 * 6
          }, {
            label: "CLIENT2",
            progress: 12.5 * 7
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        }
      } else if (AN && !AD) {
        if (hasVoiceOver) {
          setSteps([{
            label: "AD",
            progress: 12.5
          }, {
            label: "QC",
            progress: 12.5 * 2
          }, {
            label: "AN",
            progress: 12.5 * 3
          }, {
            label: "AN_VO",
            progress: 12.5 * 4
          }, {
            label: "AE",
            progress: 12.5 * 5
          }, {
            label: "QC2",
            progress: 12.5 * 6
          }, {
            label: "CLIENT2",
            progress: 12.5 * 7
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        } else {
          setSteps([{
            label: "AD",
            progress: 14.28
          }, {
            label: "QC",
            progress: 14.28 * 2
          }, {
            label: "AN",
            progress: 14.28 * 3
          }, {
            label: "AE",
            progress: 14.28 * 4
          }, {
            label: "QC2",
            progress: 14.28 * 5
          }, {
            label: "CLIENT2",
            progress: 14.28 * 6
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        }
      } else if (AD && !AN) {
        if (hasVoiceOver) {
          setSteps([{
            label: "AD",
            progress: 16.66
          }, {
            label: "QC",
            progress: 16.66 * 2
          }, {
            label: "CLIENT",
            progress: 16.66 * 3
          }, {
            label: "AN",
            progress: 16.66 * 4
          }, {
            label: "AN_VO",
            progress: 16.66 * 5
          }, {
            label: "AE",
            progress: 16.66 * 6
          }, {
            label: "QC2",
            progress: 16.66 * 7
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        } else {
          setSteps([{
            label: "AD",
            progress: 14.28
          }, {
            label: "QC",
            progress: 14.28 * 2
          }, {
            label: "CLIENT",
            progress: 14.28 * 3
          }, {
            label: "AN",
            progress: 14.28 * 4
          }, {
            label: "AE",
            progress: 14.28 * 5
          }, {
            label: "QC2",
            progress: 14.28 * 6
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        }
      } else {
        if (hasVoiceOver) {
          setSteps([{
            label: "AD",
            progress: 14.28
          }, {
            label: "QC",
            progress: 14.28 * 2
          }, {
            label: "AN",
            progress: 14.28 * 3
          }, {
            label: "AN_VO",
            progress: 14.28 * 4
          }, {
            label: "AE",
            progress: 14.28 * 5
          }, {
            label: "QC2",
            progress: 14.28 * 6
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        }
        else {
          setSteps([{
            label: "AD",
            progress: 16.66
          }, {
            label: "QC",
            progress: 16.66 * 2
          }, {
            label: "AN",
            progress: 16.66 * 3
          }, {
            label: "AE",
            progress: 16.66 * 4
          }, {
            label: "QC2",
            progress: 16.66 * 5
          },
          {
            label: "DONE",
            progress:100
          }
        ]);
        }
      }
    }
  }, [project.service, project.client]);

  useEffect(() => {
    if (steps.length > 0) {

      if(project.stage.state === "completed") {
        setStep(steps.length - 1);
        setProgressStep(100);
        return;
      }

      const indexStep = steps.findIndex((step) => step.label === project.rol.role);
      if (indexStep !== -1) {
        setStep(indexStep);
        setProgressStep(steps[indexStep].progress.toFixed(1));
      } else {
        console.warn("rol not found", project.rol.role, project);
      }
      progressDate();
    }
  }, [project.rol.role, steps]);

  const progressDate = () => {
    const currentDate = new Date();
    const startDate = new Date(project.start_date);
    const endDate = new Date(project.end_date);
    const totalTime = endDate.getTime() - startDate.getTime();
    const elapsedTime = currentDate.getTime() - startDate.getTime();
    const progress = Math.floor((elapsedTime / totalTime) * 100);
    const progressValue = Math.max(1, Math.min(progress, 100));
    setProgress(progressValue);
    if (currentDate > endDate) setIsDateOver(true);
  };

  const changeLabel = (label) => {
    switch (label) {
      case "AD":
        return "AD";
      case "QC":
        return "QC_AD";
      case "CLIENT":
        return "CT";
      case "AN":
        return "AN";
      case "QC2":
        return "QC_AN";
      case "CLIENT2":
        return "CT";
      case "AE":
        return "AE";
      case "SCRIPT_ADAPTER":
        return "SCRIPT ADAPTER";
      case "INTERPRETER":
        return "INTERPRETER";
      case "DONE":
        return "DONE"
      default:
        return label;

    }
  }

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 12 }} gutterBottom>
          Progress
        </Typography>
        <Typography sx={{ fontSize: 12 }} gutterBottom>
          {progressStep}%
        </Typography>
      </Box>
      {steps.length > 0 && <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index} sx={{
            '& .MuiStepLabel-root .Mui-active': {
              color: isDateOver ? "#FF0000" : "#1976d2"
            },
            '& .MuiStepLabel-root .Mui-completed': {
              color: isDateOver ? "#FF0000" : "#1976d2"
            },
          }}>
            <StepLabel sx={{ span: { color: "white !important" } }}>{changeLabel(label.label)}</StepLabel>
          </Step>
        ))}
      </Stepper>}
      <Stack
        spacing={2}
        sx={{ flex: 1, mt: 2, color: "white", overflow: "initial", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div className={style.dates}>
          <RadioButtonUncheckedIcon sx={{ color: isDateOver ? "#FF0000" : "#12B4B8" }} />
          <Slider
            className="sliderDate"
            style={{ color: isDateOver ? "#FF0000" : "#12B4B8" }}
            disabled
            value={progress}
            aria-label="Disabled slider"
          />
          <RadioButtonUncheckedIcon sx={{ color: isDateOver ? "#FF0000" : "#12B4B8" }} />
        </div>
        <div className={style.dates_label}>
          <span>{format(new Date(project.start_date), 'MM/dd/yyyy')}</span>
          <span>{format(new Date(project.end_date), 'MM/dd/yyyy')}</span>
        </div>
      </Stack>
    </Box>
  );
};