import React from 'react';

const Forbidden403 = () => {
    return (
        <div style={{color: "white",textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column"}}>
            <h1 style={{display: 'block', width: '100%'}}> 403 Forbidden </h1> 
            <h2>You are not authorized to access this page</h2>
        </div>
    );
};

export default Forbidden403;
