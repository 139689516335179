import { Button, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataQC } from "../../context/DataQC";

const ModalApproveAll = ({ open, setOpen, e }) => {
  const { handleChangeAllApproved } = useContext(DataQC);
  const [openToast, setOpenToast] = useState(false);

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          All Segments Were Approved Successfully!
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          {"Please confirm that you want to approve all"}
        </DialogTitle>

        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            sx={{ color: "white", backgroundColor: "#952937" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              e.target.checked = true;
              handleChangeAllApproved(e);
              setOpenToast(true);
              setOpen(false);
            }}
            autoFocus
            sx={{ color: "white", backgroundColor: "#8EC36A" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalApproveAll;
