import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { AutoGraph } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Button, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { DataUser } from '../../../context/DataUser';
import { generateAudioFinal } from '../../../utils/audio/generateAudiofinal';
import { updateProject } from '../../../utils/project/CRUD';
import { deleteRecord } from '../../../utils/regions/deleteRecord';
import { ProgressProject } from '../../dashboard/ProgressProject';
import TrackingProject from '../../dashboard/TrackingProject';
import TabPanel, { a11yProps } from '../TabPanel/TabPanel';
import DownloadResources from './DownloadResources';
import FormDataProject from './FormDataProject';
import FormResourcesProject from './FormResourcesProject';
import FormRolesProject from './FormRolesProject';
import FormVoiceOver from './FormVoiceOver';
import styles from './ModalEditProject.module.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const ModalEditProject = ({ open, setOpen, project }) => {
  const { getProyects } = useContext(DataUser);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [formData, setFormData] = useState({ isDirty: false, data: {} });
  const [formDataRol, setFormDataRol] = useState({ isDirty: false, data: {} });
  const [updateData, setUpdateData] = useState(false);
  const [updateDataVoiceOver, setUpdateDataVoiceOver] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', type: 'error' });
  const [fileAudio, setFileAudio] = useState(null);
  const [guideline, setGuideline] = useState(null);
  const hasVoiceOver = project?.users?.some((user) => user?.role === 'AN_D'); 
  
  
  const handleChange = (event, newValue) => setValue(newValue);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlert({ open: false, message: '', type: 'error' });
  };

  const combineData = () => setUpdateData(true);
  const addService = () =>setUpdateDataVoiceOver(true)

  useEffect(() => {
    if (open) {
      // Comprueba si hay cambios en ambos estados antes de hacer la actualización
      if ((formData.data._id && formDataRol.data._id) && (formData.isDirty || formDataRol.isDirty)) {
        setLoading(true);
        const data = { ...formData.data, ...formDataRol.data }; // Combina ambos sets de datos
        if (data.name === project.name) delete data.name; // Si no se cambia el nombre, elimínalo
        delete data.__v;
        
        async function update(data) {
          await fetchUpdateProject(data); // Llama a la función que actualiza el proyecto en el backend
        }
        
        update(data); // Ejecuta la actualización
      } else if (formData.data._id && formDataRol.data._id) {
        // Si no hay cambios, resetea los estados y muestra alerta de que no hubo cambios
        setFormDataRol({ isDirty: false, data: {} });
        setFormData({ isDirty: false, data: {} });
        setAlert({ open: true, message: 'No changes were made', type: 'warning' });
        setUpdateData(false);
      }
    }
  }, [formData, formDataRol]);

  const fetchUpdateProject = async (data) => {
    try {
      await updateProject(data);
      setAlert({ open: true, message: 'Project updated successfully', type: 'success' });
      setLoading(false);
      await getProyects(1, true);
    } catch (err) {
      setAlert({ open: true, message: 'Error updating project', type: 'error' });
    } finally {
      setUpdateData(false);
    }
  };

  const uploadAudioProject = async (id, file) => {
    setLoading(true);
    if (!file && !guideline) {
      setAlert({ open: true, message: 'No file selected', type: 'warning' });
      setLoading(false);
      return;
    }
    if (file) {
      const s3Client = new S3Client({
        region: "us-east-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        },
      });

      let version = 0;
      let urlPreview;
      if (project.audio) {
        urlPreview = project.audio;
        const partes = project.audio.split("/");
        const nombreArchivo = partes[partes.length - 1];
        const indiceGuion = nombreArchivo.indexOf("-");
        const indicePunto = nombreArchivo.indexOf(".wav");

        // Comprobamos si hubo una coincidencia y obtenemos el número
        if (indiceGuion !== -1 && indicePunto !== -1) {
          const numero = nombreArchivo.substring(indiceGuion + 2, indicePunto);
          if (numero !== NaN && numero !== 'NaN' && numero !== undefined && numero !== null && numero !== '') {

            version = parseInt(numero) + 1;
          } else {
            version = 0;
          }
        } else {
          version = 0;
        }
      }
      const filename = `${id}-v${version}.wav`;

      let upload = new Upload({
        client: s3Client,
        params: {
          Bucket: 'container-audio-wav',
          Key: filename,
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const porcent = (progress.loaded / progress.total) * 100;
        setProgress(porcent);
        if (porcent === 100) {
          setProgress(0);
          setAlert({ open: true, message: 'Audio uploaded successfully', type: 'success' });
        }
      });
      const data = await upload.done();
      if (urlPreview) {
        const params = {
          Bucket: "container-records",
          Key: urlPreview,
        };
        await deleteRecord(params.Key);
      }
      await updateProject({ _id: id, audio: data.Location, guideline: guideline ? guideline : project.guideline }).then(async (res) => {
        if (project.audio) {
          await updateProject({ _id: id, needExternalAudio : false });
          generateAudioFinal(id);
          setAlert({ open: true, message: 'AudioFinal started generating', type: 'success' });
        }
        // await getProyects(1, true);
        setFileAudio(null);
        project.audio = data.Location;

      }).catch((err) => { console.error(err); });
      setLoading(false);
      setProgress(0);
    } else if (guideline && !file) {
      await updateProject({ _id: id, guideline }).then(async (res) => { await getProyects(1, true); }).catch((err) => { console.error(err); });
      setLoading(false);
    };
  }

  const handleCloseModal = async () => {
    setFormData({ isDirty: false, data: {} });
    setFormDataRol({ isDirty: false, data: {} });
    setOpen(false);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>{alert.message}</Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal} >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={styles.tabs}
          >
            <Tab label={project?.name} className={styles.tab_title} sx={{
              '&.Mui-disabled': {
                color: 'var(--text-color)',
              },
            }} disabled />
            <Tab label="Edit Data Project" icon={<EditIcon sx={{ mr: 1 }} />} {...a11yProps(1)} />
            <Tab label="Edit Resources Project" icon={<EditIcon sx={{ mr: 1 }} />} {...a11yProps(2)} />
            <Tab label="Progres Project" icon={<AutoGraph sx={{ mr: 1 }} />} {...a11yProps(3)} />
            <Tab label={hasVoiceOver ? "Edit Voice Over" : "Add Voice Over"} icon={<EditIcon sx={{ mr: 1 }} />} {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0} />
          <TabPanel value={value} index={1}>
            <div className={styles.container_contetTab}>
              <div className={styles.header_tab}>
                <h2 className={styles.title_tab}>Edit Data Project</h2>
              </div>
              {loading ? (<h1>Loading...</h1>) : (
                <div className={styles.content_tab}>
                  <FormDataProject project={project} setFormData={setFormData} updateData={updateData} />
                  <FormRolesProject project={project} setFormDataRol={setFormDataRol} updateData={updateData} />
                </div>
              )}
              <div className={styles.footer_tab}>
                <Button variant="contained" color="secondary" onClick={() => handleCloseModal()}> Cancel </Button>
                <Button variant="contained" color="primary" disabled={loading} onClick={() => combineData()}> Update Data Project </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={styles.container_contetTab}>
              <div className={styles.header_tab}>
                <h2 className={styles.title_tab}>Edit Resources Project</h2>
              </div>
              <div className={styles.content_tab} >
                <FormResourcesProject project={project} style={{ width: '50%' }} setFileAudio={setFileAudio} progress={progress} loading={loading} setGuideline={setGuideline} />
                <DownloadResources project={project} />
              </div>
              <div className={styles.footer_tab}>
                <Button variant="contained" color="secondary" onClick={() => handleCloseModal()} > Cancel </Button>
                <Button variant="contained" color="primary" onClick={() => uploadAudioProject(project._id, fileAudio)} disabled={loading}> Update Data Project </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3} style={{ height: ' calc(100% - 60px)' }}>
            <div className={styles.container_contetTab}>
              <div className={styles.header_tab}>
                <h2 className={styles.title_tab}>Tracking Project</h2>
              </div>
              <div className={styles.tracking_content}>
                <ProgressProject project={project} />
                <div
                  className={styles.tracking_content__right + ' ' + 'progressModal'} >
                  <TrackingProject project={project} />
                </div>
              </div>

            </div>
          </TabPanel>
          <TabPanel value={value} index={4} style={{ height: ' calc(100% - 60px)' }}>
            <div className={styles.container_contetTab}>
              <div className={styles.header_tab}>
                <h2 className={styles.title_tab}>{hasVoiceOver ? "Edit Voice Over" : "Add Voice Over"}</h2>
              </div>
              <div className={styles.content_tab} >
                <FormVoiceOver project={project} setAlert={setAlert} loading={loading} updateDataVoiceOver={updateDataVoiceOver} setLoading={setLoading} setUpdateDataVoiceOver={setUpdateDataVoiceOver} hasVoiceOver={hasVoiceOver}/>
              </div>
              <div className={styles.footer_tab}>
                <Button variant="contained" color="secondary" onClick={() => handleCloseModal()} > Cancel </Button>
                <Button variant="contained" color="primary" onClick={()=>addService()} disabled={loading}> {hasVoiceOver ? "Update Voice Over" : "Add Voice Over"} </Button>
              </div>
              </div>
              
          </TabPanel>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ModalEditProject;