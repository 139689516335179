import React, { useContext } from 'react';
import CardJoinSRT from '../components/voice-over/CardJoinSRT';
import { DataVoiceOver } from '../context/DataVoiceOver';

const JoinSRT = () => {
  const { video } = useContext(DataVoiceOver);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",flexGrow: 1 }}>
      <video
        src={`https://container-video-mp4.s3.amazonaws.com/voice-over/${video}`}
        aria-labelledby="video-caption"
        crossOrigin="anonymous"
        id="videoVoiceOver"
        style={{ width: "100%",margin:"1rem",borderRadius:".5rem",boxShadow:"0 0 10px rgba(0,0,0,.5)",maxHeight:"75vh", objectFit: "cover" }}
      >
      </video>
      <CardJoinSRT />
    </div>
  )
}

export default JoinSRT