import { createContext, useState } from "react";
import * as srtparsejs from "srtparsejs"; // cjs
import { subripToSeconds } from "../utils/secondToSubrip";

export const DataPM = createContext();

export const DataPMProvider = ({ children }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [segments, setSegments] = useState([]);
  const [isCaption, setIsCaption] = useState(false);

  const [countDescrptors, setCountDescrptors] = useState(0);
  const [countNarrators, setCountNarrators] = useState(0);
  const [countEditors, setCountEditors] = useState(0);
  const [countDubbing, setCountDubbing] = useState(0);
  const [countQC, setCountQC] = useState(0);
  const [countArchived, setCountArchived] = useState(0);


  const [captions, setCaptions] = useState([]);

  const searchCount = (projects) => {
    const counts = {
      AD: 0,
      AN: 0,
      AE: 0,
      DU: 0,
      QC: 0,
    };

    projects.forEach((project) => {
      //si projec.segments no tiene nada, no se cuenta
      // if (project.segments.length >0) {

      counts[project.rol.role] += 1;
      // }
    });

    setCountDescrptors(counts.AD);
    setCountNarrators(counts.AN);
    setCountEditors(counts.AE);
    setCountDubbing(counts.DU);
    setCountQC(counts.QC);
    setCountArchived(counts.AE);
  };

  const handleChangeCaption = (fileSRT) => {
    return new Promise((resolve, reject) => {

      const file = fileSRT;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const data = e.target.result;
        const json = srtparsejs.parse(data);
        const cleanSegmentsRttm = cleanRttm(json);
        const emptySegments = generateEmptySegmentsArray(cleanSegmentsRttm);
        const joinSegments = joinedSegments(cleanSegmentsRttm, emptySegments);
        const joinSegmentsEmpty = mergeEmptySegments(joinSegments);
        const result = parseTimeJson(joinSegmentsEmpty);
        setSegments(result);
        resolve(result[result.length - 1])
      };
      reader.onerror = (error) => {
        // En caso de error, rechazar la Promesa
        reject(error);
      };
    });
  };

  const generateSegmentDubbing = (fileSRT, user, index) => {
    const file = fileSRT;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      const data = e.target.result;
      const json = srtparsejs.parse(data);
      let segments = [];
      json.forEach((element) => {
        segments.push({
          start: subripToSeconds(element.startTime),
          end: subripToSeconds(element.endTime),
          caption: element.text,
          user: user.uid,
        });

      });
      let captionsTemp = [...captions];
      captionsTemp[index] = segments;
      setCaptions(captionsTemp);
    }
  }

  const generateSegmentsVoiceOver = (users, files) => {
    console.log(users, files)
    return new Promise((resolve, reject) => {  // Usamos una promesa para manejar la asincronía
      let segments = [];
      let captionsTemp = [];
      const filesWithValue = files.filter((file) => file !== null);
      console.log("filesWithValue", filesWithValue)
      for (let i = 0; i < filesWithValue.length; i++) {
        console.log(filesWithValue)
        const file = files[i];
        const user = users[i].user_id
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          console.log(e)
          const data = e.target.result;
          const json = srtparsejs.parse(data);
          json.forEach((element) => {
            console.log(element)
            segments.push({
              start: subripToSeconds(element.startTime),
              end: subripToSeconds(element.endTime),
              caption: element.text,
              user: user.uid,
            });
          });
          console.log(segments)
          captionsTemp.push(segments);
          segments = [];
          console.log(i)
          console.log(files.length)
          if (i === filesWithValue.length - 1) {
            console.log(captionsTemp)
            resolve(captionsTemp);
          }
        };
        reader.onerror = (error) => {
          reject(error);  // Rechazamos la promesa en caso de error
        };
      }
    });
  };

  const joinedSegments = (array1, array2) => {
    const newArray = [...array1, ...array2];
    newArray.sort((a, b) => a.start - b.start);
    return newArray;
  };
  const mergeEmptySegments = (arr) => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].speaker === "empty") {
        if (i + 1 < arr.length && arr[i + 1].speaker === "empty") {
          arr[i].duration = arr[i + 1].stop - arr[i].start;
          arr[i].stop = arr[i + 1].stop;
          arr[i].text += " " + arr[i + 1].text;
          arr.splice(i + 1, 1);
          i--;
        }
      }
    }
    //retornar  solo los segmentos que tienen speaker === empty
    arr.forEach((element) => {
      if (element.speaker === "empty" && element.duration >= 2) {
        newArray.push(element);
      }
    });
    return newArray;
  };
  const generateEmptySegmentsArray = (originalArray) => {
    // //console.log(originalArray);
    const newArray = [];
    //primer elemento del arreglo es el tiempo inicial 0 y el primero elmento el tiempo inicial
    if (parseFloat(originalArray[0].start) > 2) {
      newArray.push({
        start: 0,
        stop: parseFloat(originalArray[0].start),
        duration: parseFloat(originalArray[0].start),
        speaker: "empty",
      });
    }
    //iterar sobre el arreglo original

    for (let i = 0; i < originalArray.length - 1; i++) {
      //si el tiempo entre el segmento y el siguiente es mayor a 2 segundo
      if (
        parseFloat(originalArray[i + 1].start) -
        parseFloat(originalArray[i].stop) >
        2
      ) {
        const emptySegment = {
          start: parseFloat(originalArray[i].stop),
          stop: parseFloat(originalArray[i + 1].start),
          duration:
            parseFloat(originalArray[i + 1].start) -
            parseFloat(originalArray[i].stop),
          speaker: "empty",
        };

        newArray.push(emptySegment);
      }
    }

    return newArray;
  };

  const cleanRttm = (array) => {
    const newArray = [];

    for (let i = 0; i < array.length; i++) {
      //puedes hacer una expresion regular para verificar si tiene los siguientes caracteres = ♪,[,] en js si los tiene retornar true
      let speaker = "spk_none";
      const regexValidText = /♪|\[|\]/g;
      if (regexValidText.test(array[i].text)) {
        speaker = "empty";
      }

      newArray.push({
        start: subripToSeconds(array[i].startTime),
        stop: subripToSeconds(array[i].endTime),
        duration:
          subripToSeconds(array[i].endTime) -
          subripToSeconds(array[i].startTime),
        speaker: speaker,
        text: array[i].text,
      });
    }
    return newArray;
  };

  const parseTimeJson = (array) => {
    const newArray = [];
    array.forEach((element) => {
      newArray.push({
        start: element.start,
        end: element.stop,
      });
    });
    return newArray;
  };

  const postSegments = async (id, segments) => {
    const data = await fetch(`${process.env.REACT_APP_URL}/api/segments/all/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segments: segments }),
    });
    const response = await data.json();
    if (response.status) {
      sendMailCreateProject(id);
      setIsCaption(true);
    } else {
      setIsCaption(false);
    }
    //console.log(response);
  };

  const postSegmentsDubbing = async (id, segments) => {
    const data = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/all/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segments: segments }),
    });
    const response = await data.json();
    if (response.status) {
      sendMailCreateProject(id);
      setIsCaption(true);
      return true
    } else {
      setIsCaption(false);
      return false
    }
  };

  const sendMailCreateProject = async (id) => {
    // /api/projects/create-email/64c1d5daa27d782e5b4b7cd8
    const data = await fetch(`${process.env.REACT_APP_URL}/api/projects/create-email/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    await data.json()
  };

  return (
    <DataPM.Provider
      value={{
        isCreate,
        setIsCreate,
        isCreateUser,
        setIsCreateUser,
        handleChangeCaption,
        segments,
        setSegments,
        postSegments,
        isCaption,
        searchCount,
        countDescrptors,
        countNarrators,
        countEditors,
        countDubbing,
        countQC,
        generateSegmentDubbing, 
        generateSegmentsVoiceOver,
        captions, 
        postSegmentsDubbing, 
        countArchived
      }}
    >
      {children}
    </DataPM.Provider>
  );
};
