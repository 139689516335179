import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { putCaptionSegment, putDescriptionSegment, putScriptSegmentSign } from "../descriptions/description";
import { process } from "../env";
import { deleteRecord } from "../regions/deleteRecord";


export const uploadVideoVoiceOver = async (file) => {

  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
  });
  const filename = `voice-over/${file.name}`;
  //console.log("filename", filename);
  let upload = new Upload({
    client: s3Client,
    params: {
      Bucket: "container-video-mp4",
      Key: filename,
      Body: file,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    //console.log("progress", progress);
  });
  try {
    const data = await upload.done();
    return data.Location;
  } catch (error) {
    console.error("error", error);
    return error;
  }
};

export const upladRecording = async (project, id, file) => {
  //console.log("upladRecording", project, id, file);

  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
  });
  const filename = `${project}/${id}.webm`;
  //console.log("filename", filename);
  let upload = new Upload({
    client: s3Client,
    params: {
      Bucket: "container-records",
      Key: filename,
      Body: file,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    //console.log("progress", progress);
  });
  try {
    const data = await upload.done();
    putDescriptionSegment(
      { audiorecord: data.Location }, id
    );
  } catch (error) {
    console.error("error", error);
    return error;
  }
};

export const upladRecordingVideo = async (region, id, file) => {

  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
  });
  let version = 0;
  let urlPreview;
  if (region.data.videorecord) {
    urlPreview = region.data.videorecord;
    const partes = region.data.videorecord.split("/");

    // Obtenemos la última parte que contiene el nombre del archivo
    const nombreArchivo = partes[partes.length - 1];

    // Buscamos el índice del primer guion (-) en el nombre del archivo
    const indiceGuion = nombreArchivo.indexOf("-");

    // Buscamos el índice del punto (.) antes de ".webm"
    const indicePunto = nombreArchivo.indexOf(".webm");

    // Comprobamos si hubo una coincidencia y obtenemos el número
    if (indiceGuion !== -1 && indicePunto !== -1) {
      const numero = nombreArchivo.substring(indiceGuion + 2, indicePunto);
      if (numero !== NaN && numero !== 'NaN' && numero !== undefined && numero !== null && numero !== '') {

        version = parseInt(numero) + 1;
      } else {
        version = 0;
      }
    } else {
      version = 0;
    }
  }
  const filename = `ASL/${region.data.project}/${id}-v${version}.webm`;
  //console.log("filename", filename);
  let upload = new Upload({
    client: s3Client,
    params: {
      Bucket: "container-records",
      Key: filename,
      Body: file,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    //console.log("progress", progress);
  });
  try {
    const data = await upload.done();
    //eliminar el anterior video
    if (urlPreview) {
      const partes = urlPreview.split("/");
      const filename = partes[partes.length - 1];
      const params = {
        Bucket: "container-records",
        Key: `ASL/${region.data.project}/${filename}`,
      };
      await deleteRecord(params.Key);
    }
    putScriptSegmentSign(
      { videorecord: data.Location }, id
    );
    return data.Location;


  } catch (error) {
    console.error("error", error);
    return error;
  }
};

export const upladRecordingDubbing = async (region, id, userId, file) => {

  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
  });
  //generar el nombre del archivo al final  un versionado con un contador
  let version = 0;
  let urlPreview;
  if (region.data.audiorecord.initial) {
    urlPreview = region.data.audiorecord.initial;
    const partes = region.data.audiorecord.initial.split("/");

    // Obtenemos la última parte que contiene el nombre del archivo
    const nombreArchivo = partes[partes.length - 1];

    // Buscamos el índice del primer guion (-) en el nombre del archivo
    const indiceGuion = nombreArchivo.indexOf("-");

    // Buscamos el índice del punto (.) antes de ".webm"
    const indicePunto = nombreArchivo.indexOf(".webm");

    // Comprobamos si hubo una coincidencia y obtenemos el número
    if (indiceGuion !== -1 && indicePunto !== -1) {
      const numero = nombreArchivo.substring(indiceGuion + 2, indicePunto);
      if (numero !== NaN && numero !== 'NaN' && numero !== undefined && numero !== null && numero !== '') {

        version = parseInt(numero) + 1;
      } else {
        version = 0;
      }
    } else {
      version = 0;
    }
  }

  const filename = `${region.data.project}/${id}-v${version}.webm`;
  //console.log("filename", filename);
  let upload = new Upload({
    client: s3Client,
    params: {
      Bucket: "container-records",
      Key: filename,
      Body: file,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    //console.log("progress", progress);
  });
  try {
    if (urlPreview) {
      const partes = urlPreview.split("/");
      const filename = partes[partes.length - 1];
      const params = {
        Bucket: "container-records",
        Key: `${region.data.project}/${filename}`,
      };

      await deleteRecord(params.Key);
    }
    const data = await upload.done();
    putCaptionSegment(
      { audiorecord: {
        initial:data.Location,
        preview:region.data.audiorecord.initial?region.data.audiorecord.initial:""
      } }, id
    );
    return  {
      initial:data.Location,
      preview:region.data.audiorecord.initial?region.data.audiorecord.initial:""
    }
    
  } catch (error) {
    console.error("error", error);
    return error;
  }
};

export const uploadRecordingAudio = async (region, id, file) => {
  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
  });
  let version = 0;
  let urlPreview;
  if (region.data.audiorecord.initial) {
    urlPreview = region.data.audiorecord.initial;
    const partes = region.data.audiorecord.initial.split("/");

    // Obtenemos la última parte que contiene el nombre del archivo
    const nombreArchivo = partes[partes.length - 1];

    // Buscamos el índice del primer guion (-) en el nombre del archivo
    const indiceGuion = nombreArchivo.indexOf("-");

    // Buscamos el índice del punto (.) antes de ".webm"
    const indicePunto = nombreArchivo.indexOf(".webm");

    // Comprobamos si hubo una coincidencia y obtenemos el número
    if (indiceGuion !== -1 && indicePunto !== -1) {
      const numero = nombreArchivo.substring(indiceGuion + 2, indicePunto);
      if (numero !== NaN && numero !== 'NaN' && numero !== undefined && numero !== null && numero !== '') {

        version = parseInt(numero) + 1;
      } else {
        version = 0;
      }
    } else {
      version = 0;
    }
  }
  const filename = `${region.data.project}/${id}-v${version}.webm`;
  //console.log("filename", filename);
  let upload = new Upload({
    client: s3Client,
    params: {
      Bucket: "container-records",
      Key: filename,
      Body: file,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    //console.log("progress", progress);
  });
  try {
    const data = await upload.done();
    //eliminar el anterior video
    if (urlPreview) {
      const partes = urlPreview.split("/");
      const filename = partes[partes.length - 1];
      const params = {
        Bucket: "container-records",
        Key: `${region.data.project}/${filename}`,
      };

      await deleteRecord(params.Key);
    }
    putDescriptionSegment(
      { audiorecord: {
        initial:data.Location,
        preview:region.data.audiorecord.initial?region.data.audiorecord.initial:""
      } }, id
    );
    
    return  {
      initial:data.Location,
      preview:region.data.audiorecord.initial?region.data.audiorecord.initial:""
    }


  } catch (error) {
    console.error("error", error);
    return error;
  }
};