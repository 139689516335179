import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import React, { useContext } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/TextFields.module.css";
import { updateScriptSegmentSign } from "../../utils/scriptAdapter";
import { BarActions } from "../toolbar/BarActions";

const TextField = () => {
    const {
        setDescription,
        charactersCount,
        recommendCharacterMax,
        currentRegion,
        startTime,
        endTime,
        setDuration,
    } = useContext(DataDescriptor);    

    const checkDescription = (e) => {
        setDescription(e.target.value);
    };

    const handleTextAreaDescription = (e) => {
        setDescription(e.target.value);        
        updateScriptSegmentSign(
            currentRegion,
            e.target.value,
            startTime,
            endTime,            
            setDuration
        );
    };
    return (
        <>
            <div className={styles.container__TextLabel}>
                <div className={styles.label__TextField}>
                    <div className={styles.title__TextField}>
                        <ArticleOutlinedIcon /> <h3>Script</h3>
                    </div>
                    <BarActions />
                </div>
                <div className={styles.container__descriptioTextFiel}>
                    <textarea
                        placeholder="Enter a script description"
                        value={currentRegion.data?.script? currentRegion.data?.script : ""}
                        onLoadStart={(e) => {
                            checkDescription(e);
                        }}
                        onChange={(e) => {
                            handleTextAreaDescription(e);
                        }}
                        className={styles.textArea_description}
                    />
                    <div
                        className={styles.container__count}
                        style={{
                            color:
                                charactersCount > Math.round(recommendCharacterMax)
                                    ? "red"
                                    : "green",
                        }}
                    >
                        <span className={styles.span__characterCount}>
                            {charactersCount}
                        </span>
                    </div>
                </div>
                <div className={styles.container__textCount}>
                    {charactersCount > Math.round(recommendCharacterMax) && (
                        <h4 style={{ color: "var(--orange)" }}>
                            {" "}
                            WARNING: You have exceeded the limit{" "}
                            {Math.round(recommendCharacterMax)} .
                        </h4>
                    )}
                </div>
            </div>

        </>
    )
}

export default TextField