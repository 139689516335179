import { Autocomplete, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { rolesAudioDescription } from "../../../utils/dataProjectManager";

const FormRolesProject = ({ project,setFormDataRol,updateData }) => {
  const rolesEditProject = ["Quality Control AD", "Quality Control AN"];
  const { register, handleSubmit, control, watch, formState: { errors, isDirty, dirtyFields }, } = useForm({
    defaultValues: {
      rol: project?.rol.role,
      users: [
        { user_id: project?.users[1]?.user_id },
        { user_id: project?.users[2]?.user_id },
        { user_id: project?.users[4]?.user_id },
        { user_id: project?.users[5]?.user_id },
      ]
    }
  })

  useEffect(() => {
    if (updateData) executeSubmit()
  }, [updateData]);

  const executeSubmit = async() => await handleSubmit(onSubmit)()

  useEffect(() => {
    getUsers("AD");
    getUsers("AN");
    getUsers("AE");
    getUsers("QC");
    getUsers("SCRIPT_ADAPTER");
    getUsers("INTERPRETER");
    getUsers("CLIENT");
  }, []);
  const [usersDescriptors, setUsersDescriptors] = useState([]);
  const [usersNarrators, setUsersNarrators] = useState([]);
  const [usersEditors, setUsersEditors] = useState([]);
  const [usersQualityControl, setUsersQualityControl] = useState([]);
  const [usersScriptAdapter, setUsersScriptAdapter] = useState([]);
  const [usersInterpreter, setUsersInterpreter] = useState([]);
  const [usersClient, setUsersClient] = useState([]);
  const getUsers = async (role) => {
    const data = await fetch(`${process.env.REACT_APP_URL}/api/users/${role}`);
    const dataRole = await data.json();
    dataRole.users.forEach((user) => {
      user.label = user?.email;
      user.value = user?.uid;
    });
    switch (role) {
      case "AD":
        setUsersDescriptors(dataRole.users);
        break;
      case "AN":
        setUsersNarrators(dataRole.users);
        break;
      case "QC":
        setUsersQualityControl(dataRole.users);
        break;
      case 'SCRIPT_ADAPTER':
        setUsersScriptAdapter(dataRole.users);
        break;
      case 'INTERPRETER':
        setUsersInterpreter(dataRole.users);
        break;
      case 'CLIENT':
        setUsersClient(dataRole.users);
        break
      default:
        break;
    }
  };
  const valueRole = (role) => {
    switch (role) {

      case "Quality Control AD":
        return {
          role: 'QC',
          user_id: project.users[4]?.user_id._id,
        }
      case "Quality Control AN":
        return {
          role: 'QC2',
          user_id: project.users[4]?.user_id._id,
        }

    }
  };
  const onSubmit = async (data) => {
    if (isDirty) {
      try {
        let projectRol = data.rol;
        const users = [...project.users]; // Clonamos los usuarios
        const dataProject = {
          users: [
            {
              ...users[0],
              user_id: project.users[0]?.user_id._id || project.users[0]?.user_id.uid,
            },
            {
              ...users[1],
              user_id: data.users[0].user_id._id || data.users[0].user_id.uid,
            },
            {
              ...users[2],
              user_id: data.users[1].user_id._id || data.users[1].user_id.uid,
            },
            {
              ...users[3],
              user_id: project.users[3]?.user_id?._id,
            },
            {
              ...users[4],
              user_id: data.users[2].user_id._id || data.users[2].user_id.uid,
            },
            {
              ...users[5],
              user_id: data.users[3]?.user_id?._id || null, // Aquí nos aseguramos de que el cliente esté definido correctamente
            },
          ],
        };
  
        let actualUserRol = dataProject.users.find((user) => user.user_id === project.rol.user_id);
        actualUserRol = actualUserRol?.role !== "AE" ? actualUserRol : null;
  
        let idUserRol;
        if (projectRol !== project.rol.role || !actualUserRol) {
          switch (projectRol) {
            case "AD":
              idUserRol = data.users[0].user_id._id || data.users[0].user_id.uid;
              break;
            case "AN":
              idUserRol = data.users[1].user_id._id || data.users[1].user_id.uid;
              break;
            case "AE":
              idUserRol = project.users[3]?.user_id._id;
              break;
            case "QC":
              idUserRol = data.users[2].user_id._id || data.users[2].user_id.uid;
              break;
            case "QC2":
              idUserRol = data.users[2].user_id._id || data.users[2].user_id.uid;
              break;
            case "CLIENT":
              idUserRol = data.users[3].user_id._id || data.users[3].user_id.uid; // Siempre maneja el cliente seleccionado
              break;
            default:
              break;
          }
        }
  
        // Si no hay cliente en users[5], verifica si está en data.users[3] (el cliente actual)
        if (!data.users[3]?.user_id) {
          delete dataProject.users[5]; // Elimina el cliente si no hay ninguno seleccionado
        } else {
          // Si hay un cliente, asegúrate de que esté asignado correctamente
          dataProject.users[5] = {
            ...users[5],
            user_id: data.users[3]?.user_id._id || data.users[3]?.user_id.uid,
          };
        }
  
        // Actualiza el rol del proyecto si es necesario
        if (idUserRol || !actualUserRol) {
          dataProject.rol = {
            role: projectRol,
            user_id: idUserRol || actualUserRol?.user_id,
          };
        }
  
        dataProject._id = project._id;
        setFormDataRol({ isDirty: true, data: { ...dataProject } });
      } catch (error) {
        setFormDataRol({ isDirty: false, data: { _id: project._id } });
      }
    } else {
      setFormDataRol({ isDirty: false, data: { _id: project._id } });
    }
  };
  
  const getLabelRole = (role) => {
    switch (role) {
      case "AD":
        return 'Describer';
      case "AN":
        return 'Narrator';
      case "QC":
        return 'Quality Control AD';
      case 'SCRIPT_ADAPTER':
        return 'Script Adapter';
      case 'INTERPRETER':
        return 'Interpreter';
      case 'CLIENT':
        return 'Client';
      default:
        break;
    }
  }
  const watchAudioDescription = watch('client[0]'); // Observa el estado de Audio Description
const watchAudioNarration = watch('client[1]'); // Observa el estado de Audio Narration
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '50%', margin: '1.5rem' }}>
   {rolesAudioDescription.map((rol, index) => (
  <div key={index}>
    <>
      <Typography
        variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" }}>
        {rol}
      </Typography>
      <Controller
        name={`users[${index}].user_id`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            key={index}
            value={value?.email || ''} // Asegurarse de manejar valor vacío
            isOptionEqualToValue={(option, value) => {
              return option?.id === value?.id;
            }}
            onChange={(event, newValue) => onChange(newValue)}
            placeholder={`Seleccione un ${rol}`}
            options={
              rol === "Describer"
                ? usersDescriptors
                : rol.includes("Narrator")
                  ? usersNarrators
                  : rol === "Editor"
                    ? usersEditors
                    : rol === "Quality Control"
                      ? usersQualityControl
                      : rol === 'Script Adapter'
                        ? usersScriptAdapter
                        : rol === 'Interpreter'
                          ? usersInterpreter
                          : rol === 'Client'
                            ? usersClient // Siempre mostrar las opciones de cliente
                            : []
            }
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      />
    </>
  </div>
))}

      <Typography
        variant="h6" sx={{ color: "white" }}>
        Project Actual Rol - {project.rol.role}
      </Typography>
      <Controller
        name="rol"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Rol"
          >
            {!project.rol.role.includes('QC') && (<MenuItem value={(project.rol.role)}>{getLabelRole(project.rol.role)}</MenuItem>)}
            {rolesEditProject.map((rol, index) => <MenuItem key={index} value={valueRole(rol).role}>{rol}</MenuItem>)}
          </Select>
        )}
      />
    </form>



  );
};

export default FormRolesProject
